<ng-template #longContent let-modal>


    <div class="modal-body">
        <h5 class="mb-0 linha-quadro">Novo Cnis</h5>

        <form [formGroup]="formCnis">
        
            <div class="row py-4">
                <div class="col-12 col-xxl-4  py-4">

                    <p class="h2">
                        Nome
                    </p>

                    

                    <input type="text" class=" form-control text-left"
                        placeholder="" formControlName="nome" class="form-control"
                        popoverTitle="Nome" placement="bottom" [popover]="''"
                        triggers="mouseenter:mouseleave">

                </div>


                <div class="col-12 col-xxl-4  py-4">

                    <p class="h2">
                        Sexo
                    </p>
                    



                    <div class="btn-group" btnRadioGroup formControlName="sexo" (click)="editarSexoSelecionado() ">
                        <label class="btn btn-sm btn-outline-black mb-0" btnRadio="Masculino">Masculino</label>
                        <label class="btn btn-sm btn-outline-black mb-0" btnRadio="Feminino">Feminino</label>
                      </div>

                    

                </div>


                <div class="col-12 col-xxl-4  py-4">

                    

                    <p class="h2">
                        Data de Nascimento
                    </p>

                    <input type="text" class=" form-control text-left" [inputMask]="dateInputMask"
                        placeholder="" formControlName="dataNascimento" class="form-control"
                        popoverTitle="Nome" placement="bottom" [popover]="''"
                        triggers="mouseenter:mouseleave">

                </div>




                


               




            </div>
        </form>




        <div class="row">
            <div class="col-12">
                <div class="col-xl-12 col-md-12 col-lg-12  d-flex flex-row-reverse">
                    <div class="row ">

                        <div class="col-auto " [className]="waguardeVisivel ? 'visible col-auto' : 'invisible col-auto'">
                            <div class="d-flex justify-content-center my-2">
                                <div class="spinner-border spinner-border-lg my-4" role="status">
                                </div>
                            </div>
                        </div>
                        <div class="col-auto habilitado " container="body" [ngbTooltip]="'Cancelar'"
                            (click)="cancelar()">
                            <div class="col-1 p-4 mb-5 active controlenav ">
                                <span class=" timeline-step timeline-step-xlg  ">
                                    <i class="bi-x-lg"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-auto " [disableTooltip]="!continuarHabilitado" container="body" [ngbTooltip]="'Confirmar'"
                            [className]="continuarHabilitado ? 'habilitado col-auto' : 'desabilitado col-auto'"
                            (click)="continuar()">
                            <div class="col-1 p-4 mb-5 active controlenav ">
                                <span class=" timeline-step timeline-step-xlg  ">
                                    <i class="bi-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      
    </div>
</ng-template>