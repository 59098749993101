import { AfterContentChecked, AfterViewChecked, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Global } from "src/app/modelos/global";
import { Permissoes } from "src/app/modelos/usuario/permissoes";
import { Recado } from "src/app/modelos/usuario/recado";
import { Usuario } from "src/app/modelos/usuario/usuario";
import { LogService } from "src/app/servicos/log.service";
import { AutenticacaoService } from "src/app/servicos/oauth/autenticacao.service";
import { ConfigService } from "src/app/servicos/ui/config.service";
import { DeixarRecadoService } from "src/app/servicos/usuario/deixar-recado.service";
import Swal from "sweetalert2";




var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  secondicontype?: string;
  click?: any;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
// referencia icones https://fontawesome.com/v5/cheatsheet



export const ROUTESADMIN: RouteInfo[] = [

];

export const ROUTES_CONFIG: RouteInfo[] = [
  {
    path: "/calculei/usuario/minhaconta",
    title: "Conta",
    type: "link",
    icontype: "bi-person-circle",
    //isCollapsed: true,
    // children: [
    //   { path: "calculos", title: "Meus Cálculos", type: "link" },
    //   { path: "clientes", title: "Meus Clientes", type: "link" }
    // ]
  },

  {
    path: "https://wa.me/message/ZGC2DYJ3EX6VK1",
    title: "Chat",
    type: "linkexterno",
    icontype: "fab fa-whatsapp",
    click: "whatsapp",
    //isCollapsed: true,
    // children: [
    //   { path: "calculos", title: "Meus Cálculos", type: "link" },
    //   { path: "clientes", title: "Meus Clientes", type: "link" }
    // ]
  },

  {
    path: "https://calendly.com/seniorprev/30min",
    title: "Treinamento",
    type: "linkexterno",
    icontype: "bi-microsoft-teams",
    click: "suporte"
    //isCollapsed: true,
    // children: [
    //   { path: "calculos", title: "Meus Cálculos", type: "link" },
    //   { path: "clientes", title: "Meus Clientes", type: "link" }
    // ]
  },

  {
    path: "https://tutoriais.calculei.app",
    title: "Cursos",
    type: "linkexterno",
    icontype: "bi-book",
    click: "documentacao"
    //isCollapsed: true,
    // children: [
    //   { path: "calculos", title: "Meus Cálculos", type: "link" },
    //   { path: "clientes", title: "Meus Clientes", type: "link" }
    // ]
  },
  // {
  //   path: 'https://calculei.app',
  //   title: "Sair",
  //   type: "linkexterno",
  //   icontype: "bi-door-open",
  //   click: "sair"
  //   //isCollapsed: true,
  //   // children: [
  //   //   { path: "calculos", title: "Meus Cálculos", type: "link" },
  //   //   { path: "clientes", title: "Meus Clientes", type: "link" }
  //   // ]
  // },

]
export const ROUTES: RouteInfo[] = [

  {
    path: "/calculei/home",
    title: "Início",
    type: "link",
    icontype: "bi-house-fill",
  },
  {
    path: "/calculei/calculos/novocalculo",
    title: "Novo",
    type: "link",
    icontype: "bi-plus-square-fill",
  },


  {
    path: "/calculei/dashboard/calculos",
    title: "Cálculos",
    type: "link",
    icontype: "bi-collection-fill",

  },

  {
    path: "/calculei/dashboard/matrizes",
    title: "Matrizes",
    type: "link",
    icontype: "bi-grid-3x3-gap-fill",

  },

  // {
  //   path: "/calculei/dashboard/entrevistas",
  //   title: "Entrevistas",
  //   type: "link",
  //   icontype: "bi-mic-fill",

  // },

  // {
  //   path: "/calculei/dashboard/clientes",
  //   title: "Clientes",
  //   type: "link",
  //   icontype: "bi-people-fill",

  // },

];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit, AfterViewChecked {

  GlobalType = Global;
  PermissoesType = Permissoes;


  usuario: Usuario;
  periodoTeste: boolean;
  assinaturaValida: boolean;

  diasRestantes: number;


  public menuItems: any[];
  public menuItemsAdmin: any[];
  public menuItemsConfig: any[];
  public isCollapsed = true;

  visaoAdmin: boolean;

  constructor(private router: Router, private configService: ConfigService,
    private autenticacaoService:AutenticacaoService,
    private deixarRecadoService: DeixarRecadoService,
    private changeDetector: ChangeDetectorRef) {

    this.configService.getUsuario().subscribe(usuario => this.usuario = usuario);

    // this.configService.getDuracaoDiasTesteRestante().subscribe(diasRestantes => {
    //   if (diasRestantes > 0) {
    //     this.periodoTeste = true;
    //     this.diasRestantes = diasRestantes;
    //   } else {
    //     this.periodoTeste = false;
    //     this.diasRestantes = 0;
    //   }
    // });

    this.configService.getAssinaturaAtiva().subscribe(ativa => {
      // console.log('get assinatura ativa');
      this.assinaturaValida = ativa;
    });

  }

  onRouterLinkActive(event) {
    // console.log('sidebar event', event);
  }

  ngOnInit() {

    this.menuItems = ROUTES.filter(menuItem => menuItem);

    this.menuItemsAdmin = ROUTESADMIN.filter(menuItem => menuItem);

    this.menuItemsConfig = ROUTES_CONFIG.filter(menuItem => menuItem);

    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });


  }
  sair(){
    LogService.log('sair', this.autenticacaoService.oauthService.getIdentityClaims())
    this.autenticacaoService.logout();

  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  conversarWhatsapp() {

    window.open('https://wa.me/message/ZGC2DYJ3EX6VK1', '_blank');

  }

  agendarVideoconferencia() {

    window.open('https://calendly.com/calculei/apresentacao', '_blank');

  }

  documentacao() {
    window.open('https://tutoriais.calculei.app', '_blank');
  }

  changelog() {

    window.open('https://blog.calculei.app/tags/atualizações', '_blank');

  }
  youtubeTutoriais() {

    window.open('https://youtube.com/@calculei', '_blank');

  }

  funcaoItem(item) {

    switch (item) {
      case "whatsapp":
        this.conversarWhatsapp();
        break;

      case "suporte":
        this.agendarVideoconferencia();
        break;

      case "youtube":
        this.youtubeTutoriais();
        break;
      case "documentacao":
        this.documentacao();
        break;

      
      case "changelog":
        this.changelog();
        break;
      default:
        break;
    }
    // console.log(item);
  }

  deixarRecado() {

    Swal.fire({
      input: 'textarea',
      inputLabel: 'Recado para o Calculei',
      inputPlaceholder: 'Deixe-se seu recado aqui...',
      inputAttributes: {
        'aria-label': 'Deixe-se seu recado aqui...'
      },
      showCloseButton: true,
      showConfirmButton: true,
      confirmButtonText: 'Enviar',
      confirmButtonColor: '#172b4d',




    }).then((result) => {
      let recado: Recado = new Recado();
      recado.mensagem = result.value;
      recado.usuario = this.usuario;
      recado.data = new Date();

      this.deixarRecadoService.deixarRecado(recado).then(recado => { });

      Swal.fire({
        title: 'Obrigado :)',
        text: 'muito obrigado pelo seu recado...',


        showConfirmButton: true,
        confirmButtonText: 'ok',
        confirmButtonColor: '#172b4d'

      }).then(() => { });


    });


  }



  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }
  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
