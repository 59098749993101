<div class="row pb-2">
  <div class="col">
    <!-- <h5 class="mb-0 linha-quadro"></h5> -->
    <div class="row">
      <div class="col-6">
        <!-- <h5 class="mb-0">Qualidade de Segurado
          <i *ngIf="visualizarPdf" class=" comando bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpOtimizacao" aria-expanded="false" aria-controls="helpOtimizacao"></i>
        </h5> -->
      </div>
      <div *ngIf="visualizarPdf" class="col-6">
        <div class="text-right">
          <div class="row justify-content-end">
            <div class="col-3">
              <app-aguarde-local [aguarde]="aguardePdf"></app-aguarde-local>
            </div>
          </div>
          <i class="comando bi bi-filetype-pdf fs-6 " role="button" container="body" (click)="gerarPdf()" ngbTooltip="Gráfico em PDF" triggers="hover"></i>
        </div>
      </div>
    </div>

    <div class="collapse" id="helpOtimizacao">

      <div class="row py-4 d-flex ">

        <div class="col-3 py-2 d-flex">
          <div class="row w-100 bg-secondary rounded m-2">
            <div class="col d-flex p-2">

              <p class=" p-2 lh-1">
                Períodos em que houve manutenção da qualidade de segurado
              </p>

            </div>

          </div>

        </div>

      </div>

    </div>
  </div>

</div>

<div class="row">
  <!-- Chart wrapper -->
  <div id="wrapperConsolidado" style="position: relative; height: 50vh">
    <canvas id="mapaQualidadeSeguradoConsolidado"></canvas>
  </div>
</div>