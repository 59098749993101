<app-cabecalho [titulo]="'Início'"></app-cabecalho>
<div class=" container-fluid mt--3">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">

        <div class="pb-4">
          <div class="card">
            <div class="row">
              <div class="col">
                <div class="card-body vh-100">

                  <div class="pb-4">
                    <div class="row">
                      <div class="col-12">
                        <p class="display-1 lh-1 fw-lighter">Olá,</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <p class="fw-light">
                          Comece com uma<mark role="button" [routerLink]="['/calculei/calculos/matriz']">Matriz de Planejamento</mark> para encontrar o melhor plano de aposentadoria, ou escolha
                          a opção <mark role="button" [routerLink]="['/calculei/calculos/novocalculo']">Cálculos</mark> para escolher um modelo de cálculo previdenciário especializado
                        </p>
                        <!-- <p class="lh-1 fw-light">bem vindo(a) ao Calculei, sua plataforma de Investimento Previdenciário</p> -->
                      </div>

                    </div>
                  </div>

                 


                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/matriz']">
                      <div class="row">
                        <div class="col-auto" role="button" >
                          <i class="comando bi bi-grid-3x3-gap-fill fs-2"></i>
    
                        </div>
                        <div class="col-auto" role="button" >
    
                          <p class="h1">
                            Matriz de Planejamento
                          </p>
                          <p class="p-0 m-0">
                            Indicação de plano de aposentadoria
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>


                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/novocalculo']">
                      <div class="row">
                        <div class="col-auto" role="button" >
                          <i class="comando bi bi-plus-square-fill fs-2"></i>
    
                        </div>
                        <div class="col-auto" role="button" >
    
                          <p class="h1">
                            Cálculos
                          </p>
                          <p class="p-0 m-0">
                            Modelos de cálculos previdenciários
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>

                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>