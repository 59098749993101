<div class="  ">

  <div class="pb-4">
    <div class="card">
      <div class="row">
        <div class="col">
          <div class="card-body">
            <span class=" display-3 font-weight-lighter  mb-0 text-default">
              Referências do Cálculo
            </span>


          </div>
        </div>

      </div>


      <div class="row">
        <div class="col">





          <form [formGroup]="referenciaForm">

            <div class="row">

              <div class=" col-12 col-xxl-6">
                <div class=" card">
                  <div class=" card-header border-dark">
                    <h5 class="mb-0">Cliente</h5>
                  </div>

                  <div class=" card-body">

                    <div class="row py-2">
                      <div class="col-12">

                        <label class="form-control-label" for="basic-url">Nome</label>
                        <input [(ngModel)]="clienteSelecionado" [typeahead]="clientes" typeaheadOptionField="nome"
                          [typeaheadOptionsLimit]="6" [typeaheadMinLength]="2" (typeaheadOnSelect)="onSelect($event)"
                          formControlName="nome" placeholder="Nome do Cliente" class="form-control">
                      </div>

                    </div>


                    <div class="row py-2">
                      <div class="col-12">


                        <label class="form-control-label" for="basic-url">CPF</label>
                        <div class="input-group">
                          <input [inputMask]="cpfMask" type="text" class="form-control" [value]="cpf"
                            placeholder="___.___.___-__" aria-label="CPF" aria-describedby="basic-addon2"
                            formControlName="cpf">
                        </div>

                      </div>
                    </div>






                  </div>
                </div>
              </div>





              <div class=" col-12 col-xxl-6">
                <div class=" card">
                  <div class=" card-header border-dark">
                    <h5 class="mb-0">Processo</h5>
                  </div>

                  <div class=" card-body">







                    <div class="row py-2">
                      <div class="col-12">
                        <label class=" form-control-label" for="example4cols1Input">
                          Data do Cálculo
                        </label>

                        <input [inputMask]="dateInputMask" class=" form-control" 
                          placeholder="dd/mm/aaaa" type="text" formControlName="dataCalculo" />
                      </div>
                    </div>


                    <div class="row py-2">
                      <div class="col-12">



                        <label class=" form-control-label" for="example4cols1Input">
                          Tipo de Processo
                        </label>
                        <div class="form-group">



                          <ngx-select formControlName="tipoProcesso" [items]="tiposProcesso"
                            placeholder="Selecionar Tipo do Benefício" (selectionChanges)="selectTipoChange($event)">
                          </ngx-select>


                        </div>

                      </div>
                    </div>









                  </div>
                </div>
              </div>

            </div>

          </form>

        </div>
      </div>


    </div>



  </div>


</div>





