import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ImportadorCNISComponent } from '../compartilhado/importador-cnis/importador-cnis.component';
import { EditorCNISComponent } from '../compartilhado/editor-cnis/editor-cnis.component';
import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';
import Stepper from 'bs-stepper';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

import { DatePipe, Location } from '@angular/common';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { Router } from '@angular/router';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { forkJoin, switchMap } from 'rxjs';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { CalculoPlanejamentoKertzman } from 'src/app/modelos/previdenciario/calculoplanejamentokertzman';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { FiltrosMatriz } from 'src/app/modelos/previdenciario/filtrosmatriz';
import { CamposMatriz } from 'src/app/modelos/previdenciario/camposmatriz';
import { OpcoesMatriz } from 'src/app/modelos/previdenciario/opcoesmatriz';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { ImpostoRenda } from 'src/app/modelos/common/impostorenda';
import { ProdutoFinanceiro } from 'src/app/modelos/common/produtofinanceiro';
import { PerfisContributivos } from 'src/app/modelos/previdenciario/perfiscontributivos';
import { EdicaoSalarioAliquota } from 'src/app/modelos/previdenciario/edicaosalarioaliquota';
import { ContribuicaoEspecificaPlanejamento } from 'src/app/modelos/previdenciario/contribuicaoespecificaplanejamento';
import { LogService } from 'src/app/servicos/log.service';
import { Title } from '@angular/platform-browser';
import { AnonimoPipe } from 'src/app/aplicacao/compartilhado/pipes/anonimopipe';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { Atividade } from 'src/app/modelos/previdenciario/atividade';
import { Periodicidade } from 'src/app/modelos/previdenciario/periodicidade';
import { EdicaoImpostoRenda } from 'src/app/modelos/previdenciario/edicaoimpostorenda';
import { ResultadoMatrizComponent } from '../compartilhado/resultado-matriz/resultado-matriz.component';

@Component({
  selector: 'app-matriz',
  templateUrl: './matriz.component.html',
  styleUrl: './matriz.component.scss'
})
export class MatrizComponent implements OnInit, AfterViewInit, AfterViewChecked {



  @ViewChild('importadorCNIS') importadorCNIS: ImportadorCNISComponent;
  @ViewChild('editorCNIS') editorCNIS: EditorCNISComponent;
  @ViewChild('seletorCnis') seletorCnis: CnisListagemComponent;
  @ViewChild('resultadoMatriz') resultadoMatriz: ResultadoMatrizComponent;

  titulo: string = 'Cálculo de Simulação';
  aguardeVisivel: boolean;
  aguardeConfig: boolean;
  aguarde:boolean;
  matrizPlanejamento: MatrizPlanejamento;
  atualizarCalculoCnisOpcoesModificados: boolean;
  usuario: Usuario;




  private stepper: Stepper;

  constructor(private location: Location,
    private anonimoPipe: AnonimoPipe,
    private titleService: Title,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private calculeiApiService: CalculeiApiService,
    private datePipe: DatePipe,
    private notificacoesService: NotificacoesService,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private listarCalculoResultadoService: ListarCalculoResultadoService,
    private configService: ConfigService) {
      
    this.configService.getUsuario().subscribe(usuario => this.usuario = usuario);
    this.configService.getAguarde().subscribe(aguarde=>this.aguarde=aguarde);
  }

  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }



  ngAfterViewInit(): void {


    if (this.location.getState().hasOwnProperty('matriz')) {

      this.matrizPlanejamento = this.location.getState()['matriz'] as MatrizPlanejamento;
      this.stepper.to(4)
      this.atualizarCalculoCnisOpcoesModificados = false;

    } else {
      this.matrizPlanejamento = new MatrizPlanejamento();
      this.configService.getUsuario().subscribe(usuario => this.matrizPlanejamento.usuario = usuario);
      this.matrizPlanejamento.casos = new Array();
      this.matrizPlanejamento.data = new Date();
      this.matrizPlanejamento.nomeCalculo = "Nova Matriz de Planejamento";
      this.matrizPlanejamento.tipoCalculo = CalculoTipos.MATRIZPLANEJAMENTO;
      this.matrizPlanejamento.calculoBeneficios = new CalculoBeneficios();
      this.matrizPlanejamento.calculoBeneficios.dataCalculo = new Date();



      this.matrizPlanejamento.opcoesMatriz = new OpcoesMatriz();

      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento = new Array<OpcoesPlanejamento>();
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0] = new OpcoesPlanejamento();
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].edicaoSalarioAliquota = new EdicaoSalarioAliquota();
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].edicaoSalarioAliquota.aliquota = 0.20;

      this.configService.getSalarioTeto().subscribe(salarioTeto => {
        this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].edicaoSalarioAliquota.salario = salarioTeto;
        this.matrizPlanejamento.tetoInss = salarioTeto;
      });

      this.configService.getSalarioMinimo().subscribe(salarioMinimo => {
        this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].edicaoSalarioAliquota.salarioPiso = salarioMinimo;
        this.matrizPlanejamento.salarioMinimo = salarioMinimo;
      });
      this.configService.getSalarioTeto().subscribe(salarioTeto => this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].edicaoSalarioAliquota.salarioTeto = salarioTeto);
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;

      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.BASICO;
      // this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].produtoFinanceiro = new ProdutoFinanceiro();
      // this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].produtoFinanceiro.taxaInflacao = 1;
      // this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].produtoFinanceiro.taxaRetorno = 0.04;
      // this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].produtoFinanceiro.taxaTributacao = 1;

      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].impostoRenda = new ImpostoRenda();
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].impostoRenda.dependentes = 0;
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].impostoRenda.educacao = 0;
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].impostoRenda.investimento = false;
      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].impostoRenda.saude = 0;

      

      this.matrizPlanejamento.opcoesMatriz.otimizacaoKertzman = true;
      this.matrizPlanejamento.opcoesMatriz.aplicarCustoImpostoRenda = false;


      this.matrizPlanejamento.opcoesMatriz.listaRentabilidades = new Array();
      this.matrizPlanejamento.opcoesMatriz.listaAliquotas = new Array();
      this.matrizPlanejamento.opcoesMatriz.listaSalarios = new Array();
      
      this.matrizPlanejamento.opcoesMatriz.listaSalarios.push(this.matrizPlanejamento.salarioMinimo);
      this.matrizPlanejamento.opcoesMatriz.listaSalarios.push(this.matrizPlanejamento.tetoInss);

      this.matrizPlanejamento.opcoesMatriz.listaAliquotas.push(0.11);
      this.matrizPlanejamento.opcoesMatriz.listaAliquotas.push(0.20);


      this.matrizPlanejamento.opcoesMatriz.listaRentabilidades = new Array();
      this.matrizPlanejamento.opcoesMatriz.listaRentabilidades.push(0.04);

      this.matrizPlanejamento.opcoesMatriz.rentabilidade = 0.04;


      this.matrizPlanejamento.opcoesMatriz.opcoesPlanejamento[0].contribuicoesEspecificasPlanejamento = new Array<ContribuicaoEspecificaPlanejamento>();

      this.matrizPlanejamento.opcoesMatriz.filtros = new FiltrosMatriz();
      this.matrizPlanejamento.opcoesMatriz.exibirMarcadores = true;



      this.matrizPlanejamento.opcoesMatriz.campos = new CamposMatriz();

      this.matrizPlanejamento.opcoesMatriz.filtros.aliquota11 = true;
      this.matrizPlanejamento.opcoesMatriz.filtros.aliquota20 = true;
      this.matrizPlanejamento.opcoesMatriz.filtros.regraPontos = true;
      this.matrizPlanejamento.opcoesMatriz.filtros.regraIdadeMinimaProgressiva = true;
      this.matrizPlanejamento.opcoesMatriz.filtros.regraPedagio50 = true;
      this.matrizPlanejamento.opcoesMatriz.filtros.regraPedagio100 = true;
      this.matrizPlanejamento.opcoesMatriz.filtros.regraIdadeTransicao = true;


      this.matrizPlanejamento.opcoesMatriz.campos.aliquotaSalario = true;
      this.matrizPlanejamento.opcoesMatriz.campos.dataAposentadoria = true;
      this.matrizPlanejamento.opcoesMatriz.campos.regra = true;
      this.matrizPlanejamento.opcoesMatriz.campos.valorBeneficio = true;
      this.matrizPlanejamento.opcoesMatriz.campos.totalContribuicoes = true;
      this.matrizPlanejamento.opcoesMatriz.campos.valorContribuicao = true;
      this.matrizPlanejamento.opcoesMatriz.campos.totalInvestimento = true;
      this.matrizPlanejamento.opcoesMatriz.campos.roiAcumulado = true;
      this.matrizPlanejamento.opcoesMatriz.campos.taxaRetorno = true;
      this.matrizPlanejamento.opcoesMatriz.campos.payback = true;
      this.matrizPlanejamento.opcoesMatriz.campos.ganhoRoi = true;
      this.matrizPlanejamento.opcoesMatriz.campos.percentualTeto = true;
      this.matrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao = true;

      this.matrizPlanejamento.opcoesMatriz.intervalos = new Array<Periodicidade>();
      this.matrizPlanejamento.opcoesMatriz.intervalos.push(Periodicidade.MENSAL);



      this.matrizPlanejamento.opcoesMatriz.frequencias = new Array<OpcoesNumeroContribuicoesPlanejamento>();



      this.matrizPlanejamento.opcoesMatriz.atividades = new Array<Atividade>();

      this.matrizPlanejamento.opcoesMatriz.edicaoImpostoRenda = new EdicaoImpostoRenda();
      this.matrizPlanejamento.opcoesMatriz.edicaoImpostoRenda.considerarCustoImpostoRendaInvestimento = false;

      this.matrizPlanejamento.opcoesMatriz.edicoesSalarioAliquota = new Array<EdicaoSalarioAliquota>();
      let edicaoSalarioAliquota:EdicaoSalarioAliquota = new EdicaoSalarioAliquota();
      edicaoSalarioAliquota.aliquota = 0.2;
      edicaoSalarioAliquota.salario = this.matrizPlanejamento.tetoInss;
      edicaoSalarioAliquota.salarioPiso = this.matrizPlanejamento.salarioMinimo;
      edicaoSalarioAliquota.salarioTeto = this.matrizPlanejamento.tetoInss;
      this.matrizPlanejamento.opcoesMatriz.edicoesSalarioAliquota.push(edicaoSalarioAliquota);

      


    }


  }



  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    this.configService.getAguarde().subscribe(status => this.aguardeConfig = status);

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:
          if (this.matrizPlanejamento.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Escolha um CNIS antes de continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 2:
          if (this.matrizPlanejamento.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Escolha um CNIS antes de continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 3:
          if (this.matrizPlanejamento.calculoBeneficios.leituraCnis) {
            console.log('entrando no passo resultado');
            this.calcularResultado();
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Escolha um CNIS antes de continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:
          this.editorCNIS.salvarModificacoesCnis();
          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });


    if (!environment.production) {
      // this.mock();
    };

  }



  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculomatriz.json", request => {
      this.carregarDados(request as MatrizPlanejamento);
      this.atualizarCnisModificado();
      this.titleService.setTitle("Matriz de Planejamento " + (this.usuario.configuracao.anonimo ? this.anonimoPipe.transform(this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome) : this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome));
      this.matrizPlanejamento.nomeCalculo = "Matriz de Planejamento " + (this.usuario.configuracao.anonimo ? this.anonimoPipe.transform(this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome) : this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome);
      this.stepper.to(4);
      // this.atualizarCalculoCnisOpcoesModificados = true;
      
    });
  }


  carregarDados(calculo: MatrizPlanejamento) {
    this.atualizarCalculoCnisOpcoesModificados = false;
    this.matrizPlanejamento = calculo;
    LogService.log('atividades matriz', this.matrizPlanejamento.opcoesMatriz.atividades);

  }

  iniciar() {
  }


  leituraCNISEvent(leituraCNIS: LeituraCNIS) {
    this.configService.setAguarde(true);
    this.changeDetector.detectChanges()
    this.matrizPlanejamento.calculoBeneficios.leituraCnis = leituraCNIS;
    setTimeout(() => {
      this.cnisAtualizado();
    }, 100);

    this.titleService.setTitle("Matriz de Planejamento " + (this.usuario.configuracao.anonimo ? this.anonimoPipe.transform(this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome) : this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome));
    this.matrizPlanejamento.nomeCalculo = "Matriz de Planejamento " + (this.usuario.configuracao.anonimo ? this.anonimoPipe.transform(this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome) : this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome);
    
    this.proximo(true);
  }


  excluirCnis() {

    this.aguardeVisivel = true;
    this.editorCNIS.excluirCnis();
  }
  excluirCnisFinalizado(exclui: boolean) {
    // console.log('cnis excluido - beneficios component');
    this.editorCNIS.excluirFinalizado();
    // this.seletorCnis.carregarDados();

  }

  atualizarListaCnis(atualizar: boolean) {
    // this.seletorCnis.carregarDados();
  }


  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }
  pdf(event) {
  }


  cnisAtualizado() {
    // console.log('atualizando cnis beneficios');
    this.cnisOpcoesModificados(true);
    if (this.editorCNIS) {
      this.editorCNIS.carregarDados();
    }
  }
  cnisOpcoesModificados(event) {
    // console.log('cnis Modificado set true atualizarCalculo');
    this.atualizarCalculoCnisOpcoesModificados = true;
  }

  calcularResultado() {

    // if (!this.atualizarCalculoCnisOpcoesModificados) return;
    this.configService.setAguarde(true);



    this.calculeiApiService.api("CriarMatrizPlanejamento", this.matrizPlanejamento)
      .subscribe(resultado => {

        // console.log('resultado criar matriz planejamento', resultado);

        LogService.log("resultado criar matriz", resultado);

        this.matrizPlanejamento = resultado as MatrizPlanejamento;
        this.configService.setAguarde(false);
        // console.log('resultado matriz', this.matrizPlanejamento)
        this.atualizarCalculoCnisOpcoesModificados = false;
      });



  }

  salvarEdicaoCnis(event) {
    this.editorCNIS.salvarModificacoesCnis();
  }

  editarNomeCalculo(nome: string) {
    this.matrizPlanejamento.nomeCalculo = nome;
    this.salvarAtualizacaoMatriz(this.matrizPlanejamento);
  }

  salvarAtualizacaoMatriz(matriz: MatrizPlanejamento) {
    if (matriz.id && matriz.id.length > 0) {
      // console.log('matriz para atualizar', matriz)
      this.calculeiApiService.api("AtualizarMatrizPlanejamento", matriz)
        .subscribe(resultado => {
          // console.log('matriz atualizada', resultado);
          this.atualizarCalculoCnisOpcoesModificados = false;
        });

    }


  }
  atualizarCnisModificado() {
    this.configService.setAguarde(true);
    // this.calculoBeneficios = calculoBeneficios;
    this.calculeiApiService.api("AtualizarEdicaoCnis", this.matrizPlanejamento.calculoBeneficios)
      .subscribe(atualizacao => {
        this.matrizPlanejamento.calculoBeneficios = atualizacao as CalculoBeneficios;

        // LogService.log('cnis contribuicoes excedentes atualizar cnis modificado', this.matrizPlanejamento.calculoBeneficios.leituraCnis.cnis.contribuicoesExcedentes);
        // this.timeline.carregarDados(this.matrizPlanejamento.calculoBeneficios);
        // console.log('cnis atualizado', atualizacao);
        // this.cnisAtualizadoEvent.emit(this.calculoBeneficios);
        this.configService.setAguarde(false);
      });
  }


  pdfResultado(event){
    if (this.matrizPlanejamento.casos && this.matrizPlanejamento.casos.length>0) {
      this.resultadoMatriz.pdfMatriz();
    }
    else{
      this.notificacoesService.showNotification('default', 'Relatório PDF', 'Continue com o cálculo até o passo resultado para gerar o relatório');

    }
    

  }

}
