<div *ngIf="calculoLiquidacaoSentencaPrevidenciario && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos">
    <div class="row pb-2">
        <div class="col-12 d-flex">
            <div class="w-100">
                <div>
                    <h5 class="mb-0 linha-quadro">Hiscre</h5>
                </div>
                <div class="d-flex flex-column">
                    <div class="row py-4">
                        <div class="col">
                            <p class="h2">
    
                                Importar HISCRE
                                <i class="bi-question-circle" container="body"
                                    placement="right"
                                    tooltipClass="tooltip-fatorprevidenciario"
                                    tooltip=" Caso o segurado já tenha recebido algum valor referente ao benefício em questão, os abatimentos podem ser importados a partir do Histórico de Créditos emitido pelo INSS"></i>
    
                            </p>
                        </div>
                    </div>
                    <div class="row mt-auto">
                        <div class="col">
                            <app-importador-hiscre
                                (abatimentosHiscreEvent)="atualizarAbatimentosHiscre($event)"
                                (processandoHiscreEvent)="processandoHiscre($event)"></app-importador-hiscre>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    
    <div class="row py-2">
    
        <div class="col-12  d-flex">
            <div class="w-100">
                <div>
                    <h5 class="mb-0 linha-quadro">Evolução de RMI</h5>
                </div>
                <div class="d-flex flex-column">
                    <div class="row py-4">
                        <div class="col">
                            <p class="h2">
                                Gerar valores a serem descontados com base na RMI
                                <i
                                    class="bi-question-circle" container="body"
                                    placement="right"
                                    tooltipClass="tooltip-fatorprevidenciario"
                                    tooltip=" Quando o arquivo do histórico de crédito (Hiscre) não estiver disponível, uma aproximação dos valores pagos pode ser feita com base na DIB, DIP e na RMI. O valores serão gerados com base na evolução da RMI no período, inclusive décimo-terceiros quando aplicável">
                                </i>
                            </p>
                        </div>
                    </div>
                    <div class="row mt-auto">
                        <div class="col">
                            <app-nova-evolucao-rmi
                                *ngIf="calculoLiquidacaoSentencaPrevidenciario"
                                [tipoBeneficio]="tipoBeneficio"
                                (salvarAbatimentosNovaEvolucaoRMIEvent)="salvarAbatimentosNovaEvolucaoRMI($event)"
                                (salvarNovaEvolucaoRMIEvent)="salvarNovaEvolucaoRMI($event)"
                                (processandoNovaEvolucaoRMIEvent)="processandoNovaEvolucaoRMI($event)"></app-nova-evolucao-rmi>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    <div class="row py-2">
        <div class="col-12  d-flex">
            <div class="w-100">
                <div>
                    <h5 class="mb-0 linha-quadro">Formulário</h5>
                </div>
                <div class="d-flex flex-column">
                    <div class="row py-4">
                        <div class="col">
                            <p class="h2">
                                Preencher manualmente os valores a serem descontados
                                <i
                                    class="bi bi-question-circle" container="body"
                                    placement="right"
                                    tooltipClass="tooltip-fatorprevidenciario"
                                    tooltip=" Quando houver poucos valores a serem descontados, ou o HISCRE não estiver disponível, você pode incluir cada valor individualmente através deste formulário">
                                </i>
                            </p>
    
                        </div>
                    </div>
                    <div class="row mt-auto">
                        <div class="col">
                            <app-novo-valor-competencia
                                (salvarNovoAbatimentoEvent)="salvarNovoAbatimento($event)"></app-novo-valor-competencia>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    
    <div class="row py-2">
        <div class="col-12  d-flex">
            <div class="w-100">
                <div>
                    <h5 class="mb-0 linha-quadro">Percentual</h5>
                </div>
                <div class="d-flex flex-column">
                    <div class="row py-4">
                        <div class="col">
                            <p class="h2">
                                Descontar percentual do valor original de cada parcela
                            </p>
                        </div>
                    </div>
                    <div class="row mt-auto">
                        <div class="col-xl-2">
    
                            <label for="inputvalor" class="form-label">Percentual de Desconto</label>
                            <input  [inputMask]="jurosInputMask" (input)="incluirPercentualDescontoParcela($event)" placeholder="0,00" type="text" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
    
    <div *ngIf="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos.length > 0" class="row py-4">
        <div class=" col-12">
            <div class=" ">
                <div>
                    <h5 class="mb-0 linha-quadro">Abatimentos</h5>
                </div>
                <div>
                    <div class="row py-4">
                        <div class="col-lg-12">
                            <div class="dataTables_wrapper">
                                <ngx-datatable
                                    class="material striped selection-cell"
                                    [columnMode]="'force'"
                                    [headerHeight]="50"
                                    [footerHeight]="50"
                                    [rowHeight]="'auto'"
                                    [rows]="linhasAbatimentos">
    
                                    <ngx-datatable-column name="Competência"
                                        prop="competencia">
                                        <ng-template let-value="value" let-row="row"
                                            ngx-datatable-cell-template>
    
                                            <span>{{ value | date : "dd/MM/yyyy" }}</span>
                                            <span *ngIf="row.decimoTerceiro"> 13<sup>o</sup>
                                            </span>
                                        </ng-template>
    
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Valor" prop="valor">
    
                                        <ng-template let-value="value"
                                            let-rowIndex="rowIndex"
                                            ngx-datatable-cell-template>
                                            <span>{{value | currency}}</span>
                                        </ng-template>
                                    </ngx-datatable-column>
    
                                </ngx-datatable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>