



<div class="  ">

    <div class="pb-4">
      <div class="card">
        <div class="row">
          <div class="col">
            <div class="card-body">
              <span class=" display-3 font-weight-lighter  mb-0 text-default">
                Benefício
              </span>
  
  
            </div>
          </div>
  
        </div>
  
  
        <div class="row">
          <div class="col">


<form [formGroup]="beneficioForm">

    <div class="row">
        <div class=" col-12 col-xxl6">
            <div class=" card">
              <div class=" card-header border-dark"><h5 class="mb-0">Informações do Benefício</h5></div>
      
              <div class=" card-body">

                


             


                <div class="row">
                    <div class="col-lg-12">        
                      <label class=" form-control-label" for="example4cols1Input">
                        Tipo de Benefício
                      </label>
                      <div class=" py-2">

                        <ngx-select
                        formControlName="tipoBeneficio"
                        [items]="tiposBeneficios"
                        placeholder="Selecionar Tipo do Benefício"
                        (selectionChanges)="selectTipoBeneficioChange($event)">
                        </ngx-select>
        
                      
                      </div>
        
                    </div>
                  </div>



                <div class="row">
                    <div class="col-lg-12">
                        <div class=" py-2">
                            <label class="form-control-label" for="basic-url">Data de Início (DIB)</label>
                            <input
                            [inputMask]="dateInputMask"
                            class=" form-control"
                            
                            placeholder="dd/mm/aaaa"
                            type="text"
                            formControlName="dib"/>
                        </div>
                    </div>
                </div>
        
                <!-- <div class="row">
                    <div class="col-lg-12">
                        <div class=" py-2">
                            <label class="form-control-label" for="basic-url">Data de Início dos Pagamentos (DIP) ou Fim dos Atrasados</label>
                            <input
                            [inputMask]="dateInputMask"
                            class=" form-control"
                            
                            placeholder="dd/mm/aaaa"
                            type="text"
                            formControlName="dip"/>
                        </div>
                    </div>
                </div> -->
        
        
                <div class="row">
                    <div class="col-lg-12">
                        <div class=" py-2">
                            <label class="form-control-label" for="basic-url">Renda Mensal Inicial (RMI)</label>
                            <input
                            
                            [inputMask]="currencyInputMask"
                            class=" form-control text-left"
                            
                            placeholder="R$ 0,00"
                            type="text"
                            formControlName="rmi"/>
                        </div>
                    </div>
                </div>
        
                <div class="row">
                    <div class="col-lg-12">
                        <div class=" py-2">
                            <label class="form-control-label" for="basic-url">Data de Início do Adiantamento de 25% <sup>opcional</sup></label>
                            <input
                            [inputMask]="dateInputMask"
                            class=" form-control"
                            
                            placeholder="dd/mm/aaaa"
                            type="text"
                            formControlName="dib25"/>
                        </div>
                    </div>
                </div>
    
                  
              </div>
            </div>
          </div>
    </div>
</form>

  
          </div>
        </div>
  
  
      </div>
  
  
  
    </div>
  
  
  </div>
  