<div class=" card">
    <div class=" card-header border-dark">
        <h5 class="mb-0">Novo Plano</h5>
    </div>
    <div class=" card-body">
        <form [formGroup]="planoForm">



            <div class="row py-4">
                <div class="col-4 py-4" popoverTitle="planOption" [popover]="'Modelos de plano 1 = Personalizado 2 = Fixo  Ex: 1'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default" >
                        planOption
                    </h5>

                    <ngx-select formControlName="planOption" [defaultValue]="planOptionDefault"
                        [items]="listaPlanOption" placeholder="Tipo de Plano Fixo ou Personalizado"
                        (select)="selectPlanOption($event)" (selectionChanges)="selectPlanOptionChange($event)">
                    </ngx-select>

                </div>


                <div class="col-4 py-4" popoverTitle="planFrequence" [popover]="'Frequências para o plano 1 = Mensal 2 = Bimestral 3 = Trimestral 4 = Semestral 5 = Anual 6 = Semanal PlanFrequence: se você optou pelo plano Personalizado, só será possível utilizar as frequências Mensal e Semanal. Para o Plano fixo, todas as frequências podem ser utilizadas.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        planFrequence
                    </h5>

                    <ngx-select formControlName="planFrequence" [defaultValue]="planFrequenceDefault"
                        [items]="listaPlanFrequence" placeholder="Frquencia da Cobrança"
                        (select)="selectPlanFrequence($event)" (selectionChanges)="selectPlanFrequenceChange($event)">
                    </ngx-select>

                </div>

                <!-- <div class="col-4 py-4" popoverTitle="chargeDay" [popover]="' ChargeDay: esse campo é obrigatório se o Plano for Personalizado e a frequência for Mensal. Caso o Plano for Fixo, esse campo não deve ser informado. Para Cartão de crédito, esse será o dia em que a cobrança será efetuada. Para Boleto Bancário, esse será o dia do vencimento do boleto.  Ex: 30'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        chargeDay
                    </h5>

                    <input type="text"
                        placeholder="para cartão é o dia do mês que será feita a cobrança, para boleto dia do vencimento do boleto"
                        formControlName="chargeDay" class="form-control">

                </div> -->


                <!-- <div class="col-4 py-4" popoverTitle="dayOfWeek" [popover]="'DayOfWeek: esse campo é obrigatório se o Plano for Personalizado e a frequência for Semanal. Caso o Plano for Fixo, esse campo não deve ser informado'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        dayOfWeek
                    </h5>

                    <ngx-select formControlName="dayOfWeek" [defaultValue]="dayOfWeekDefault" [items]="listaDayOfWeek"
                        placeholder="Dia da semana " (select)="selectDayOfWeek($event)"
                        (selectionChanges)="selectDayOfWeekChange($event)">
                    </ngx-select>

                </div> -->




                <div class="col-4 py-4" popoverTitle="name" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        name
                    </h5>

                    <input type="text" placeholder="nome do plano" formControlName="name" class="form-control">

                </div>



                <div class="col-4 py-4" popoverTitle="description" [popover]="'descrição do plano'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        description
                    </h5>

                    <input type="text" placeholder="description do plano" formControlName="description"
                        class="form-control">

                </div>



                <div class="col-4 py-4" popoverTitle="amount" [popover]="'Valor cobrado a cada renovação do ciclo do plano.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        amount
                    </h5>

                    <input type="text" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" formControlName="amount"
                        class="form-control">

                </div>


                <!-- <div class="col-4 py-4" popoverTitle="subscriptionTax" [popover]="'Taxa de assinatura, cobrada na primeiro ciclo do plano.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        subscriptionTax
                    </h5>

                    <input type="text" placeholder="taxa de assinatura cobrada no primeiro ciclo do plano"
                        formControlName="subscriptionTax" class="form-control">

                </div> -->


                <!-- <div class="col-4 py-4" popoverTitle="subscriptionLimit" [popover]="'SubscriptionLimit: caso esse campo não seja informado, o plano não terá limite de assinaturas. Limite de adesões que serão permitidas no plano.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        subscriptionLimit
                    </h5>

                    <input type="text" placeholder="total de adesoes. nao informar para ficar sem limite"
                        formControlName="subscriptionLimit" class="form-control">

                </div> -->




                <!-- <div class="col-4 py-4" popoverTitle="IsImmediateCharge" [popover]="'IsImmediateCharge: caso esse campo seja true, a cobrança será gerada em até 01 hora, após a assinatura. Se a cobrança deve ser realizada em até 01 hora, após a assinatura ser realizada '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        IsImmediateCharge
                    </h5>
                    <div class="form-group mb-3">
                        <div class="btn-group " btnRadioGroup formControlName="isImmediateCharge">
                            <label [btnRadio]="false" class="btn btn-outline-black mb-0">Cobrança Não Imediata</label>
                            <label [btnRadio]="true" class="btn btn-outline-black mb-0">Cobrança Imediata</label>

                        </div>
                    </div>

                </div> -->



                <!-- <div class="col-4 py-4" popoverTitle="isProRata" [popover]="'IsProRata: caso esse campo seja true, o sistema irá gerar a primeira cobrança com um valor proporcional ao uso. Se o plano deve cobrar um valor proporcional ao uso na primeira cobrança. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        isProRata
                    </h5>
                    <div class="form-group mb-3">
                        <div class="btn-group " btnRadioGroup formControlName="isProRata">
                            <label [btnRadio]="false" class="btn btn-outline-black mb-0">Não Pro Rata</label>
                            <label [btnRadio]="true" class="btn btn-outline-black mb-0">Pro Rata</label>

                        </div>
                    </div>

                </div> -->


                <!-- <div class="col-4 py-4" popoverTitle="daysBeforeChargeDateExpiration" [popover]="'Dias que antecedem o vencimento para envio do boleto.DaysBeforeChargeDateExpiration: esse número será subtraído do dia de vencimento da cobrança, para realizar o envio do boleto. (Configuração específica para boleto bancário)'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        daysBeforeChargeDateExpiration
                    </h5>

                    <input type="text" placeholder="total de adesoes. nao informar para ficar sem limite"
                        formControlName="daysBeforeChargeDateExpiration" class="form-control">

                </div> -->


              


                <!-- <div class="col-4 py-4" popoverTitle="BillingCycle" [popover]="'BillingCycle: caso esse campo não seja informado, o plano não terá limite de ciclos. Quantidade de ciclos para o plano ser encerrado. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        BillingCycle
                    </h5>

                    <input type="text" placeholder="quantidade de ciclos maxima. nao enviar para nao ter limite"
                        formControlName="billingCycle" class="form-control">

                </div> -->




                <!-- <div class="col-4 py-4" popoverTitle="CallbackUrl" [popover]="'Endereço de Callback. Ex: https://callbacks.exemplo.com.br/api/Notify'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        CallbackUrl
                    </h5>

                    <input type="text"
                        placeholder="endereço url de callback https://callbacks.exemplo.com.br/api/Notify"
                        formControlName="callbackUrl" class="form-control">

                </div> -->







                <!-- <div class="col-4 py-4" popoverTitle="DaysDue" [popover]="'Dia do vencimento ex 10'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        DaysDue
                    </h5>

                    <input type="text" placeholder="Dia do vencimento " formControlName="daysDue" class="form-control">

                </div> -->





                <!-- <div class="col-4 py-4" popoverTitle="Instruction" [popover]="'Instrução que deve ser enviada no boleto  Ex: Pagável até o vencimento'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        Instruction
                    </h5>

                    <input type="text"
                        placeholder="Instrução que deve ser enviada no boleto Ex: Pagável até o vencimento "
                        formControlName="instruction" class="form-control">

                </div> -->



                <!-- <div class="col-4 py-4" popoverTitle="PenaltyAmount" [popover]="'Multa que deve ser aplicada após o vencimento Ex: 2.00'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        PenaltyAmount
                    </h5>

                    <input type="text" placeholder="Multa que deve ser aplicada após o vencimento ex. 2.00 "
                        formControlName="penaltyAmount" class="form-control">

                </div> -->


                <!-- <div class="col-4 py-4" popoverTitle="InterestAmount" [popover]="'Juros que deve ser aplicado após o vencimento Ex: 2.00'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        InterestAmount
                    </h5>

                    <input type="text" placeholder="Juros que deve ser aplicado após o vencimento ex: 2.00 "
                        formControlName="interestAmount" class="form-control">

                </div> -->


                <!-- <div class="col-4 py-4" popoverTitle="DiscountType" [popover]="' Informe esse campo apenas se o tipo do plano for personalizado 1 = valor 2 = Percentual Ex: 1 '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        DiscountType
                    </h5>

                    <ngx-select formControlName="discountType" [defaultValue]="discountTypeDefault"
                        [items]="listaDiscountType"
                        placeholder="Informe esse campo apenas se o tipo do plano for personalizado "
                        (select)="selectDiscountType($event)" (selectionChanges)="selectDiscountTypeChange($event)">
                    </ngx-select>

                </div> -->




                <!-- <div class="col-4 py-4" popoverTitle="DiscountDue" [popover]="'Quantidade de dias de limite para o desconto.  ex Data'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        DiscountDue
                    </h5>

                    <input type="text" placeholder="Quantidade de dias de limite para o desconto.  data "
                        formControlName="discountDue" class="form-control">

                </div> -->




                <!-- <div class="col-4 py-4" popoverTitle="planOption" [popover]="'Valor do desconto. Informar apenas se houver desconto para o boleto. Valor será adicionado em campo separado no registro Ex: 2.00'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        DiscountAmount
                    </h5>

                    <input type="text"
                        placeholder="Valor do desconto. Informar apenas se houver desconto para o boleto. Valor será adicionado em campo separado no registro  "
                        formControlName="discountAmount" class="form-control">

                </div> -->




            </div>





        </form>


















        <form [formGroup]="inserirPlanoForm">



            <div class="row py-4">
                <div class="col-4 py-4" popoverTitle="descricaoPagamento" [popover]="'Modelos de plano 1 = Personalizado 2 = Fixo  Ex: 1'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default" >
                        descricaoPagamento
                    </h5>

                    <input type="text" placeholder="nome do plano" formControlName="descricaoPagamento" class="form-control">

                </div>


                <div class="col-4 py-4" popoverTitle="vantagensPagamento" [popover]="'Frequências para o plano 1 = Mensal 2 = Bimestral 3 = Trimestral 4 = Semestral 5 = Anual 6 = Semanal PlanFrequence: se você optou pelo plano Personalizado, só será possível utilizar as frequências Mensal e Semanal. Para o Plano fixo, todas as frequências podem ser utilizadas.'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        vantagensPagamento
                    </h5>

                    <input type="text" placeholder="nome do plano" formControlName="vantagensPagamento" class="form-control">

                </div>

             




                <!-- <div class="col-4 py-4" popoverTitle="valorOriginal" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        valorOriginal
                    </h5>

                    <input type="text" [inputMask]="currencyInputMask" placeholder="R$ 0,00" formControlName="valorOriginal" class="form-control">

                </div> -->

                <div class="col-4 py-4" popoverTitle="desconto" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        desconto
                    </h5>

                    <input type="text" [inputMask]="jurosInputMask" placeholder=" 0.00 %" formControlName="desconto" class="form-control">

                </div>

                <div class="col-4 py-4" popoverTitle="descontoMeioPagamento" [popover]="'Nome do plano. '"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        descontoMeioPagamento
                    </h5>

                    <input type="text" [inputMask]="jurosInputMask" placeholder=" 0.00 %" formControlName="descontoMeioPagamento" class="form-control">

                </div>



                <div class="col-4 py-4" popoverTitle="meioPagamento" [popover]="'descrição do plano'"  triggers="mouseenter:mouseleave">

                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        meioPagamento
                    </h5>

                    <ngx-select formControlName="meioPagamento" 
                    [items]="listaMeiosPagamento" placeholder="Forma de pagamento"
                    (select)="selectMeioPagamento($event)">
                </ngx-select>

                </div>



            
               



            </div>





        </form>






        <div class="row">
            <div class="col-12">
                <div class="col-xl-12 col-md-12 col-lg-12  d-flex flex-row-reverse">
                    <div class="row ">
    
                        <div class="col-auto " [className]="aguardeVisivel ? 'visible col-auto' : 'invisible col-auto'">
                            <div class="col-1 p-4 mb-5  controlenav  ">
                                <span class=" timeline-step timeline-step-xlg text-default">
                                    <h1 class="display-1">
                                        <i class="fas fa-spinner fa-spin fa-2xl"></i>
                                    </h1>
                                </span>
                            </div>
                        </div>
                        <div class="col-auto "
                            [className]="continuarHabilitado ? 'habilitado col-auto' : 'desabilitado col-auto'"
                            (click)="continuar()">
                            <div class="col-1 p-4 mb-5 active controlenav ">
                                <span class=" timeline-step timeline-step-xlg  ">
                                    <i class="bi-arrow-right"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>














    </div>
</div>