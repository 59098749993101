import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { CalculoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/calculoplanejamentoprevidenciario';
import { EdicaoSalarioAliquota } from 'src/app/modelos/previdenciario/edicaosalarioaliquota';
import { OpcaoEnquadramentoSeguradoPlanejamento } from 'src/app/modelos/previdenciario/opcaoenquadramentoseguradoplanejamento';
import { OpcoesDataLimitePlanejamento } from 'src/app/modelos/previdenciario/opcoesdatalimiteplanejamento';
import { OpcoesImpostoRendaPlanejamento } from 'src/app/modelos/previdenciario/opcoesimpostorendaplanejamento';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { PerfisContributivos } from 'src/app/modelos/previdenciario/perfiscontributivos';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { Navegacao } from 'src/app/modelos/ui/navegacao';

import { OpcoesImpostoRendaComponent } from '../opcoes-imposto-renda/opcoes-imposto-renda.component';
import { OpcoesProdutoPrivadoComparativoKertzmanComponent } from '../opcoes-produto-privado-comparativo-kertzman/opcoes-produto-privado-comparativo-kertzman.component';
import * as currency from 'currency.js';
import { ProdutoFinanceiro } from 'src/app/modelos/common/produtofinanceiro';
import { ImpostoRenda } from 'src/app/modelos/common/impostorenda';
import { OpcaoNumeroContribuicoesPipe } from 'src/app/aplicacao/compartilhado/pipes/opcoesnumerocontribuicoespipe';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { LogService } from 'src/app/servicos/log.service';
import { Atividade } from 'src/app/modelos/previdenciario/atividade';
import { Periodicidade } from 'src/app/modelos/previdenciario/periodicidade';


@Component({
  selector: 'app-editor-enquadramento-contributivo',
  templateUrl: './editor-enquadramento-contributivo.component.html',
  styleUrls: ['./editor-enquadramento-contributivo.component.scss']
})
export class EditorEnquadramentoContributivoComponent implements OnInit, AfterViewInit {



  @Input() opcoesPlanejamento: OpcoesPlanejamento;
  @Input() esconderOpcaoQuantidadeContribuicoes: boolean;
  @Output() atualizarOpcoesEvent = new EventEmitter<any>();

  perfisContributivosType = PerfisContributivos;
  opcoesNumeroContribuicoesPlanejamentoType = OpcoesNumeroContribuicoesPlanejamento;
  atualizarOutrosValores: boolean = false;

  
  periodicidadeOptions = Object.values(Periodicidade).filter(value => typeof value === 'number');




  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);
  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,

    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0',
    autoUnmask: true,
    unmaskAsNumber: true,
    onBeforeMask(initialValue, opts) {
      return !isNaN(Number.parseFloat(initialValue)) ? (Number.parseFloat(initialValue) * 10000).toString() : this.opcoesPlanejamento?.rentabilidade
    },
    onUnMask: (masked, unmasked) => {
      // this.valorAliquotaSelecionada = Number.parseInt(unmasked); //currency(masked, {separator: '.', decimal: ','}).value;
      LogService.log('unmasked', Number.parseInt(unmasked)/10000);
      this.opcoesPlanejamento.rentabilidade = Number.parseInt(unmasked)/10000
      return unmasked;
    }
  });

  aliquotaInputMask = createMask(AppComponent.aliquotaInputMask);




  radioModel = 'teto20';

  numeroContribuicoesForm = this.formBuilder.group({
    numeroContribuicoes: new FormControl('')
  });

  opcaoImpostoRendaForm = this.formBuilder.group({
    opcaoIsencao: new FormControl('')
  });
  visualizarEditorData: boolean;

  opcaoNumeroContribuicoesPlanejamentoSelecionado: OpcoesNumeroContribuicoesPlanejamento;
  numeroContribuicoesSelecionado: number;
  visualizarEditorNumeroContribuicoes: boolean;

  produtoFinanceiro: ProdutoFinanceiro;

  formParametrosProdutoFinanceiro = this.formBuilder.group({
    taxaRetorno: [0, Validators.required],
    taxaTributacao: [0, Validators.required],
    taxaInflacao: [0]
  });

  


  @Input() edicaoSalarioAliquota: EdicaoSalarioAliquota;
  @Output() perfilContributivoSelecionadoEvent = new EventEmitter<EdicaoSalarioAliquota>();


  valorSalarioBaseSelecionado: number;
  valorSalarioBaseSelecionadoInput: number;
  // this.valorSalarioBaseSelecionado = currency(this.valorSalarioBaseSelecionado, {separator: '.', decimal: ','}).value
  valorAliquotaSelecionada: number;
  valorAliquotaSelecionadaInput: number;

  salarioPiso: number;
  salarioTeto: number;

  editarSalarioDesabilitado: boolean;
  editarAliquotaDesabilitado: boolean;

  perfilContributivo: PerfisContributivos;



  constructor(private currencyPipe: CurrencyPipe, private decimalPipe: DecimalPipe,
    private formBuilder: FormBuilder) {

    this.aliquotaInputMask.oncomplete = () => {
      this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota = this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota / 10000;
      LogService.log('oncomplete', this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota);

    }
    // this.opcoesPlanejamento = new OpcoesPlanejamento();

    // this.produtoFinanceiro = new ProdutoFinanceiro();
    // this.produtoFinanceiro.taxaInflacao = 4.9;
    // this.produtoFinanceiro.taxaRetorno = 13;
    // this.produtoFinanceiro.taxaTributacao = 15;

  }

  ngOnInit(): void {

    this.valorAliquotaSelecionada = this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota;
  }

  ngAfterViewInit(): void {
  }



  perfilContributivoSelecionado(edicaoSalarioAliquota: EdicaoSalarioAliquota) {
  }


  continuar() {
  }


  atualizarAliquota(event) {
    // console.log('atualizar aliquota event', event);
    // console.log(this.radioModel);

    let opcao: PerfisContributivos = event;
    switch (opcao) {
      case PerfisContributivos.SALARIOTETO20:
        this.opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.SALARIOTETO20;
        this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota = 0.2;
        this.opcoesPlanejamento.edicaoSalarioAliquota.salario = this.opcoesPlanejamento.edicaoSalarioAliquota.salarioTeto;
        this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);
        this.atualizarOutrosValores = false;
        break;
      case PerfisContributivos.SALARIOMINIMO11:
        this.opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.SALARIOMINIMO11;
        this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota = 0.11;
        this.opcoesPlanejamento.edicaoSalarioAliquota.salario = this.opcoesPlanejamento.edicaoSalarioAliquota.salarioPiso;
        this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);
        this.atualizarOutrosValores = false;
        break;

      case PerfisContributivos.SALARIOMINIMO20:
        this.opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.SALARIOMINIMO20;
        this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota = 0.2;
        this.opcoesPlanejamento.edicaoSalarioAliquota.salario = this.opcoesPlanejamento.edicaoSalarioAliquota.salarioPiso;
        this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);
        this.atualizarOutrosValores = false;
        break;


      case PerfisContributivos.SALARIOTETO11:

        this.opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.SALARIOTETO11;
        this.opcoesPlanejamento.edicaoSalarioAliquota.aliquota = 0.11;
        this.opcoesPlanejamento.edicaoSalarioAliquota.salario = this.opcoesPlanejamento.edicaoSalarioAliquota.salarioTeto;
        this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);
        this.atualizarOutrosValores = false;
        break;
      case PerfisContributivos.OUTROSVALORES:
        this.opcoesPlanejamento.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
        this.atualizarOutrosValores = true;
        break;

      default:
        break;
    }

  }

  atualizarOpcaoNumeroContribuicoesPlanejamento(event) {

    // console.log('atualizar opcao numero de contribuicoes event', event);


    let opcao: OpcoesNumeroContribuicoesPlanejamento = event;
    switch (opcao) {
      case OpcoesNumeroContribuicoesPlanejamento.COMPLETO:
        this.opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.COMPLETO;

        break;
      case OpcoesNumeroContribuicoesPlanejamento.BASICO:
        this.opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.BASICO;

        break;
      case OpcoesNumeroContribuicoesPlanejamento.MANTERQUALIDADESEGURADO:

        this.opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.MANTERQUALIDADESEGURADO;

        break;

      case OpcoesNumeroContribuicoesPlanejamento.PERIODOMESES:
        LogService.log('opcao 1 contribuição a cada x meses');

        this.opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.PERIODOMESES;

        break;

      case OpcoesNumeroContribuicoesPlanejamento.ESPECIFICO:

        LogService.log('opcao total especifico contribuicoes');

        this.opcoesPlanejamento.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.ESPECIFICO;

        break;


      default:
        break;
    }

    this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);

  }

  atualizarImpostoRenda(event) {

    this.opcoesPlanejamento.impostoRenda.investimento = event;
    this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);
  }

  atualizarOutrosValoresAliquotaSalario() {
    this.atualizarOpcoesEvent.emit(this.opcoesPlanejamento);
  }

  editarPeriodicidade(periodicidade) {
    this.opcoesPlanejamento.periodicidade = periodicidade;
  }
}
