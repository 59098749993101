import { Base } from "../base";
import { Beneficio } from "./beneficio";
import { CasoPlanejamentoPrevidenciario } from "./casoplanejamentoprevidenciario";
import { Contribuicao } from "./contribuicao";

export class RelatorioKertzman extends Base {
    otimizacao:CasoPlanejamentoPrevidenciario;
    variacaoSubstituicoesTeto:number[];
    variacaoRmi:number[];
    variacaoPayback:number[];
    variacaoTaxaPayback:number[];
    variacaoLucroPrevidencia:number[];
    variacaoLucroPrivado:number[];
    contribuicoesOtimizacao:Contribuicao[];
    preAnalise:Beneficio;
    
}