<div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman?.relatorio?.preAnalise">

  <div class="row pb-2">
    <div class="col">
      <h5 class="mb-0 linha-quadro"></h5>
      <div class="row">
        <div class="col-6">
          <h5 class="mb-0">Situação Atual
            <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroPreanalise" aria-expanded="false" aria-controls="helpQuadroPreanalise"></i>
          </h5>
        </div>
        <div class="col-6">
          <div class="text-right">
            <i class="comando bi bi-filetype-pdf fs-6" role="button" container="body" (click)="gerarPdf()" ngbTooltip="Salvar PDF" triggers="hover"></i>
          </div>
        </div>
      </div>

      <div class="collapse" id="helpQuadroPreanalise">

        <div class="row py-4 d-flex ">

          <div class="col-3 py-2 d-flex">
            <div class="row w-100 bg-secondary rounded m-2">
              <div class="col d-flex p-2">

                <p class=" p-2 lh-1">
                  Situação Atual
                </p>

              </div>
              <!-- <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div> -->

            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

  <div *ngIf="calculoPlanejamentoKertzman && calculoPlanejamentoKertzman.relatorio.preAnalise" class="row">
    <div class="col-4">
      <div>
        <div>
          <p class="text-center fw-lighter h6 text-uppercase">Aposentadoria por Idade</p>
        </div>
        <p class="display-1 lh-1  mb-0">
          {{calculoPlanejamentoKertzman.relatorio.preAnalise.relatorioRendaMensalInicial.rendaMensalInicial | currency}}
        </p>
        <p class="lh-1 fw-light">
          Valor do benefício
        </p>
      </div>

    </div>
    <div class="col-4">
      <div>
        <div>
          <p class="text-center fw-lighter h6 text-uppercase">Requisitos</p>
        </div>

        <div>
          <p class="display-4 lh-1 fw-lighter mb-0">
            Idade
          </p>

          <p class="lh-1 fw-lighter">

            Faltam: {{calculoPlanejamentoKertzman.relatorio.preAnalise.idadeComplementar.porExtenso}}
          </p>
        </div>

        <div>
          <p class="display-4 lh-1 fw-lighter mb-0">
            Tempo de contribuição

          </p>

          <p class="lh-1 fw-lighter">
            Faltam {{calculoPlanejamentoKertzman.relatorio.preAnalise.tempoContribuicaoComplementar.porExtenso}}
          </p>
        </div>

        <div>
          <p class="display-4 lh-1 fw-lighter mb-0">
            Contribuições
          </p>

          <p class="lh-1 fw-lighter">

            Faltam: {{calculoPlanejamentoKertzman.relatorio.preAnalise.carenciaComplementar.porExtenso}}
          </p>
        </div>

      </div>

    </div>

  </div>

  <!-- <app-destaque-pre-analise-cumprimento-requisitos *ngIf="calculoPlanejamentoKertzman.preAnalise" [destaque]="calculoPlanejamentoKertzman.preAnalise"></app-destaque-pre-analise-cumprimento-requisitos> -->

  <app-evolucao-salarios [exibirY]="false" [calculoPlanejamentoKertzman]="calculoPlanejamentoKertzman"></app-evolucao-salarios>

  <!-- <app-evolucao-salarios-pos-real [exibirY]="false" [beneficio]="calculoPlanejamentoKertzman.relatorio.preAnalise"></app-evolucao-salarios-pos-real> -->

  <!-- <app-relatorio-beneficio #relatorioBeneficio *ngIf="calculoPlanejamentoKertzman.relatorio.preAnalise" [beneficio]="calculoPlanejamentoKertzman.relatorio.preAnalise"></app-relatorio-beneficio> -->

  <!-- <app-quadro-carencia-planejamento *ngIf="calculoPlanejamentoKertzman.preAnalise" [beneficio]="calculoPlanejamentoKertzman.preAnalise"></app-quadro-carencia-planejamento> -->

  <!-- <app-quadro-descartes *ngIf="calculoPlanejamentoKertzman.preAnalise" [beneficio]="calculoPlanejamentoKertzman.preAnalise"></app-quadro-descartes> -->

</div>