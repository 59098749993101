<div class="row ">
  <div class="col">
    <h5 class="mb-0 linha-quadro">Importar CNIS
      <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroImportarCnis" aria-expanded="false" aria-controls="helpQuadroImportarCnis"></i>
    </h5>

    <div class="collapse" id="helpQuadroImportarCnis">

      <div class="row py-4 d-flex ">

        <div class="col-3 py-2 d-flex">
          <div class="row bg-secondary rounded m-2">
            <div class="col d-flex p-2">

              <p class="p-2 text-justify">
                Vamos começar escolhendo o CNIS. Verifique nas suas pastas onde está o CNIS que você deseja trabalhar. Para importar o CNIS para o calculei, utilize o botão "Selecionar arquivo da pasta", ou você pode arrastar direto da pasta para a área indicada "arrastar o CNIS aqui"
              </p>

            </div>
            <div class="mt-auto pb-2 text-right">
              <i class="comando bi bi-filetype-pdf fs-3"></i>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>

</div>



<div [className]="!aguarde ? 'visible py-4' : 'invisible py-4'">

  <div class="row py-2 d-flex">

    <div class="col-3 d-flex align-items-center justify-content-center">
  
      <div class="d-inline-flex">
        <input style="display: none" type="file" #fileInput (change)="selecionarArquivo($event)" />
        <button type="button" (click)="showFileDialog()" class="btn btn-outline-black">
          Selecionar arquivo da pasta
        </button>
      </div>
  
    </div>
    <div class="col-1 d-flex align-items-center justify-content-center">
      <div class="vr"></div>
    </div>
  
    <div class="col-8 align-items-center justify-content-center">
      <ngx-file-drop dropZoneLabel="Arraste o CNIS aqui" (onFileDrop)="dropped($event)"
        (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)" dropZoneClassName="dropcnis" contentClassName="contentdropcnis">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
          <div class="p-6 dropper text-center flex rounded">
            arraste e solte o CNIS aqui
          </div>
          <!-- <button type="button" (click)="openFileSelector()">Browse Files</button> -->
        </ng-template>
      </ngx-file-drop>
  
    </div>
  </div>
  

</div>

<div *ngIf="aguarde">
  <div class="d-flex justify-content-center">
      <app-aguarde-config [aguarde]="aguarde"></app-aguarde-config>
  </div>
</div>
