<div *ngIf="calculoBeneficios">
    <h5 class="mb-0 linha-quadro">Segurado</h5>
    <div class="row py-2">
        <div class="col-3 py-1">
            <p class="h2">
                Nome
            </p>
            <div>
                <div [className]="!calculoBeneficios.usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                    <p>
                        {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
                    </p>
                </div>
                <div [className]="calculoBeneficios.usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                    <p>
                        {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome | anonimo}}
                    </p>

                </div>
            </div>
        </div>

        <div class="col-3 py-1">
            <p class="h2">
                CPF
            </p>
            <div [className]="!calculoBeneficios.usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                <p>
                    {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}
                </p>
            </div>
            <div [className]="calculoBeneficios.usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                <p>
                    000.000.000-00
                </p>
            </div>
        </div>
        <div class="col-3 py-1">
            <p class="h2">
                NIT
            </p>

            <div [className]="!calculoBeneficios.usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                <p>
                    {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nit}}
                </p>
            </div>
            <div [className]="calculoBeneficios.usuario.configuracao.anonimo ? 'visible' : 'invisible'">
                <p>
                    000.00000.00-0
                </p>
            </div>

        </div>
        <div class="col-3 py-1">
            <p class="h2">
                Data de Nascimento
            </p>
            <p>
                
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
            </p>

        </div>
        <div class="col-3 py-1">
            <p class="h2">
                Idade
            </p>
            <p>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}
            </p>
        </div>
        <div class="col-3 py-1">
            <p class="h2">
                Sexo
            </p>
            <div>
                <p>
                    {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo}}
                    
                </p>

            </div>
            
        </div>
        <div class="col-3 py-1">
            <p class="h2">
                Data do Extrato Previdenciário
            </p>
            <p>
                {{calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataExtratoPrevidenciario | date : 'dd/MM/yyyy'}}
            </p>
        </div>
        <div class="col-3 py-1">
            <p class="h2">
                Data do Cálculo
            </p>
            <p>
                {{calculoBeneficios.dataCalculo| date : 'dd/MM/yyyy'}}
                
            </p>
            
        </div>

    </div>

</div>
