<div class="row">
  <div class="col">
    <h5 class="mb-0 linha-quadro">
      Matriz de Planos
      <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpMatriz" aria-expanded="false" aria-controls="helpMatriz"></i>
    </h5>

    <div class="collapse" id="helpMatriz">

      <div class="row py-4 d-flex ">
        <div class="col">

          <p>
            A <strong>Matriz de Planos</strong> apresenta uma visão consolidada dos cenários de aposentadoria simulados pelo
            <strong>Calculei</strong>, permitindo que você compare os diferentes planos de forma prática. Além disso, a matriz é apresentada como um
            <strong>heatmap</strong>, onde as células têm cores mais escuras para valores mais altos, facilitando a visualização das melhores opções.
          </p>

          <h3><strong>Estrutura da Matriz</strong></h3>
          <ul>
            <li>
              <strong>Linhas:</strong> Representam as alíquotas e os salários das contribuições simuladas, conforme os dados informados.
            </li>
            <li>
              <strong>Colunas:</strong> Cada coluna corresponde a um plano de aposentadoria. O nome do plano é composto pela regra de aposentadoria aplicável no INSS (por exemplo, "Regra de Transição" ou "Aposentadoria por Idade") e o número de contribuições previstas na simulação para aquele plano.
            </li>
            <li>
              <strong>Informação apresentada:</strong> Por padrão, a matriz exibe o <strong>valor do benefício estimado</strong> de cada plano. O usuário pode trocar essa informação por outras opções, como:
              <ul>
                <li>Total do investimento.</li>
                <li>Taxa de retorno (ROI).</li>
                <li>Retorno estimado ao longo do tempo.</li>
                <li>Porcentagem do teto previdenciário.</li>
              </ul>
            </li>
            <li>
              <strong>Heatmap:</strong> As células da matriz são coloridas de acordo com os valores apresentados. Valores mais altos aparecem com cores mais escuras, permitindo uma identificação rápida dos planos mais vantajosos.
            </li>
          </ul>

          <h3><strong>Interatividade</strong></h3>
          <p>
            <strong>Detalhamento do plano:</strong> Ao clicar em qualquer plano (coluna da matriz), abre-se o detalhamento com a memória de cálculo completa, incluindo:
          </p>
          <ul>
            <li>As parcelas que devem ser pagas.</li>
            <li>Os valores de contribuição previstos.</li>
            <li>Outros detalhes específicos do plano.</li>
          </ul>

          <h3><strong>Como utilizar a Matriz de Planos?</strong></h3>
          <ul>
            <li><strong>Compare os planos:</strong> Use o heatmap e as informações da matriz para identificar quais cenários oferecem o melhor equilíbrio entre investimento, retorno e valor do benefício.</li>
            <li><strong>Personalize a análise:</strong> Selecione a métrica que deseja visualizar na matriz, como o retorno estimado ou a porcentagem do teto, para aprofundar sua análise.</li>
            <li><strong>Entenda os planos:</strong> Clique em um plano para acessar o detalhamento e verificar os cálculos e contribuições envolvidos.</li>
          </ul>

          <p>
            Para mais detalhes ou dúvidas, entre em contato com nosso suporte.
          </p>

        </div>

      </div>

    </div>

  </div>
</div>

<div *ngIf="!esconderControles">

  <div class="row py-2">

    <div class="col">
      <span class> Selecionar critério de comparação </span>
      <div class="dropdown">
        <button class="btn btn-outline-default dropdown-toggle btn-sm" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          {{referenciaMatrizOpcao}}
        </button>
        <ul class="dropdown-menu">
          <li>
            <button class="dropdown-item" (click)="selecaoReferenciaMatriz('roi')">Retorno estimado</button>
          </li>
          <li>
            <button class="dropdown-item" (click)="selecaoReferenciaMatriz('beneficio')">Valor do benefício</button>
          </li>
          <li>
            <button class="dropdown-item" (click)="selecaoReferenciaMatriz('taxa')">Taxa de retorno</button>
          </li>
          <li>
            <button class="dropdown-item" (click)="selecaoReferenciaMatriz('porcentagemteto')">Porcentagem do Teto</button>
          </li>
          <li>
            <button class="dropdown-item" (click)="selecaoReferenciaMatriz('investimento')">Investimento</button>
          </li>

        </ul>
      </div>

    </div>

  </div>

</div>

<div *ngIf="esconderControles">
  <div class="row">
    <div class="col-12 text-center">
      <span>
        {{referenciaMatrizOpcao}}
      </span>
    </div>
  </div>
</div>

<div class="row ">
  <div class="row py-2">
    <div class="col" role="button">
      <ejs-heatmap [dataSource]="dataSource"
        id="heatmap-container"
        [paletteSettings]="paletteSettings"
        (cellRender)='cellRender($event)'
        (tooltipRender)='tooltipRender($event)'
        (cellClick)=(cellClick($event))
        [allowSelection]='false'
        [cellSettings]="cellSettings"
        [legendSettings]="legendSettings"
        [tooltipSettings]="tooltipSettings"
        [xAxis]="xAxis"
        [yAxis]="yAxis"
        [renderingMode]="renderingMode"></ejs-heatmap>
    </div>
  </div>

  
</div>