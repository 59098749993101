

<div class="row">
  <div class="col-12">
    <h5 class="mb-0 linha-quadro">Processo</h5>

    <div class="row py-4 flex d-flex g-4">

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Nome 
        </h5>
        <input class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.nome" />
        <p class="h6">
          Parte ou nome do cliente
        </p>
      </div>

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          CPF
        </h5>
        <input [inputMask]="cpfMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.cpf" />
        <p class="h6">
          CPF Opcional
        </p>
      </div>

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Número do Processo
        </h5>
        <input [inputMask]="numeroProcessoMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.numeroProcesso"/>
        <p class="h6">
          Número do Processo opcional, apenas para efeito de apresentação no relatório
        </p>
      </div>

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Data do Cálculo
        </h5>
        <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text"[(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCalculo" />
        <p class="h6">
          Data em que o cálculo está sendo realizado. Apenas para efeito de atualização. A precedência para data de atualização de valores é para o campo DIP.
        </p>
      </div>

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Prescrição Quinquenal
        </h5>
        <div class="form-inline d-flex align-items-center">
          <div class="btn-group w-100" btnRadioGroup [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.naoAplicarPrescricao">
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Aplicar</label>
            <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não Aplicar</label>
          </div>

        </div>

        
        
        <p class="h6">
          Limitar valores atrasados aos 5 anos anteriores a Data do Ajuizamento
        </p>
      </div>

      <div class="col-3" *ngIf="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.naoAplicarPrescricao">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Data do Ajuizamento
        </h5>
        <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataAjuizamento"/>
        <p class="h6">
          Relevante apenas quando houver aplicação de prescrição quinquenal
          
        </p>
      </div>

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Data de Citação
        </h5>
        <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCitacao" />
        <p class="h6">
          Data em que a parte ré é citada no processo. Será data base para aplicação de juros quando houver
        </p>
      </div>

      <div class="col-3">
        <h5 class=" card-title text-uppercase mb-0 text-default">
          Tipo do Processo
        </h5>
        <div class="dropdown">
          <button class="btn btn-default btn-lg dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              {{calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso | tipoProcessoLiquidacao}}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><button class="dropdown-item" (click)="editarTipoProcesso(tiposProcessoType.CONCESSAO)">{{tiposProcessoType.CONCESSAO | tipoProcessoLiquidacao}}</button></li>
              <li><button class="dropdown-item" (click)="editarTipoProcesso(tiposProcessoType.REVISAO)">{{tiposProcessoType.REVISAO | tipoProcessoLiquidacao}}</button></li>
              <li><button class="dropdown-item" (click)="editarTipoProcesso(tiposProcessoType.REESTABELECIMENTO)">{{tiposProcessoType.REESTABELECIMENTO | tipoProcessoLiquidacao}}</button></li>
              
          </ul>
      </div>
        <p class="h6">
          Tipo do Processo
        </p>
      </div>

    </div>

  </div>

</div>

<!-- 

<div class="w-100 ">
  <div>

  </div>

  <div>

    <div class="row py-4">
      <div class="col-12">

        <h5 class=" card-title text-uppercase mb-0 text-default">
          Nome
        </h5>
        <input [typeahead]="clientes" typeaheadOptionField="nome" [typeaheadOptionsLimit]="6"
          [typeaheadMinLength]="2" (typeaheadOnBlur)="typeaheadOnBlur($event)"
          (typeaheadOnSelect)="onSelect($event)" formControlName="nome" placeholder="Nome do Cliente"
          class="form-control" />
      </div>
    </div>

    <div class="row py-4">
      <div class="col-12">

        <h5 class=" card-title text-uppercase mb-0 text-default">
          CPF <sup>opcional</sup>
        </h5>
        <div class="input-group">
          <input [inputMask]="cpfMask" type="text" class="form-control" [value]="cpf" placeholder="___.___.___-__"
            aria-label="CPF" aria-describedby="basic-addon2" formControlName="cpf" />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-12 col-xl-6 d-flex">
  <div class="w-100">
    <div>
      <h5 class="mb-0 linha-quadro">Processo</h5>
    </div>
    <div>

      <div class="row py-4">
        <div class="col">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Número do Processo <sup>opcional</sup>
          </h5>
          <input [inputMask]="numeroProcessoMask" type="text" class="form-control"
            placeholder="NNNNNNN-DD.AAAA.JTR.OOOO" aria-label="Nome" aria-describedby="basic-addon2"
            formControlName="numero">
        </div>
      </div>

      <div class="row py-4">
        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data do Cálculo
          </h5>

          <input [inputMask]="dateInputMask" class=" form-control"  placeholder="dd/mm/aaaa"
            type="text" formControlName="dataCalculo" />
        </div>
      </div>

      <div class="row py-4">
        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data do Ajuizamento
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control"  placeholder="dd/mm/aaaa"
            type="text" formControlName="dataAjuizamento" />
        </div>
      </div>

      <div class="row py-4">

        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Prescrição Quinquenal
          </h5>
          <div class="form-group mb-3 py-2">
            <div class="btn-group " btnRadioGroup formControlName="naoAplicarPrescricao">
              <label [btnRadio]="false" class="btn btn-outline-black mb-0">Aplicar</label>
              <label [btnRadio]="true" class="btn btn-outline-black mb-0">Não Aplicar</label>

            </div>
          </div>

        </div>
      </div>

      <div class="row py-4">
        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data da Citação do Processo
          </h5>

          <input [inputMask]="dateInputMask" class=" form-control"  placeholder="dd/mm/aaaa"
            type="text" formControlName="dataCitacao" />
        </div>
      </div>

      <div class="row py-4">
        <div class="col">

          <h5 class=" card-title text-uppercase mb-0 text-default">
            Tipo de Processo
          </h5>

          <div class="form-group">

            <ngx-select formControlName="tipoProcesso" [defaultValue]="itemTipoProcesso" [items]="itemsTipoProcesso"
              placeholder="Selecionar Tipo Processo" (select)="selectTipoProcesso($event)"
              (selectionChanges)="selectTipoProcessoChanges($event)">
            </ngx-select>
          </div>

        </div>
      </div>
    </div>
  </div>
</div> -->