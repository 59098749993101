import { Component, EventEmitter, Input, numberAttribute, OnInit, Output } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { ImpostoRenda } from 'src/app/modelos/common/impostorenda';
import { ProdutoFinanceiro } from 'src/app/modelos/common/produtofinanceiro';
import { EdicaoSalarioAliquota } from 'src/app/modelos/previdenciario/edicaosalarioaliquota';
import { OpcoesMatriz } from 'src/app/modelos/previdenciario/opcoesmatriz';
import { OpcoesNumeroContribuicoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesnumerocontribuicoesplanejamento';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';
import { PerfisContributivos } from 'src/app/modelos/previdenciario/perfiscontributivos';
import { LogService } from 'src/app/servicos/log.service';
import { ConfigService } from 'src/app/servicos/ui/config.service';

@Component({
  selector: 'app-editor-opcoes-matriz',
  templateUrl: './editor-opcoes-matriz.component.html',
  styleUrl: './editor-opcoes-matriz.component.scss'
})
export class EditorOpcoesMatrizComponent implements OnInit {

  @Input() opcoesMatriz: OpcoesMatriz;

  @Output() opcoesMatrizAtualizadasEvent = new EventEmitter<OpcoesMatriz>();

  novaAliquota: number;
  // listaAliquotas:number[];

  novoSalario: number;
  // listaSalarios:number[];

  novaRentabilidade: number;
  // listaRentabilidades:number[];

  salarioMinimo: number;
  tetoInss: number;


  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  aliquotaInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0',
    autoUnmask: true,
    unmaskAsNumber: true,
    onBeforeMask(initialValue, opts) {
      return !isNaN(Number.parseFloat(initialValue)) ? (Number.parseFloat(initialValue) * 10000).toString() : "4"
    },
    onUnMask: (masked, unmasked) => {
      // this.valorAliquotaSelecionada = Number.parseInt(unmasked); //currency(masked, {separator: '.', decimal: ','}).value;
      LogService.log('unmasked', Number.parseInt(unmasked)/10000);
      // this.novaAliquota = Number.parseInt(unmasked)/10000
      return unmasked;
    }


  });

  jurosInputMask = createMask({
    alias: 'numeric',
    groupSeparator: '.',
    radixPoint: ',',
    digits: 2,
    numericInput: true,
    digitsOptional: false,
    suffix: ' %',
    placeholder: '0',
    autoUnmask: true,
    unmaskAsNumber: true,
    onBeforeMask(initialValue, opts) {
      return !isNaN(Number.parseFloat(initialValue)) ? (Number.parseFloat(initialValue) * 10000).toString() : "4"
    },
    onUnMask: (masked, unmasked) => {
      // this.valorAliquotaSelecionada = Number.parseInt(unmasked); //currency(masked, {separator: '.', decimal: ','}).value;
      LogService.log('unmasked', Number.parseInt(unmasked)/10000);
      this.opcoesMatriz.rentabilidade = Number.parseInt(unmasked)/10000
      return unmasked;
    }


  });
  

  constructor(private configService: ConfigService) {
    this.novoSalario = 0;
    this.novaAliquota = 0;
    this.novaRentabilidade = 0;

    this.configService.getSalarioMinimo().subscribe(salarioMinimo => this.salarioMinimo = salarioMinimo);
    this.configService.getSalarioTeto().subscribe(teto => this.tetoInss = teto);

    this.currencyInputMask.autoUnmask = true;
    this.currencyInputMask.unmaskAsNumber = true;

    // this.listaAliquotas = new Array();




  }

  addPerfilContributivo(aliquota: number, base: string) {
    let edicaoSalarioAliquota: EdicaoSalarioAliquota = new EdicaoSalarioAliquota();
    
    edicaoSalarioAliquota.aliquota = aliquota;
    edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
    edicaoSalarioAliquota.salario = (base === 'sm') ? this.salarioMinimo : this.tetoInss
    edicaoSalarioAliquota.salarioTeto = this.tetoInss;
    edicaoSalarioAliquota.salarioPiso = this.salarioMinimo;

    
    this.opcoesMatriz.edicoesSalarioAliquota.push(edicaoSalarioAliquota);
    this.opcoesMatrizAtualizadas();

    LogService.log('nova opcao planejamento opa', edicaoSalarioAliquota);

  }
  excluirPerfil(edicaoSalarioAliquota:EdicaoSalarioAliquota){
    this.opcoesMatriz.edicoesSalarioAliquota.splice(this.opcoesMatriz.edicoesSalarioAliquota.indexOf(edicaoSalarioAliquota),1);
  }

  ngOnInit(): void {
    // this.montarOpcoes();
  }

  opcoesMatrizAtualizadas() {
    this.opcoesMatrizAtualizadasEvent.emit(this.opcoesMatriz);
  }

  fecharAddSalario() { }
  addSalario() {
    this.opcoesMatriz.listaSalarios.push(this.novoSalario);

    // this.montarOpcoes();
  }

  fecharAddAliquota() { }
  addAliquota() {
    this.opcoesMatriz.listaAliquotas.push(this.novaAliquota / 100);

    // this.montarOpcoes();
  }
  selecionarAliquota(aliquota: number) {
    this.opcoesMatriz.listaAliquotas.push(aliquota);

    // this.montarOpcoes();
  }


  fecharAddRentabilidade() { }

  addRentabilidade() {
    this.opcoesMatriz.listaRentabilidades.push(this.novaRentabilidade / 100);

    // this.montarOpcoes();
  }

  selecionarRentabilidade(rentabilidade: number) {
    // console.log('selecionar rentabilidade', rentabilidade);
    this.opcoesMatriz.listaRentabilidades.push(rentabilidade);

    // this.montarOpcoes();
  }
  aplicarOtimizacao(otimizacao: boolean) {
    // console.log('otimizacao selecionada', otimizacao);
    this.opcoesMatriz.otimizacaoKertzman = otimizacao;

    // this.montarOpcoes();
  }

  aplicarCustoImpostoRenda(opcao: boolean) {
    this.opcoesMatriz.aplicarCustoImpostoRenda = opcao;

    // this.montarOpcoes();

  }

  selecionarMultiploSalarioMinimo(multiplo: number) {
    this.opcoesMatriz.listaSalarios.push(this.salarioMinimo * multiplo);

    // this.montarOpcoes();
  }

  selecionarSalarioTeto() {
    this.opcoesMatriz.listaSalarios.push(this.tetoInss);

    // this.montarOpcoes();
  }

  excluirSalario(salario: number) {
    let indexSalario = this.opcoesMatriz.listaSalarios.indexOf(salario);
    this.opcoesMatriz.listaSalarios.splice(indexSalario, 1);

    // this.montarOpcoes();
  }
  excluirAliquota(aliquota: number) {
    let index = this.opcoesMatriz.listaAliquotas.indexOf(aliquota);
    this.opcoesMatriz.listaAliquotas.splice(index, 1);

    // this.montarOpcoes();
  }
  excluirRendimento(rendimento: number) {
    let index = this.opcoesMatriz.listaRentabilidades.indexOf(rendimento);
    this.opcoesMatriz.listaRentabilidades.splice(index, 1);

    // this.montarOpcoes();
  }

  montarOpcoes() {

    let opcoes: OpcoesPlanejamento[] = new Array<OpcoesPlanejamento>();


    let opcao11sm = new OpcoesPlanejamento();
    opcao11sm.edicaoSalarioAliquota = new EdicaoSalarioAliquota();
    opcao11sm.edicaoSalarioAliquota.aliquota = 0.11;
    opcao11sm.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
    opcao11sm.edicaoSalarioAliquota.salario = this.salarioMinimo;
    opcao11sm.edicaoSalarioAliquota.salarioTeto = this.tetoInss;
    opcao11sm.edicaoSalarioAliquota.salarioPiso = this.salarioMinimo;

    opcao11sm.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.BASICO;
    // opcao11sm.produtoFinanceiro = new ProdutoFinanceiro();
    // opcao11sm.produtoFinanceiro.taxaInflacao = 0.049;
    // opcao11sm.produtoFinanceiro.taxaRetorno = this.opcoesMatriz.listaRentabilidades.length > 0 ? this.opcoesMatriz.listaRentabilidades[0] : 0.13;
    // opcao11sm.produtoFinanceiro.taxaTributacao = 0.15;

    opcao11sm.impostoRenda = new ImpostoRenda();
    opcao11sm.impostoRenda.dependentes = 0;
    opcao11sm.impostoRenda.educacao = 0;
    opcao11sm.impostoRenda.investimento = this.opcoesMatriz.aplicarCustoImpostoRenda;
    opcao11sm.impostoRenda.saude = 0;

    // opcoes.push(opcao11sm);




    let opcao20sm = new OpcoesPlanejamento();
    opcao20sm.edicaoSalarioAliquota = new EdicaoSalarioAliquota();
    opcao20sm.edicaoSalarioAliquota.aliquota = 0.2;
    opcao20sm.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
    opcao20sm.edicaoSalarioAliquota.salario = this.salarioMinimo;
    opcao20sm.edicaoSalarioAliquota.salarioTeto = this.tetoInss;
    opcao20sm.edicaoSalarioAliquota.salarioPiso = this.salarioMinimo;

    opcao20sm.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.BASICO;
    // opcao20sm.produtoFinanceiro = new ProdutoFinanceiro();
    // opcao20sm.produtoFinanceiro.taxaInflacao = 0.049;
    // opcao20sm.produtoFinanceiro.taxaRetorno = this.opcoesMatriz.listaRentabilidades.length > 0 ? this.opcoesMatriz.listaRentabilidades[0] : 0.13;
    // opcao20sm.produtoFinanceiro.taxaTributacao = 0.15;

    opcao20sm.impostoRenda = new ImpostoRenda();
    opcao20sm.impostoRenda.dependentes = 0;
    opcao20sm.impostoRenda.educacao = 0;
    opcao20sm.impostoRenda.investimento = this.opcoesMatriz.aplicarCustoImpostoRenda;
    opcao20sm.impostoRenda.saude = 0;

    // opcoes.push(opcao20sm);


    let opcao20teto = new OpcoesPlanejamento();
    opcao20teto.edicaoSalarioAliquota = new EdicaoSalarioAliquota();
    opcao20teto.edicaoSalarioAliquota.aliquota = 0.2;
    opcao20teto.edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
    opcao20teto.edicaoSalarioAliquota.salario = this.tetoInss;
    opcao20teto.edicaoSalarioAliquota.salarioTeto = this.tetoInss;
    opcao20teto.edicaoSalarioAliquota.salarioPiso = this.salarioMinimo;

    opcao20teto.opcaoNumeroContribuicoes = OpcoesNumeroContribuicoesPlanejamento.BASICO;
    // opcao20teto.produtoFinanceiro = new ProdutoFinanceiro();
    // opcao20teto.produtoFinanceiro.taxaInflacao = 0.049;
    // opcao20teto.produtoFinanceiro.taxaRetorno = this.opcoesMatriz.listaRentabilidades.length > 0 ? this.opcoesMatriz.listaRentabilidades[0] : 0.13;
    // opcao20teto.produtoFinanceiro.taxaTributacao = 0.15;

    opcao20teto.impostoRenda = new ImpostoRenda();
    opcao20teto.impostoRenda.dependentes = 0;
    opcao20teto.impostoRenda.educacao = 0;
    opcao20teto.impostoRenda.investimento = this.opcoesMatriz.aplicarCustoImpostoRenda;
    opcao20teto.impostoRenda.saude = 0;

    opcoes.push(opcao20teto);

  

    this.opcoesMatriz.opcoesPlanejamento = opcoes;
    this.opcoesMatrizAtualizadas();
  }


  fecharAddValorContribuicao(){
    document.getElementById("dropAddOutroValorContribuicao").classList.remove("show");
  }
  confirmarAddValorContribuicao(){
    

    let edicaoSalarioAliquota: EdicaoSalarioAliquota = new EdicaoSalarioAliquota();
    
    edicaoSalarioAliquota.aliquota = this.novaAliquota / 10000;
    edicaoSalarioAliquota.perfilContributivo = PerfisContributivos.OUTROSVALORES;
    edicaoSalarioAliquota.salario = this.novoSalario;
    edicaoSalarioAliquota.salarioTeto = this.tetoInss;
    edicaoSalarioAliquota.salarioPiso = this.salarioMinimo;

   
    this.opcoesMatriz.edicoesSalarioAliquota.push(edicaoSalarioAliquota);
    this.opcoesMatrizAtualizadas();

    LogService.log('nova opcao planejamento opa', edicaoSalarioAliquota);

    document.getElementById("dropAddOutroValorContribuicao").classList.remove("show");

  }

}
