<div class="m-2">
    <div class="row  p-2">
        <div class="col-md-6">
            <!-- <img src={{logoUrl}} width="100px"> -->
        </div>
    
        <div class="col-md-6 text-right">
            <p class="font-weight-bold mb-1 h1">Sumário de Simulação de Renda Mensal Inicial
            </p>
            <p class="mb-1 h2">Cliente {{calculoPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}
            </p>
        </div>
    </div>
    
    <hr class="my-5">
    
    
    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-1 text-default">Sumário de Simulação de Renda Mensal Inicial</span>
        </div>
    </div>
    
    <div class="row py-4 p-2">
        <div class="col">
            <span class="display-2 text-default">Dados da Simulação</span>
        </div>
    </div>
    
    <div class="row pt-2 pb-6 p-2">
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Cliente</p>
            <p class="mb-1 h1"><span class="text-muted h1">Nome </span>
                {{calculoPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">CPF </span>
                {{calculoPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.cpf}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Sexo </span>
                {{calculoPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo}}</p>
            <p class="mb-1 h1"><span class="text-muted h1">Data Nascimento </span>
                {{calculoPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.dataNascimento | date :
                'dd/MM/yyyy'}}
            </p>
    
            <p class="mb-1 h1"><span class="text-muted h1">Idade </span>
                {{calculoPlanejamento.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.idade.porExtenso}}</p>
    
        </div>
    
        <div class="col-6">
            <p class="font-weight-bold mb-4 display-3 text-default">Parâmetros da Simulação</p>
    
            <p class="mb-1 h1"><span class="text-muted h1">Data da Simulação </span>
                {{calculoPlanejamento.data| date : 'dd/MM/yyyy'}}</p>
    
    
        </div>
    
    </div>
    
    
    <!-- <app-ranking-rmi *ngIf="beneficios" [beneficios]="beneficios"
        (rmiSelecionadaEvent)="verCasoBeneficio($event)"></app-ranking-rmi> -->
    
    <div class="row">
        <div class="col-12">
            <h5 class="mb-0 linha-quadro">Resultados</h5>
            <div *ngFor="let caso of calculoPlanejamento.casos">
    
    
                <app-caso-planejamento-preview [caso]="caso" [visualizarDetalhe]="false"
                    (casoSelecionadoEvent)="verCaso($event)"></app-caso-planejamento-preview>
    
    
    
            </div>
        </div>
    </div>
</div>