<div *ngIf="relatorio.otimizacao.totalSubstituicoesTeto > 0">
    <div class="row pb-2">
        <div class="col">
            <h5 class="mb-0 linha-quadro"></h5>
            <div class="row">
                <div class="col-6">
                    <h5 class="mb-0">Resultado do Produto Financeiro
                        <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpCapitalizacao" aria-expanded="false" aria-controls="helpCapitalizacao"></i>
                    </h5>
                </div>
            </div>

            <div class="collapse" id="helpCapitalizacao">

                <div class="row py-4 d-flex ">

                    <div class="col-3 py-2 d-flex">
                        <div class="row w-100 bg-secondary rounded m-2">
                            <div class="col d-flex p-2">

                                <p class=" p-2 lh-1">
                                    Comparativo de capitalização do investimento
                                </p>

                            </div>
                            <!-- <div class="mt-auto pb-2 text-right">
                                <i class="comando bi bi-cash-coin fs-3"></i>
                            </div> -->

                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-4">
            <div>
                <div>
                    <p class="text-center fw-lighter h6 text-uppercase">Capitalização</p>
                </div>
                <p class="display-1 lh-1  mb-0">
                    {{relatorio.otimizacao.valorSaqueCapitalizacao | currency }}
                </p>
                <p class="lh-1 fw-light">
                    Saque mensal do acumulado na capitalização durante {{relatorio.otimizacao.expectativaVida | number : '1.1-1'}} anos / {{relatorio.otimizacao.expectativaVida * 12 | number : '1.0-0'}} meses
                </p>
            </div>
            <div>
                <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.totalContributivoCapitalizadoGanhoReal | currency }}
                    </p>

                    <p class="lh-1 fw-lighter">
                        Total acumulado
                    </p>
                </div>

            </div>

        </div>
        <div class="col-4">
            <div>
                <div>
                    <p class="text-center fw-lighter h6 text-uppercase">Rendimento</p>
                </div>

                <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.taxaRetornoPrivadoAno * 100 | number : '1.2-2'}}% a. a.
                    </p>

                    <p class="lh-1 fw-lighter">
                        Rentabilidade do Produto Financeiro
                    </p>
                </div>

                <!-- <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.taxaTributacaoDescontada * 100 | number : '1.2-2'}} %
                    </p>

                    <p class="lh-1 fw-lighter">
                        Tributação
                    </p>
                </div> -->

                <!-- <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.taxaRentabilidadePrivadaLiquida * 100 | number : '1.2-2'}}% a. a.

                    </p>

                    <p class="lh-1 fw-lighter">
                        Rentabilidade Líquida
                    </p>
                </div> -->

                <!-- <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.taxaInflacaoProjetada * 100 | number : '1.2-2' }}%

                    </p>

                    <p class="lh-1 fw-lighter">
                        Desconto de Inflação Estimada
                    </p>
                </div> -->

                <!-- <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.taxaGanhoRealPrivadaAno * 100 | number : '1.2-2'}}% a. a.

                    </p>

                    <p class="lh-1 fw-lighter">
                        Ganho Real
                    </p>
                </div> -->

                <div>
                    <p class="display-4 lh-1 mb-0">
                        {{relatorio.otimizacao.taxaRentabilidadePrivadaMensalCapitalizada * 100 | number : '1.2-2'}} % a. m.

                    </p>

                    <p class="lh-1 fw-lighter">
                        Rentabilidade Mensal
                    </p>
                </div>

            </div>

        </div>
        <div class="col-4">

            <div>
                <div>
                    <p class="text-center fw-lighter h6 text-uppercase">Investimento</p>
                </div>

                <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.valorContribuicaoComplementarMensal | currency}}
                    </p>

                    <p class="lh-1 fw-lighter">
                        Valor da contribuição de otimização
                    </p>
                </div>

                <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.totalSubstituicoesTeto}}
                    </p>

                    <p class="lh-1 fw-lighter">
                        Parcelas
                    </p>
                </div>

                <div>
                    <p class="display-4 lh-1 fw-lighter mb-0">
                        {{relatorio.otimizacao.totalInvestimentoSubstituicoes | currency}}
                    </p>

                    <p class="lh-1 fw-lighter">
                        Total do Investimento
                    </p>
                </div>

            </div>

        </div>
    </div>

    <div class="row">
        <div *ngIf="relatorio.otimizacao.totalContributivoCapitalizadoGanhoReal > 0">

            <div class="row d-flex py-4 d-flex-row">

                <div class="col-6 col-xxl-6 pt-5">
                    <div class=" col-12 text-center py-2">

                        <span class=" display-4 font-weight-lighter   mb-0 text-default">
                            Progressão da Capitalização
                        </span>

                        <app-progressao-capitalizacao-chart *ngIf="linhasCapitalizacaoMensal"
                            [linhasCapitalizacaoMensal]="linhasCapitalizacaoMensal"></app-progressao-capitalizacao-chart>

                    </div>

                    <div class=" col-xl-12 col-md-12  py-2">

                        <div class="table-responsive">
                            <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                                <thead>
                                    <tr>

                                        <th scope="col">
                                            <span>
                                                Parcela
                                            </span>
                                        </th>

                                        <th scope="col">
                                            <span>
                                                Investimento Acumulado
                                            </span>
                                        </th>

                                        <th scope="col">
                                            <span>
                                                Taxa Real
                                            </span>
                                        </th>
                                        <th scope="col">
                                            <span>
                                                Rendimento no Mês
                                            </span>
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <ng-container class *ngFor="let linha of linhasCapitalizacaoMensal; index as index">

                                        <tr>

                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.mes}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.investimentoAcumulado | currency}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.taxaReal | number : '1.2-2'}} % </span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.rendimentoMes | currency}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>

                                        </tr>

                                    </ng-container>

                                </tbody>
                            </table>

                        </div>

                    </div>
                </div>

                <div class="col-6 col-xxl-6 pt-5">
                    <div class=" col-12 text-center py-2">

                        <span class=" display-4 font-weight-lighter text-center mb-0 text-default">
                            Progressão de Saques Mensais de
                            {{relatorio.otimizacao.valorSaqueCapitalizacao | currency }}
                        </span>

                        <app-duracao-saque-capitalizacao-chart *ngIf="linhasCapitalizacaoMensalSaque"
                            [linhasCapitalizacaoMensalSaque]="linhasCapitalizacaoMensalSaque"></app-duracao-saque-capitalizacao-chart>
                    </div>

                    <div class=" col-xl-12 col-md-12 py-2">
                        <div class="table-responsive">
                            <table class="table table-bordered table-sm  table-striped  table-hover align-middle overflow-auto">
                                <thead>
                                    <tr>

                                        <th scope="col">
                                            <span>
                                                Parcela
                                            </span>
                                        </th>

                                        <th scope="col">
                                            <span>
                                                Investimento Acumulado
                                            </span>
                                        </th>

                                        <th scope="col">
                                            <span>
                                                Taxa Real
                                            </span>
                                        </th>

                                        <th scope="col">
                                            <span>
                                                Rendimento
                                            </span>
                                        </th>

                                        <th scope="col">
                                            <span>
                                                Saque no Mês
                                            </span>
                                        </th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <ng-container class *ngFor="let linha of linhasCapitalizacaoMensalSaque; index as index">

                                        <tr>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.mes}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.investimentoAcumulado | currency}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.taxaReal * 100 | number : '1.2-2'}}%</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.rendimentoMes | currency}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>
                                            <!-- competencia  -->
                                            <td>
                                                <div>
                                                    <span>{{linha.saque | currency}}</span>
                                                </div>

                                                <!-- {{contribuicao.competencia | date : 'MM/yyyy'}} -->
                                            </td>

                                        </tr>

                                    </ng-container>

                                </tbody>
                            </table>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>
