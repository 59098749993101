<div class="row">
    <div class="col">
        <h5 class="mb-0 linha-quadro">Dados do Segurado</h5>
    </div>
</div>

<div class="row d-flex py-2">
    <div class="col">
        <span class="display-2  d-block lh-1 fw-lighter">
            {{ anonimo ? (cnis.identificacaoFiliado.nome | anonimo) : cnis.identificacaoFiliado.nome}} 
        </span>
    </div>
    
</div>
<div class="row ">

    
    <div class="col-3 py-2">
        <span class="d-block">
            Data de Nascimento
        </span>
        <span class="d-block fw-bold lh-1">
            {{cnis.identificacaoFiliado.dataNascimento | date : 'dd/MM/yyyy'}}
        </span>

    </div>
    <div class="col-3 py-2">
        <span class="d-block">
            Idade
        </span>
        <span class="d-block fw-bold lh-1">
            {{cnis.identificacaoFiliado.idade.porExtenso}}
        </span>
    </div>

    <div class="col-3 py-2">
        <span class="d-block">
            Data do Extrato Previdenciário
        </span>
        <span class="d-block fw-bold lh-1">
            {{cnis.identificacaoFiliado.dataExtratoPrevidenciario | date : 'dd/MM/yyyy'}}
        </span>
    </div>

    <div class="col-3 py-2">
        <span class="d-block">
            Sexo
        </span>
        <span class="d-block fw-bold lh-1">
            {{cnis.identificacaoFiliado.sexo}}
        </span>
        

    </div>

    <div class="col-3 py-2">

        <span class="d-block">
            Data da Análise
        </span>
        <span class="d-block fw-bold lh-1">
            {{dataAnalise | date : 'dd/MM/yyyy'}}
        </span>

    </div>

    <div class="col-3 py-2">
        <span class="d-block">
            CPF
        </span>
        <div [className]="!anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                {{cnis.identificacaoFiliado.cpf}}
            </span>
        </div>
        <div [className]="anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                000.000.000-00
            </span>
        </div>
    </div>
    <div class="col-3 py-2">
        <span class="d-block">
            NIT
        </span>

        <div [className]="!anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                {{cnis.identificacaoFiliado.nit}}
            </span>
        </div>
        <div [className]="anonimo ? 'visible' : 'invisible'">
            <span class="d-block fw-bold lh-1">
                000.00000.00-0
            </span>
        </div>

    </div>

</div>