<div *ngIf="calculoLiquidacaoSentencaPrevidenciario && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca && calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio" class="row">

  <div class="row">
    <div class="col-12">
      <h5 class="mb-0 linha-quadro">Benefício</h5>

      <div class="row py-4 flex d-flex g-4">

        <div class="col-3" *ngIf="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REESTABELECIMENTO">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data de cessação do benefício
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control text-left" type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dataCessacao" />
          <p class="h6">
            Data de cessação do benefício
          </p>
        </div>

        <div class="col-3" *ngIf="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REVISAO || calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REESTABELECIMENTO">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Número do benefício
          </h5>
          <input [inputMask]="numeroBeneficioMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.numeroBeneficio" />
          <p class="h6">
            Número do benefício
          </p>
        </div>


        <div class="col-12">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Tipo do benefício
          </h5>
          <div class="dropdown">
            <button class="btn btn-default btn-lg dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              {{calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.tipoBeneficio | tipoBeneficioLiquidacao}}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li *ngFor="let tipo of itemsTipoBeneficio">
                <button class="dropdown-item" (click)="editarTipoBeneficio(tipo.value)">{{tipo.text}}</button>
              </li>
            </ul>
          </div>
          <p class="h6">
            Tipo do Processo
          </p>
        </div> 

        
        <div class="col-3" *ngIf="!(calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REESTABELECIMENTO)">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            DIB
          </h5>
          <input  [inputMask]="dateInputMaskDib" class=" form-control text-left" type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dib" />
          <p class="h6">
            Data de início do benefício
          </p>
        </div>


        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            DIP
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dip" />
          <p class="h6">
            Data de Início dos Pagamentos - DIP (fim dos atrasados)
          </p>
        </div>


        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            RMI
          </h5>
          <input [inputMask]="currencyInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.rmi" />
          <p class="h6">
            Renda mensal inicial
          </p>
          <app-cnis-geral-modal (cnisSelecionadoEvent)="cnisGeralModalSelecaoConcluida($event)" ></app-cnis-geral-modal>
        </div>

        
        <!-- 
          
        

        

        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Prescrição Quinquenal
          </h5>
          <div class="form-inline d-flex align-items-center">
            <div class="btn-group w-100" btnRadioGroup [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.naoAplicarPrescricao">
              <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Aplicar</label>
              <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não Aplicar</label>
            </div>

          </div>

          <p class="h6">
            Limitar valores atrasados aos 5 anos anteriores a Data do Ajuizamento
          </p>
        </div>

        <div class="col-3" *ngIf="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.naoAplicarPrescricao">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data do Ajuizamento
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataAjuizamento" />
          <p class="h6">
            Relevante apenas quando houver aplicação de prescrição quinquenal

          </p>
        </div>

        <div class="col-3">
          <h5 class=" card-title text-uppercase mb-0 text-default">
            Data de Citação
          </h5>
          <input [inputMask]="dateInputMask" class=" form-control text-left" placeholder type="text" [(ngModel)]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCitacao" />
          <p class="h6">
            Data em que a parte ré é citada no processo. Será data base para aplicação de juros quando houver
          </p>
        </div>

        
      -->

      </div>

    </div>

  </div>

</div>

<!-- 
<form [formGroup]="beneficioForm">
  <h5 class="mb-0 linha-quadro">Benefício</h5>
  <div class="row ">
    <div class="col-12 col-xl-6 d-flex">
      <div class="w-100 ">

        <div *ngIf="calculoLiquidacaoSentencaPrevidenciario">

          <div
            [className]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso=== tipoProcessoLiquidacaoSentencaPrevidenciarioType.REESTABELECIMENTO ? 'visible py-2' : 'invisible'">
            <div class="col">

              <h5 class=" card-title text-uppercase mb-0 text-default">
                Data da Cessação do Benefício
              </h5>
              <input [inputMask]="dateInputMask" class=" form-control"
                placeholder="dd/mm/aaaa"
                type="text" formControlName="dataCessacao" />
            </div>
          </div>

          <div
            [className]="calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REVISAO || calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REESTABELECIMENTO ? 'visible py-4'  : 'invisible'">
            <div class="col">

              <h5 class=" card-title text-uppercase mb-0 text-default">
                Número do Benefício
              </h5>
              <input [inputMask]="numeroBeneficioMask" class=" form-control"
                placeholder="__________"
                type="text" formControlName="numeroBeneficio" />
            </div>
          </div>

          <div class="row py-4">
            <div class="col">
              <h5 class=" card-title text-uppercase mb-0 text-default">
                Tipo do Benefício
              </h5>

              <ngx-select formControlName="tipoBeneficio"
                [defaultValue]="itemTipoBeneficio"
                [items]="itemsTipoBeneficio"
                placeholder="Selecionar Tipo do Benefício"
                (select)="selectTipoBeneficio($event)"
                (selectionChanges)="selectTipoBeneficioChanges($event)">
              </ngx-select>
            </div>
          </div>

          <div
            [className]="!(calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso === tipoProcessoLiquidacaoSentencaPrevidenciarioType.REESTABELECIMENTO) ? 'visible row py-4' : 'invisible'">
            <div class="col">
              <h5 class=" card-title text-uppercase mb-0 text-default">
                Data de Início do Benefício - DIB
              </h5>
              <input [inputMask]="dateInputMask" class=" form-control"
                
                placeholder="dd/mm/aaaa" type="text" formControlName="dib" />
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-12 col-xl-6 d-flex">
      <div class="w-100">

        <div>

          <div class="row py-4">
            <div class="col">

              <h5 class=" card-title text-uppercase mb-0 text-default">
                Data de Início dos Pagamentos - DIP (fim dos atrasados)
              </h5>
              <input [inputMask]="dateInputMask" class=" form-control"
                
                placeholder="dd/mm/aaaa" type="text" formControlName="dip" />
            </div>
          </div>

          <div [className]="habilitarRmi ? 'visible' : 'invisible'">
            <div class="row py-4">
              <div class="col-lg-12">
                <h5 class=" card-title text-uppercase mb-0 text-default">
                  Renda Mensal Inicial - RMI
                </h5>
                <input [inputMask]="currencyInputMask"
                  class=" form-control text-left"
                   placeholder="R$ 0,00" type="text"
                  formControlName="rmi" />
              </div>
            </div>
          </div>






          <div [className]="!habilitarRmi ? 'visible' : 'invisible'">
            <app-cnis-geral-modal (cnisSelecionadoEvent)="cnisGeralModalSelecaoConcluida($event)" ></app-cnis-geral-modal>
          </div>
          

          <div class="row py-4">
            <div class="col">

              <h5 class=" card-title text-uppercase mb-0 text-default">
                Data de Início do Acréscimo de 25% <sup>opcional</sup>
              </h5>
              <input [inputMask]="dateInputMask" class=" form-control"
                
                placeholder="dd/mm/aaaa" type="text" formControlName="dib25" />
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</form> -->