

<div *ngIf="calculoLiquidacaoSentenca && calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca" class="row py-2">
    <div class="col-12">
      <h5 class="mb-0 linha-quadro">Parcelas</h5>
  
      <table class="my-4 table table-striped table-hover  align-middle" >
        <thead class="" style="display: table-header-group; page-break-inside: avoid !important;">
          <tr class="text-center ">
            <th scope="col">Competência</th>
            <th scope="col">Nominal<br> (X)</th>
            <th scope="col">Abatimentos<br> (Y)</th>
            <th scope="col">Valor<br> (A = X-Y)</th>
            <th scope="col">Coef. Corr. Monetária<br> (B)</th>
            <th scope="col">Principal Corrigido<br> (C = A*B)</th>
            <th scope="col">Juros %<br> (D)</th>
            <th scope="col">Juros Aplicado R$<br> (E = C*D)</th>
            <th scope="col">Selic %<br> (F)</th>
            <th scope="col">Selic Aplicada R$<br> (G = (C+E)*F)</th>
            <th scope="col">Total<br> (H = C+E+G)</th>
            <th scope="col">Obs.</th>
            
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let parcela of calculoLiquidacaoSentenca.relatorioLiquidacaoSentenca.parcelas">
            <th scope="row">{{parcela.competencia | date : 'MM/yy'}}</th>
            <td>{{parcela.valorOriginal | currency}}</td>
            <td>{{parcela.abatimento | currency}}</td>
            <td>{{parcela.valorDescontadoAbatimento | currency}}</td>
            <td>{{parcela.coeficienteCorrecaoMonetaria | number : '1.0-5'}}</td>
            <td>{{parcela.principalCorrigido | currency}}</td>
            <td>{{parcela.juros | number : '1.2-2'}} %</td>
            <td>{{parcela.jurosPrincipal | currency }}</td>
            <td>{{parcela.selicPercentual | number : '1.2-2'}} %</td>
            <td>{{parcela.selicCorrecao | currency}}</td>
            <td>{{parcela.valorAtualizado | currency}}</td>
            <td><ng-container *ngIf="parcela.decimoTerceiro"> 13&#186;</ng-container></td>
            
            <!-- <td>{{parcela.osbservacoes | currency}}</td> -->

          </tr>
          
        </tbody>
      </table>
      
  
    </div>
  </div>