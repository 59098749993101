<app-cabecalho [titulo]="'Novo Cálculo'"></app-cabecalho>
<div class=" container-fluid mt--3">

  <div class=" row">
    <div class=" col-12">
      <div class="  ">

        <div class="pb-4">
          <div class="card">
            <div class="row">
              <div class="col">

                <div class="card-body vh-100">

                  <div class="pb-4">
                    <div class="row">
                      <div class="col-12">
                        <p class="display-1 lh-1 fw-lighter">Cálculos</p>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <p class="fw-light">
                          Escolha o modelo de cálculo para começar
                        </p>
                        <!-- <p class="lh-1 fw-light">bem vindo(a) ao Calculei, sua plataforma de Investimento Previdenciário</p> -->
                      </div>

                    </div>
                  </div>

                  

                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/previdenciario/simulacao']">
                      <div class="row">
                        <div class="col-auto"  >
                          <i class="comando bi bi-palette2 fs-2"></i>
    
                        </div>
                        <div class="col-auto"  >
    
                          <p class="h1">
                            Simulação
                          </p>
                          <p class="p-0 m-0">
                            Projeção de novas contribuições e tempo para completar todas as regras de transição
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>


                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/previdenciario/kertzman']">
                      <div class="row">
                        <div class="col-auto" role="button" >
                          <i class="comando fs-2">IK</i>
    
                        </div>
                        <div class="col-auto" role="button" >
    
                          <p class="h1">
                            Método Kertzman
                          </p>
                          <p class="p-0 m-0">
                            Otimização da regra Aposentadoria por Idade
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>


                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/previdenciario/mapaqualidadesegurado']">
                      <div class="row">
                        <div class="col-auto" role="button" >
                          <i class="comando bi bi-calendar2-range fs-2"></i>
    
                        </div>
                        <div class="col-auto" role="button" >
    
                          <p class="h1">
                            Qualidade de Segurado
                          </p>
                          <p class="p-0 m-0">
                            Períodos em que o segurado esteve dentro da cobertura da qualidade de segurado
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>


                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/previdenciario/incapacidadetemporaria']">
                      <div class="row">
                        <div class="col-auto" role="button" >
                          <i class="comando bi bi-bandaid fs-2"></i>
    
                        </div>
                        <div class="col-auto" role="button" >
    
                          <p class="h1">
                            Auxílio por Incapacidade Temporária / Acidente de Trabalho
                          </p>
                          <p class="p-0 m-0">
                            Cálculo do valor do auxílio
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>


                  <div class="row py-4">
                    <div class="col-auto opcao py-2" role="button" [routerLink]="['/calculei/calculos/previdenciario/invalidez']">
                      <div class="row">
                        <div class="col-auto" role="button" >
                          <i class="comando bi bi-file-medical fs-2"></i>
    
                        </div>
                        <div class="col-auto" role="button" >
    
                          <p class="h1">
                            Aposentadoria por Invalidez
                          </p>
                          <p class="p-0 m-0">
                            Cálculo da aposentadoria por invalidez ordinária ou por acidade de trabalho
                          </p>
                          <!-- <p class="h6 p-0 m-0">
                            Montagem da matriz de Planejamento previdenciário do calculei. É a ferramenta ideal para voocê reduzir o tempo de listagem e análise de cenários de aposentadoria.
                          </p> -->
    
                        </div>
                      </div>

                    </div>
                    
                  </div>



                  

                  
                </div>

                

              </div>

            </div>
          </div>

        </div>

      </div>
    </div>