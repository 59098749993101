<h5 class="mb-0 linha-quadro">Administrador</h5>

<div class="row">
    <div class="col">
        <p>
            Opções de Administrador
        </p>
    </div>

</div>

<div class="row">
    <div class="col -auto">
        <div class="hstack">
            <div class="vstack">
                <div>
                    <p>Admin Dashboard</p>
                </div>
                <div class>
                    <button class="btn btn-outline-default" [routerLink]="'/admin'">Admin</button>
                </div>
            </div>

            <div class="vstack">
                <div>
                    <p>Página fim período teste</p>
                </div>
                <div class>
                    <button class="btn btn-outline-default" [routerLink]="'/calculei/usuario/teste'">Página Período Teste</button>
                </div>
            </div>

            <div class="vstack">
                <div>
                    <p>Teste api</p>
                </div>
                <div class>
                    <button class="btn btn-outline-default" (click)="testeApi()">Teste API</button>
                </div>
            </div>

            <div class="vstack">
                <div>
                    <p>Sair</p>
                </div>
                <div class>
                    <button class="btn btn-outline-default" (click)="logout()">Sair</button>
                </div>
            </div>
        </div>
    </div>

</div>

<app-usuarios></app-usuarios>