import { Base } from "../base";
import { Atividade } from "./atividade";
import { CamposMatriz } from "./camposmatriz";
import { EdicaoImpostoRenda } from "./edicaoimpostorenda";
import { EdicaoSalarioAliquota } from "./edicaosalarioaliquota";
import { FiltrosMatriz } from "./filtrosmatriz";
import { OpcoesNumeroContribuicoesPlanejamento } from "./opcoesnumerocontribuicoesplanejamento";
import { OpcoesPlanejamento } from "./opcoesplanejamento";
import { Periodicidade } from "./periodicidade";

export class OpcoesMatriz extends Base{
    opcoesPlanejamento:OpcoesPlanejamento[];
    frequencias:OpcoesNumeroContribuicoesPlanejamento[];
    atividades:Atividade[];
    filtros:FiltrosMatriz;
    campos:CamposMatriz;
    otimizacaoKertzman:boolean;
    aplicarCustoImpostoRenda:boolean;
    exibirMarcadores:boolean;
    
    listaRentabilidades:number[];
    listaSalarios:number[];
    listaAliquotas:number[];

    intervalos:Periodicidade[];
    edicaoImpostoRenda:EdicaoImpostoRenda;
    edicoesSalarioAliquota:EdicaoSalarioAliquota[];
    rentabilidade:number;
    
}