
<div *ngIf="ficha">
    <div class="row">
        <div class="col-12">
            <h5 class="mb-0 linha-quadro pb-4">Perguntas</h5>
    
        </div>
    </div>
    
    <div class="row py-2">
        <div class="col-12">
            <app-nova-pergunta *ngIf="ficha" (addPerguntaEvent)="addPergunta($event)" ></app-nova-pergunta>
        </div>
    </div>

    <app-listagem-perguntas *ngIf="ficha" [ficha]="ficha"></app-listagem-perguntas>
</div>

<!-- <div class="row py-2">
    <div class="col-12">

        <div class="dropdown">
            <i class="comando bi bi-plus-square-dotted fs-6" role="button" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div class="container-xl">
                    <div class="row ">
                        <div class="col">
                            <p class="h2">Nova Pergunta</p>
                        </div>
                    </div>
                    <div class="row justify-content-end">
                        <div class="col-auto">
                            <i class="comando bi bi-arrow-right fs-6" role="button"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
    </div>
</div> -->