import { CalculoIncapacidadeTemporaria } from './../../../../modelos/previdenciario/calculoincapacidadetemporaria';

import { DatePipe, Location } from '@angular/common';
import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterContentChecked, AfterViewInit, OnChanges, SimpleChanges, AfterContentInit, AfterViewChecked } from '@angular/core';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { Calculo } from 'src/app/modelos/common/calculo';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { RequestRelatorioRendaMensalInicial } from 'src/app/modelos/previdenciario/requestrelatoriorendamensalinicial';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { GruposSumarioCalculoBeneficiosComponent } from '../compartilhado/grupos-sumario-calculo-beneficios/grupos-sumario-calculo-beneficios.component';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { BarraNavegacaoComponent } from 'src/app/aplicacao/compartilhado/barra-navegacao/barra-navegacao.component';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarRelatorioRendaMensalInicialPdfService } from 'src/app/servicos/relatorio/listar-relatorio-renda-mensal-inicial-pdf.service';
import { EditorCNISComponent } from '../compartilhado/editor-cnis/editor-cnis.component';
import { ImportadorCNISComponent } from '../compartilhado/importador-cnis/importador-cnis.component';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';

import { CalculoAnaliseCnis } from 'src/app/modelos/previdenciario/calculoanalisecnis';
import { ResultadoAnaliseCnisComponent } from '../compartilhado/resultado-analise-cnis/resultado-analise-cnis.component';
import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import { Global } from 'src/app/modelos/global';
import { ResultadoIncapacidadeTemporariaComponent } from '../compartilhado/resultado-incapacidade-temporaria/resultado-incapacidade-temporaria.component';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-incapacidade-temporaria',
  templateUrl: './incapacidade-temporaria.component.html',
  styleUrls: ['./incapacidade-temporaria.component.scss']
})
export class IncapacidadeTemporariaComponent implements OnInit, AfterViewInit, OnChanges, AfterViewChecked {
  @ViewChild('seletorCnis', { static: false }) seletorCnis?: CnisListagemComponent;
  @ViewChild('tabsCalculoBeneficios', { static: false }) tabs?: TabsetComponent;
  @ViewChild('importadorCNIS') importadorCNIS: ImportadorCNISComponent;
  @ViewChild('editorCNIS') editorCNIS: EditorCNISComponent;

  // resultadoAnaliseCnis


  @ViewChild('resultadoAnaliseCnis') resultadoAnaliseCnis: ResultadoAnaliseCnisComponent;
  @ViewChild('resultadoIncapacidadeTemporaria') resultadoIncapacidadeTemporaria: ResultadoIncapacidadeTemporariaComponent;




  calculoIncapacidadeTemporaria: CalculoIncapacidadeTemporaria;;

  calculoArquivo: boolean;


  private stepper: Stepper;
  aguardeConfig: boolean;
  aguardeResultado: boolean;
  atualizarCalculoCnisModificado: boolean;


  constructor(private location: Location, private listarCalculoResultadoService: ListarCalculoResultadoService,
    private datePipe: DatePipe, private changeDetector: ChangeDetectorRef,
    private router: Router,
    private configService: ConfigService,
    private calculeiApiService:CalculeiApiService,
    private notificacoesService: NotificacoesService,
    private listarPDfService: ListarPDFService,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService) {




  }


  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

    if (this.location.getState().hasOwnProperty('calculo')) {
      this.calculoIncapacidadeTemporaria = this.location.getState()['calculo'] as CalculoIncapacidadeTemporaria;
      this.stepper.to(3)
      
    } else {
      this.calculoIncapacidadeTemporaria = new CalculoIncapacidadeTemporaria();
      this.calculoIncapacidadeTemporaria.tipoCalculo = CalculoTipos.INCAPACIDADETEMPORARIA;
      this.calculoIncapacidadeTemporaria.calculoBeneficios = new CalculoBeneficios();
      this.calculoIncapacidadeTemporaria.calculoBeneficios.dataCalculo = new Date();
      this.calculoIncapacidadeTemporaria.nomeCalculo = "Novo cálculo auxílio Incap. Temp."
      this.calculoIncapacidadeTemporaria.data = new Date();

    }
  }


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:
          if (this.calculoIncapacidadeTemporaria.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
              // title: 'CNIS',
              text: 'Selecione CNIS antes de continuar',
              iconHtml: '<i class="bi bi-filetype-pdf"></i>',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: false,
              // confirmButtonText: 'Ok',
              // confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 2:
          if (this.calculoIncapacidadeTemporaria.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo resultado');
            this.calcularResultado();
          } else {
            event.preventDefault();
            Swal.fire({
              // title: 'CNIS',
              text: 'Selecione CNIS antes de continuar',
              iconHtml: '<i class="bi bi-filetype-pdf"></i>',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: false,
              // confirmButtonText: 'Ok',
              // confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:
          this.editorCNIS.salvarModificacoesCnis();
          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });

    this.configService.getAguarde().subscribe(status => this.aguardeConfig = status);

    if (!environment.production) {
      // this.mock();
    };
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculoincapacidadetemporaria.json", request => {
      this.carregarDados(request as CalculoIncapacidadeTemporaria);

    });
  }

  carregarDados(calculoIncapacidadeTemporaria: CalculoIncapacidadeTemporaria) {


    this.calculoIncapacidadeTemporaria = calculoIncapacidadeTemporaria;

  }

  leituraCNISEvent(leituraCNIS: LeituraCNIS) {


    this.calculoIncapacidadeTemporaria.calculoBeneficios.leituraCnis = leituraCNIS;
    this.proximo(true);
    this.cnisAtualizado(this.calculoIncapacidadeTemporaria.calculoBeneficios);


  }


  //editor cnis

  compararEdicaoCNIS(cnisAnterior: CNIS, cnisAtual: CNIS, dataAnterior: Date, dataAtual: Date): boolean {
    let resultado: boolean;
    resultado = (JSON.stringify(cnisAnterior) === JSON.stringify(cnisAtual)) && dataAnterior.toDateString() === dataAtual.toDateString();

    return resultado;
  }

  edicaoCNISFinalizada(calculoBeneficios: CalculoBeneficios) {


    this.calculoIncapacidadeTemporaria.calculoBeneficios = calculoBeneficios;


    this.changeDetector.detectChanges();
    // this.resultadoAnaliseCnis.carregarDados(this.calculoAnaliseCnis);
    this.resultadoIncapacidadeTemporaria.listarResultado(this.calculoIncapacidadeTemporaria);
  }

  sumarioFinalizado(pronto: boolean) {

  }


  incluirMarcadorCnis(incluir: boolean) {


    this.editorCNIS.incluirMarcador();


  }

  excluirCnis() {

    this.editorCNIS.excluirCnis();
  }

  excluirCnisFinalizado(exclui: boolean) {
    // console.log('cnis excluido - beneficios component');
    this.editorCNIS.excluirFinalizado();
    // this.seletorCnis.carregarDados();

  }
  

  atualizarListaCnis(atualizar: boolean) {
    // this.seletorCnis.carregarDados();
  }


  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }
  pdf(event) {
    this.configService.setAguarde(true);
    this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Estamos preparando seu relatório. Ele será baixado na sua pasta de downloads automaticamente assim que ficar pronto!');

    this.calculoIncapacidadeTemporaria.tipoCalculo = CalculoTipos.INCAPACIDADETEMPORARIA;
    this.calculoIncapacidadeTemporaria.usuario = Global.usuario;

    this.calculeiApiService.apiBlobLx("ListarPDF",this.calculoIncapacidadeTemporaria)
      .subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = 'AUXILIO-INCAPACIDADE-TEMPORARIA-'+ this.calculoIncapacidadeTemporaria.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome + '.pdf';
        a.click();
        URL.revokeObjectURL(objectUrl);

        this.notificacoesService.showNotification('default', 'Exportar relatório em PDF', 'Pronto. Verifique seu relatórios na pasta de downloads do seu navegador!');
        this.configService.setAguarde(false);

      });
   
  }

  salvarEdicaoCnis(event) {
    this.editorCNIS.salvarModificacoesCnis();
  }

  calcularResultado() {

    // if (!this.atualizarCalculoCnisModificado) return;

    this.configService.getUsuario().subscribe(usuario=>this.calculoIncapacidadeTemporaria.usuario = usuario);
    this.configService.setAguarde(true);
    this.calculeiApiService.api("ListarAuxilioIncapacidadeTemporaria", this.calculoIncapacidadeTemporaria)
    .subscribe(resultado=>{
      // console.log('resultado calculo incapacidade', resultado);
      this.calculoIncapacidadeTemporaria = resultado as CalculoIncapacidadeTemporaria;
      this.configService.setAguarde(false);
      this.atualizarCalculoCnisModificado = false;
    });
    
    
    // let prepCalculoApi: CalculoBeneficios = JSON.parse(JSON.stringify(this.calculoBeneficios));
    // prepCalculoApi.beneficios = new Array();
    // this.listarGruposSumarioCalculoBeneficiosService.listarGruposSumarioCalculoBeneficios(prepCalculoApi)
    //   .then(calculo => {

    //     // console.log('calculo pronto beneficios');
    //     // console.log(calculo);

    //     this.calculoBeneficios = calculo as CalculoBeneficios;
    //     this.configService.setAguarde(false);
    //     this.aguardeResultado = false;
    //     this.atualizarCalculoCnisModificado = false;


    //   });

  }

  cnisAtualizado(calculoBeneficios: CalculoBeneficios) {
    // console.log('atualizando cnis beneficios');
    this.cnisModificado(true);
    if (this.editorCNIS) {
      this.editorCNIS.carregarDados();
    }
  }
  cnisModificado(event) {
    // console.log('cnis Modificado set true atualizarCalculo');
    this.atualizarCalculoCnisModificado = true;

  }


}

