
<div class="row py-4">
    <div class="col">
        <h5 class="mb-0 linha-quadro">
            Contribuições não computadas
            <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpChecklist" aria-expanded="false" aria-controls="helpChecklist">

            </i>
            <div class="collapse" id="helpChecklist">
                <p class="lh-1 py-2">
                    O checklist de tempo de contribuição, são fases de validação das contribuições e sequências para determinar quais vão ser utilizadas para contagem de tempo de contribuição.
                </p>
                <p class="py-2 lh-1">
                    Cada fase do checklist de contagem de tempo de contribuição, verifica se as contribuições e sequências devem ser contadas para tempo de contribuição. Cada fase do checklist segue um critério de validação.
                    Quando as sequências e contribuições não passarem na fase, elas serão marcadas como invalidas e não farão parte da contagem final de tempo de contribuição que será levada em consideração nos próximos passos do cálculo.
                </p>
                <p class="lh-1 py-2">
                    Na fase de contagem apenas as contribuições / competências validadas são consideradas. Para considerar a contribuição a pesar da validação, selecione a opção ignorar.
                </p>

            </div>
        </h5>
    </div>
</div>

<div class="row py-2  flex d-flex justify-content-between">

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
        </div>

    </div>

    <div class="col-auto flex d-flex">
        <div class="col-auto p-0 m-0">
            <!-- <i class="comando bi bi-toggle-off fs-6 " role="button" container="body" ngbTooltip="Ignorar validação de todas as contribuições invalidadas" triggers="hover"></i> -->
        </div>

    </div>

</div>

<div class="row py-2">
    <div class="col-12">

        <div class="table-responsive">
            <table class="table table-bordered table-sm  table-striped  table-hover align-middle">
                <thead>

                    <tr>
                        <th scope="col" class=" align-middle text-center sequencia">
                            <span>
                                Seq.
                            </span>
                        </th>

                        <th scope="col" class="  align-middle text-center" width="10%">
                            <span>
                                Competência
                            </span>
                        </th>

                        <th scope="col" class="  align-middle text-center validade">
                            <span>
                                Tempo de Contribuição para Aposentadorias por Idade
                            </span>
                        </th>
                        <th scope="col" class="  align-middle text-center validade">
                            <span>
                                Tempo de Contribuição para Aposentadorias por Tempo de Contribuição
                            </span>
                        </th>
                        <th scope="col" class="  align-middle text-center validade">
                            <span>
                                Carência para Aposentadorias por Idade
                            </span>
                        </th>
                        <th scope="col" class="  align-middle text-center validade">
                            <span>
                                Carência para Aposentadorias por Tempo de Contribuição
                            </span>
                        </th>

                        <th scope="col" class="  align-middle text-center">
                            <span>
                                Critérios
                            </span>
                        </th>

                        <ng-container *ngIf="!esconderOpcoes">
                            <th scope="col" class="  align-middle text-center" width="10%">
                                <span>
                                    Opções
                                </span>
                            </th>
                        </ng-container>

                    </tr>

                </thead>
                <tbody>
                    <ng-container class *ngFor="let sequencia of calculoBeneficios.leituraCnis.cnis.relacoesPrevidenciarias; index as indexSequencia;">
                        <ng-container class *ngFor="let contribuicao of sequencia.contribuicoes; index as indexContribuicao;">

                            <ng-container *ngIf="!contribuicao.validaContagemTempoContribuicaoAposentadoriaIdade || !contribuicao.validaContagemTempoContribuicaoAposentadoriaTempo || !contribuicao.validaContagemCarenciaAposentadoriaIdade || !contribuicao.validaContagemCarenciaAposentadoriaTempo ">

                                <ng-container *ngIf="contribuicao.observacoes && contribuicao.observacoes.length > 0">

                                    <tr>
                                        <td class="align-middle text-center">
                                            {{sequencia.sequencia}}
                                        </td>

                                        <td class=" align-middle text-center">
                                            {{contribuicao.competencia | date : "MM/yyyy"}}
                                        </td>

                                        <td class=" align-middle text-center">
                                            <ng-container *ngIf="!sequencia.validaContagemTempoContribuicaoAposentadoriaIdade">
                                                <i class="bi bi-calendar2-x text-red"></i>
                                            </ng-container>
                                            <ng-container *ngIf="sequencia.validaContagemTempoContribuicaoAposentadoriaIdade">
                                                <i class="bi bi-calendar2-check text-green"></i>
                                            </ng-container>
                                            
                                        </td>

                                        <td class=" align-middle text-center">

                                            <ng-container *ngIf="!sequencia.validaContagemTempoContribuicaoAposentadoriaTempo">
                                                <i class="bi bi-calendar2-x text-red"></i>
                                            </ng-container>
                                            <ng-container *ngIf="sequencia.validaContagemTempoContribuicaoAposentadoriaTempo">
                                                <i class="bi bi-calendar2-check text-green"></i>
                                            </ng-container>
                                        </td>

                                        <td class=" align-middle text-center">
                                            

                                            <ng-container *ngIf="!sequencia.validaContagemCarenciaAposentadoriaIdade">
                                                <i class="bi bi-calendar2-x text-red"></i>
                                            </ng-container>
                                            <ng-container *ngIf="sequencia.validaContagemCarenciaAposentadoriaIdade">
                                                <i class="bi bi-calendar2-check text-green"></i>
                                            </ng-container>
                                        </td>

                                        <td class=" align-middle text-center">

                                            <ng-container *ngIf="!sequencia.validaContagemCarenciaAposentadoriaTempo">
                                                <i class="bi bi-calendar2-x text-red"></i>
                                            </ng-container>
                                            <ng-container *ngIf="sequencia.validaContagemCarenciaAposentadoriaTempo">
                                                <i class="bi bi-calendar2-check text-green"></i>
                                            </ng-container>
                                        </td>

                                        <td class=" align-middle text-justify">

                                            <ng-container class *ngFor="let observacao of contribuicao.observacoes; index as indexObservacao;">
                                                <div class="row">
                                                    <span>{{observacao.analise}}</span>
                                                </div>

                                            </ng-container>

                                        </td>

                                        <ng-container *ngIf="!esconderOpcoes">

                                            <td class="align-middle text-center">
                                                <a class="text-default " role="button" [attr.aria-expanded]="contribuicao.libero" data-bs-toggle="collapse" role="button" [href]="'#contribuicaoLibero'+ contribuicao.rowKey">
                                                    <a class [id]="'contribuicaoLibero'+contribuicao.rowKey"></a>
                                                    <i class=" bi bi-toggle-on mx-1" (click)="ativarSequencia(contribuicao,true)" container="body" ngbTooltip="Desativar validação" triggers="hover"></i>
                                                    <i class=" bi bi-toggle-off mx-1" (click)="ativarSequencia(contribuicao,false)" container="body" ngbTooltip="Ativar validação" triggers="hover"></i>
    
                                                </a>
    
                                            </td>

                                        </ng-container>
                                        

                                    </tr>

                                </ng-container>

                            </ng-container>

                        </ng-container>

                    </ng-container>

                </tbody>
            </table>

        </div>

    </div>
</div>