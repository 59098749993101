import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Marcador } from 'src/app/modelos/common/marcador';
import { TiposMarcador } from 'src/app/modelos/common/tiposmarcador';
import { CasoPlanejamentoPrevidenciario } from 'src/app/modelos/previdenciario/casoplanejamentoprevidenciario';
import { ListaRegras } from 'src/app/modelos/previdenciario/listaregras';
import { MatrizPlanejamento } from 'src/app/modelos/previdenciario/matrizplanejamento';

@Component({
  selector: 'app-tabela-matriz',
  templateUrl: './tabela-matriz.component.html',
  styleUrl: './tabela-matriz.component.scss'
})
export class TabelaMatrizComponent {

  @Input() calculoMatrizPlanejamento: MatrizPlanejamento;
  @Output() atualizacaoMatrizEvent = new EventEmitter<MatrizPlanejamento>();
  @Output() abrirPlanoEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();
  @Output() escolherPlanoIndicadoEvent = new EventEmitter<CasoPlanejamentoPrevidenciario>();
  tiposMarcadorType = TiposMarcador;


  filtrosForm = this.fb.group({
    filtroRegraIdadeTransicao: [true],
    filtroRegraPedagio50: [true],
    filtroRegraPedagio100: [true],
    filtroRegraIdadeMinimaProgressiva: [true],
    filtroRegraPontos: [true],
    filtroAliquota11: [true],
    filtroAliquota20: [true],
    filtroSalarioMinimo: [true],
    filtroSalarioTeto: [true],
  });

  camposForm = this.fb.group({
    campoAliquotaSalario: [true],
    campoDataAposentadoria: [true],
    campoRegra: [true],
    campoValorBeneficio: [true],
    campoTotalContribuicoes: [true],
    campoValorContribuicao: [true],
    campoTotalInvestimento: [true],
    campoRoiAcumulado: [true],
    campoTaxaRetorno: [true],
    campoPayback: [true],
    campoGanhoRoi: [true],
    campoPercentualTeto: [true],

  });



  constructor(private fb: FormBuilder,) { 
    this.camposForm.get('campoAliquotaSalario').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.aliquotaSalario = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoDataAposentadoria').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoRegra').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.regra = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoValorBeneficio').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.valorBeneficio = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoTotalContribuicoes').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.totalContribuicoes = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoValorContribuicao').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.valorContribuicao = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoTotalInvestimento').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoRoiAcumulado').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.roiAcumulado = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoTaxaRetorno').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.taxaRetorno = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoPayback').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.payback = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoGanhoRoi').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.ganhoRoi = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
    this.camposForm.get('campoPercentualTeto').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.campos.percentualTeto = change;
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });




    this.filtrosForm.get('filtroAliquota11').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.aliquota11 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.aliquotaContributiva == 0.11) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });

    this.filtrosForm.get('filtroAliquota20').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.aliquota20 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.aliquotaContributiva == 0.20) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });



    this.filtrosForm.get('filtroRegraPontos').valueChanges.subscribe(change => {
      // console.log('regra pontos valuechanges');
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraPontos = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA1TEMPOCONTRIBUICAOPONTOS]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });

    this.filtrosForm.get('filtroRegraIdadeMinimaProgressiva').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraIdadeMinimaProgressiva = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA2TEMPOCONTRIBUICAOIDADEPROGRESSIVA]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });


    this.filtrosForm.get('filtroRegraPedagio50').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraPedagio50 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA3TEMPOCONTRIBUICAOPEDAGIO50]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });


    this.filtrosForm.get('filtroRegraPedagio100').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraPedagio100 = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA4TEMPOCONTRIBUICAOPEDAGIO100]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });


    this.filtrosForm.get('filtroRegraIdadeTransicao').valueChanges.subscribe(change => {
      this.calculoMatrizPlanejamento.opcoesMatriz.filtros.regraIdadeTransicao = change;
      this.calculoMatrizPlanejamento.casos.map(caso => {
        if (caso.regra.toString() === ListaRegras[ListaRegras.REGRA5IDADETRANSICAO]) {
          caso.filtrarMatriz = !change;
        }
      });
      this.atualizarMatriz(this.calculoMatrizPlanejamento);
    });
  }

  addTinta(caso: CasoPlanejamentoPrevidenciario, marcador: Marcador, tipo: string, idCelula: string) {
    this.addMarcador(caso, marcador, tipo);
    let celula = <HTMLInputElement>document.getElementById(idCelula);
    celula.style.setProperty('background-color', marcador.cor);
  }

  addMarcador(caso: CasoPlanejamentoPrevidenciario, marcador: Marcador, tipo: string) {
    switch (tipo) {
      case "ALIQUOTA":
        if (!caso.aliquotaContributivaMarcadores) caso.aliquotaContributivaMarcadores = new Array<Marcador>();
        caso.aliquotaContributivaMarcadores.push(marcador);
        break;
      case "CASO":
        if (!caso.marcadores) caso.marcadores = new Array<Marcador>();
        caso.marcadores.push(marcador);
        break;

      case "DATAAPOSENTADORIA":
        if (!caso.dataAposentadoriaMarcadores) caso.dataAposentadoriaMarcadores = new Array<Marcador>();
        caso.dataAposentadoriaMarcadores.push(marcador);
        break;
      case "QUALIFICACAOREFERENCIA":
        if (!caso.qualificacaoReferenciaMarcadores) caso.qualificacaoReferenciaMarcadores = new Array<Marcador>();
        caso.qualificacaoReferenciaMarcadores.push(marcador);
        break;
      case "VALORBENEFICIO":
        if (!caso.valorBeneficioMarcadores) caso.valorBeneficioMarcadores = new Array<Marcador>();
        caso.valorBeneficioMarcadores.push(marcador);
        break;
      case "TOTALCONTRIBUTIVOCOMPLEMENTARMESES":
        if (!caso.totalContributivoComplementarMesesMarcadores) caso.totalContributivoComplementarMesesMarcadores = new Array<Marcador>();
        caso.totalContributivoComplementarMesesMarcadores.push(marcador);
        break;
      case "VALORCONTRIBUICAOCOMPLEMENTARMENSAL":
        if (!caso.valorContribuicaoComplementarMensalMarcadores) caso.valorContribuicaoComplementarMensalMarcadores = new Array<Marcador>();
        caso.valorContribuicaoComplementarMensalMarcadores.push(marcador);
        break;
      case "TOTALINVESTIMENTO":
        if (!caso.totalInvestimentoMarcadores) caso.totalInvestimentoMarcadores = new Array<Marcador>();
        caso.totalInvestimentoMarcadores.push(marcador);
        break;
      case "RETORNOTOTALINVESTIMENTOROI":
        if (!caso.retornoTotalInvestimentoRoiMarcadores) caso.retornoTotalInvestimentoRoiMarcadores = new Array<Marcador>();
        caso.retornoTotalInvestimentoRoiMarcadores.push(marcador);
        break;
      case "TAXARETORNOPLANEJAMENTOCONTRIBUTIVO":
        if (!caso.taxaRetornoPlanejamentoContributivoMarcadores) caso.taxaRetornoPlanejamentoContributivoMarcadores = new Array<Marcador>();
        caso.taxaRetornoPlanejamentoContributivoMarcadores.push(marcador);
        break;
      case "TEMPORETORNOINVESTIMENTOMESESABONO":
        if (!caso.tempoRetornoInvestimentoMesesAbonoMarcadores) caso.tempoRetornoInvestimentoMesesAbonoMarcadores = new Array<Marcador>();
        caso.tempoRetornoInvestimentoMesesAbonoMarcadores.push(marcador);
        break;
      case "LUCROPLANEJAMENTOCONTRIBUTIVO":
        if (!caso.lucroPlanejamentoContributivoMarcadores) caso.lucroPlanejamentoContributivoMarcadores = new Array<Marcador>();
        caso.lucroPlanejamentoContributivoMarcadores.push(marcador);
        break;
      case "PERCENTUALTETOINSS":
        if (!caso.percentualTetoInssMarcadores) caso.percentualTetoInssMarcadores = new Array<Marcador>();
        caso.percentualTetoInssMarcadores.push(marcador);
        break;
      case "ACUMULADOCAPITALIZACAO":
        if (!caso.acumuladoCapitalizacaoMarcadores) caso.acumuladoCapitalizacaoMarcadores = new Array<Marcador>();
        caso.acumuladoCapitalizacaoMarcadores.push(marcador);
        break;




      default:
        break;
    }
    // console.log('add marcador matriz', this.calculoMatrizPlanejamento);
    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);

  }

  excluirMarcador(caso: CasoPlanejamentoPrevidenciario, marcador: Marcador, tipo: string) {

    switch (tipo) {
      case "ALIQUOTA":
        caso.aliquotaContributivaMarcadores.splice(caso.aliquotaContributivaMarcadores.indexOf(marcador), 1);
        break;
      case "CASO":
        caso.marcadores.splice(caso.marcadores.indexOf(marcador), 1);
        break;

      case "DATAAPOSENTADORIA":
        caso.dataAposentadoriaMarcadores.splice(caso.dataAposentadoriaMarcadores.indexOf(marcador), 1);
        break;

      case "QUALIFICACAOREFERENCIA":
        caso.qualificacaoReferenciaMarcadores.splice(caso.qualificacaoReferenciaMarcadores.indexOf(marcador), 1);
        break;

      case "VALORBENEFICIO":
        caso.valorBeneficioMarcadores.splice(caso.valorBeneficioMarcadores.indexOf(marcador), 1);
        break;
      case "TOTALCONTRIBUTIVOCOMPLEMENTARMESES":
        caso.totalContributivoComplementarMesesMarcadores.splice(caso.totalContributivoComplementarMesesMarcadores.indexOf(marcador), 1);
        break;
      case "VALORCONTRIBUICAOCOMPLEMENTARMENSAL":
        caso.valorContribuicaoComplementarMensalMarcadores.splice(caso.valorContribuicaoComplementarMensalMarcadores.indexOf(marcador), 1);
        break;
      case "TOTALINVESTIMENTO":
        caso.totalInvestimentoMarcadores.splice(caso.totalInvestimentoMarcadores.indexOf(marcador), 1);
        break;
      case "RETORNOTOTALINVESTIMENTOROI":
        caso.retornoTotalInvestimentoRoiMarcadores.splice(caso.retornoTotalInvestimentoRoiMarcadores.indexOf(marcador), 1);
        break;
      case "TAXARETORNOPLANEJAMENTOCONTRIBUTIVO":
        caso.taxaRetornoPlanejamentoContributivoMarcadores.splice(caso.taxaRetornoPlanejamentoContributivoMarcadores.indexOf(marcador), 1);
        break;
      case "TEMPORETORNOINVESTIMENTOMESESABONO":
        caso.tempoRetornoInvestimentoMesesAbonoMarcadores.splice(caso.tempoRetornoInvestimentoMesesAbonoMarcadores.indexOf(marcador), 1);
        break;
      case "LUCROPLANEJAMENTOCONTRIBUTIVO":
        caso.lucroPlanejamentoContributivoMarcadores.splice(caso.lucroPlanejamentoContributivoMarcadores.indexOf(marcador), 1);
        break;
      case "PERCENTUALTETOINSS":
        caso.percentualTetoInssMarcadores.splice(caso.percentualTetoInssMarcadores.indexOf(marcador), 1);
        break;
      case "ACUMULADOCAPITALIZACAO":
        caso.acumuladoCapitalizacaoMarcadores.splice(caso.acumuladoCapitalizacaoMarcadores.indexOf(marcador), 1);
        break;

      default:
        break;
    }

    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);
  }

  edicaoTitulo(event, caso: CasoPlanejamentoPrevidenciario) {
    // console.log(event.target.value);
  }
  editarTituloCenario(caso: CasoPlanejamentoPrevidenciario, idEditor) {
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    caso.referenciaMatriz = editor.value;

    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);
  }
  fecharDrop(idEditor) {

    // console.log('fechar', idEditor);
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');

  }
  focoEditorTitulo() { }
  esconderCenario(caso: CasoPlanejamentoPrevidenciario, idEditor) {

    caso.filtrarMatriz = true;
    let editor = <HTMLInputElement>document.getElementById(idEditor);
    editor.classList.remove('show');
    this.atualizacaoMatrizEvent.emit(this.calculoMatrizPlanejamento);

  }


  ordenarAlpha(reverse: boolean) {
    this.calculoMatrizPlanejamento.casos = this.calculoMatrizPlanejamento.casos.sort((a, b) => {
      let textA = a.referenciaMatriz.toLowerCase();
      let textB = b.referenciaMatriz.toLowerCase();
      return reverse ? (textA > textB) ? -1 : (textA < textB) ? 1 : 0 : (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  ordernarValor(reverse: boolean) {
    this.calculoMatrizPlanejamento.casos = this.calculoMatrizPlanejamento.casos.sort((a, b) => reverse ? a.valorBeneficio - b.valorBeneficio : b.valorBeneficio - a.valorBeneficio)
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  marcadoresExibir(toogle: boolean) {
    this.calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores = !this.calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores;
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  atualizarMatriz(matriz: MatrizPlanejamento) {
    this.atualizacaoMatrizEvent.emit(matriz);
  }

  marcarTodosFiltros() {
    Object.keys(this.filtrosForm.controls).forEach((key) => {
      const control = this.filtrosForm.get(key);
      if (control) {
        control.setValue(true);
        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }

  limparTodosFiltros() {
    Object.keys(this.filtrosForm.controls).forEach((key) => {
      const control = this.filtrosForm.get(key);
      if (control) {
        control.setValue(false);

        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
  }
  marcarTodosCampos() {
    Object.keys(this.camposForm.controls).forEach((key) => {
      const control = this.camposForm.get(key);
      if (control) {
        control.setValue(true);
        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
    this.atualizarMatriz(this.calculoMatrizPlanejamento);
  }
  limparTodosCampos() {
    Object.keys(this.camposForm.controls).forEach((key) => {
      const control = this.camposForm.get(key);
      if (control) {
        control.setValue(false);
        //or if we wanted to change the value
        //control.setValue(value);
      }
    });
  }


  abrirPlano(caso:CasoPlanejamentoPrevidenciario){
    this.abrirPlanoEvent.emit(caso);

  }

  escolherPlanoIndicado(caso:CasoPlanejamentoPrevidenciario, idDrop:string){
    this.escolherPlanoIndicadoEvent.emit(caso)
    this.fecharDrop(idDrop);
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    
  }


}
