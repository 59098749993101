<div class="row ">
  <div class="col">
    <div class="card">
      
      <div class="card-body d-flex flex-row-reverse">
        <button class="btn btn-outline-black m-2" (click)="baixarPDF()">Exportar relatório em PDF</button>

      </div>
    </div>
  </div>
</div>


<div class="row">

  <div class=" col-xl-12">
    <div class=" card">
      <div class=" card-header border-dark">
        <h5 class="mb-0">Segurado</h5>
      </div>

      <div class=" card-body">

        <div class=" row py-2">
          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              Nome
            </h5>
            <span class=" h2 font-weight-bold mb-0 ">{{calculoRevisaoTempoServico.cliente.nome}}</span>
          </div>
          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              NIT
            </h5>
            <!-- <span class=" h2 font-weight-bold mb-0 ">{{calculoRevisaoTempoServico.cliente.nit}}</span> -->
          </div>




          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              Data de Nascimento
            </h5>
            <span
              class=" h2 font-weight-bold mb-0 ">{{calculoRevisaoTempoServico.extratoPrisma.dataNascimento| date : 'dd/MM/yyyy'}}</span>
          </div>



        </div>


        <div class=" row py-2">


          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              Número do Benefício
            </h5>
            <span class=" h2 font-weight-bold mb-0 ">{{calculoRevisaoTempoServico.extratoPrisma.numeroBeneficio}}</span>
          </div>


          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              DER
            </h5>
            <span
              class=" h2 font-weight-bold mb-0 ">{{calculoRevisaoTempoServico.extratoPrisma.der| date : 'dd/MM/yyyy'}}</span>
          </div>

          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              DIB
            </h5>
            <span
              class=" h2 font-weight-bold mb-0 ">{{calculoRevisaoTempoServico.extratoPrisma.dib| date : 'dd/MM/yyyy'}}</span>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>









<div class="row">

  <div class=" col-xl-12">
    <div class=" card">
      <div class=" card-header border-dark">
        <h5 class="mb-0">Análise</h5>
      </div>

      <div class=" card-body">

        <div *ngIf="pendenciasExtrato != undefined" class=" row py-2">
          <div class=" col-xl-4 col-md-4  py-2">
            <h5 class="text-uppercase text-muted mb-0">
              Pendências
            </h5>
            <span class=" h2 font-weight-bold mb-0 ">Total de Pendências: {{pendenciasExtrato.length}}</span>

            <div class="dataTables_wrapper py-4">
              <ngx-datatable class="material striped selection-cell" [columnMode]="'force'" [headerHeight]="50"
                [footerHeight]="'auto'" [rowHeight]="'auto'" [rows]="rowsPendencias"
                [messages]="{emptyMessage: 'Nenhuma pendência encontrada', totalMessage: 'pendências no total' }">

                <ngx-datatable-column name="Data Inicial" prop="dataInicio" [sortable]="false" [draggable]="false">
                  <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span>{{value | date : 'dd/MM/yyyy'}}</span>
                  </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Data Final" prop="dataFim" [sortable]="false" [draggable]="false">
                  <ng-template let-value="value" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                    <span>{{value | date : 'dd/MM/yyyy'}}</span>
                  </ng-template>
                </ngx-datatable-column>

              </ngx-datatable>
            </div>
          </div>



        </div>

      </div>
    </div>
  </div>
</div>











<div class="row ">
  <div class="col">
    <div class="card">
      
      <div class="card-body d-flex flex-row-reverse">
        <button class="btn btn-outline-black m-2" (click)="baixarPDF()">Exportar relatório em PDF</button>

      </div>
    </div>
  </div>
</div>
