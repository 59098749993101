<div class="row ">
    <div class="col">
      <h5 class="mb-0 linha-quadro">Formulário CNIS
        <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroCnisFormulario" aria-expanded="false" aria-controls="helpQuadroCnisFormulario"></i>
      </h5>
  
      <div class="collapse" id="helpQuadroCnisFormulario">
  
        <div class="row py-4 d-flex ">
  
          <div class="col-3 py-2 d-flex">
            <div class="row bg-secondary rounded m-2">
              <div class="col d-flex p-2">
  
                <p class="p-2 text-justify">
                  Quando não tiver o arquivo de CNIS, você pode preêncher manualmente as sequências
                </p>
  
              </div>
              <div class="mt-auto pb-2 text-right">
                <i class="comando bi bi-input-cursor-text fs-3"></i>
              </div>
  
            </div>
  
          </div>
  
        </div>
  
      </div>
    </div>
  
  </div>
<div class="py-2">
    <button type="button" (click)="gerarCnis()" class="btn btn-outline-black m-2">
        Preencher sequências de vínculos manualmente
    </button>
    
</div>
<app-montagem-leitura-cnis #montagemLeituraCnis (gerarLeituraCnisMontagemFinalizada)="gerarLeituraCnisMontagemFinalizada($event)" ></app-montagem-leitura-cnis>




