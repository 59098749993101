import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { Global } from 'src/app/modelos/global';
import { Cliente } from 'src/app/modelos/usuario/cliente';
import { ListarClientesRequest } from 'src/app/modelos/usuario/listarclientesrequest';
import { ListarClienteService } from 'src/app/servicos/usuario/listar-cliente.service';

import { createMask } from '@ngneat/input-mask';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { validate } from 'json-schema';
import { PreliminaresLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/preliminaresliquidacaosentencaprevidenciario';
import { ReferenciaLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/referencialiquidacaosentencaprevidenciario';
import { LiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/liquidacaosentencaprevidenciario';
import { BeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/beneficioliquidacaosentencaprevidenciario';
import { ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-correcao-monetaria-previdenciario.service';
import { ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService } from 'src/app/servicos/calculo/previdenciario/listar-opcoes-protocolos-aplicacao-juros-previdenciario.service';
import { TiposProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tiposprocessoliquidacaosentencaprevidenciario';
import { TipoBeneficioLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/tipobeneficioliquidacaosentencaprevidenciario';
import { TabDirective, TabsetComponent } from 'ngx-bootstrap/tabs';
import { CalculoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/calculoliquidacaosentencaprevidenciario';

import { ProcessoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/processoliquidacaosentencaprevidenciario';
import { HonorariosLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/honorariosliquidacaosentencaprevidenciario';
import { RelatorioLiquidacaoSentencaoPrevidenciario } from 'src/app/modelos/previdenciario/relatorioliquidacaosentencaprevidenciario';
import { DatePipe, Location } from '@angular/common';
import { Calculo } from 'src/app/modelos/common/calculo';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { RelatorioLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/relatorio-liquidacao-sentenca-previdenciario/relatorio-liquidacao-sentenca-previdenciario.component';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { BarraNavegacaoComponent } from 'src/app/aplicacao/compartilhado/barra-navegacao/barra-navegacao.component';
import { ReferenciaLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/referencia-liquidacao-sentenca-previdenciario/referencia-liquidacao-sentenca-previdenciario.component';
import { BeneficioLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/beneficio-liquidacao-sentenca-previdenciario/beneficio-liquidacao-sentenca-previdenciario.component';
import { AbatimentoLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/abatimentos-liquidacao-sentenca-previdenciario/abatimentos-liquidacao-sentenca-previdenciario.component';
import { ProcessoLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/processo-liquidacao-sentenca-previdenciario/processo-liquidacao-sentenca-previdenciario.component';
import { HonorariosLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/honorarios-liquidacao-sentenca-previdenciario/honorarios-liquidacao-sentenca-previdenciario.component';
import { ResumoLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/resumo-liquidacao-sentenca-previdenciario/resumo-liquidacao-sentenca-previdenciario.component';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { AtualizacaoLiquidacaoSentencaPrevidenciarioComponent } from '../compartilhado/atualizacao-liquidacao-sentenca-previdenciario/atualizacao-liquidacao-sentenca-previdenciario.component';
import { AbatimentoLiquidacaoSentencaPrevidenciario } from 'src/app/modelos/previdenciario/abatimentosliquidacaosentencaprevidenciario';
import Stepper from 'bs-stepper';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import Swal from 'sweetalert2';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { TipoBeneficio } from 'src/app/modelos/previdenciario/tipobeneficio';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { ProtocolosAplicacaoJurosPrevidenciario } from 'src/app/modelos/previdenciario/protocolosaplicacaojurosprevidenciario';
import { ProtocolosCorrecaoMonetariaPrevidenciario } from 'src/app/modelos/previdenciario/protocoloscorrecaomonetariaprevidenciario';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';

@Component({
  selector: 'app-liquidacao-sentenca',
  templateUrl: './liquidacao-sentenca.component.html',
  styleUrls: ['./liquidacao-sentenca.component.scss']
})
export class LiquidacaoSentencaComponent implements OnInit, AfterViewInit, AfterViewChecked {




  @ViewChild('relatorioComponent') relatorioComponent: RelatorioLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('referencia') referencia: ReferenciaLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('beneficio') beneficio: BeneficioLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('abatimentos') abatimentos: AbatimentoLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('atualizacao') atualizacao: AtualizacaoLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('honorarios') honorarios: HonorariosLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('resumo') resumo: ResumoLiquidacaoSentencaPrevidenciarioComponent;
  @ViewChild('relatorio') relatorio: RelatorioLiquidacaoSentencaPrevidenciarioComponent;




  calculoLiquidacaoSentencaPrevidenciario: CalculoLiquidacaoSentencaPrevidenciario;
  atualizarCalculoOpcoesModificadas: boolean;

  // liquidacaoSentenca:LiquidacaoSentencaPrevidenciario;

  liquidacaoSentencaReferencia: LiquidacaoSentencaPrevidenciario;
  liquidacaoSentencaBeneficio: LiquidacaoSentencaPrevidenciario;
  liquidacaoSentencaAbatimentos: LiquidacaoSentencaPrevidenciario;
  liquidacaoSentencaProcesso: LiquidacaoSentencaPrevidenciario;
  liquidacaoSentencaHonorarios: LiquidacaoSentencaPrevidenciario;

  liquidacaoSentencaResumo: LiquidacaoSentencaPrevidenciario;
  liquidacaoSentencaRelatorio: LiquidacaoSentencaPrevidenciario;


  titulo: string = 'Novo Cálculo de Liquidação de Sentença';
  dateInputMask = createMask<Date>({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const day = +values[0];
      return new Date(year, month, day);
    },
  });

  private stepper: Stepper;
  aguardeConfig: boolean;


  constructor(private listarClienteService: ListarClienteService, private listarOpcoesProtocolosCorrecaoMonetariaPrevidenciario: ListarOpcoesProtocolosCorrecaoMonetariaPrevidenciarioService,
    private listarOpcoesProtocolosAplicacaoJurosPrevidenciario: ListarOpcoesProtocolosAplicacaoJurosPrevidenciarioService,
    private listarCalculoResultadoService: ListarCalculoResultadoService,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private configService: ConfigService,
    private calculeiApiService: CalculeiApiService,
    private changeDetector: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    private datePipe: DatePipe) {



  }


  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.from) {
        case 0:

          break;

        default:
          break;
      }

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:
          if (true) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
              title: 'CNIS',
              text: 'Escolha um CNIS antes de continuar',
              icon: 'info',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonText: 'Ok',
              confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 2:

          break;
        case 5:
          // console.log('proximo resultado');
          if (this.atualizarCalculoOpcoesModificadas) {
            this.calcularResultado();
            
          }
          


          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:
          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });

    this.configService.getAguarde().subscribe(status => this.aguardeConfig = status);

    if (!environment.production) {
      // this.mock();
    };


  }

  ngAfterViewInit() {



    if (this.location.getState().hasOwnProperty('calculo')) {
      // console.log('resultado');




      this.atualizarCalculoOpcoesModificadas = false;
      this.calculoLiquidacaoSentencaPrevidenciario = this.location.getState()['calculo'] as CalculoLiquidacaoSentencaPrevidenciario;

      this.stepper.to(6);
        
    } else {

      this.calculoLiquidacaoSentencaPrevidenciario = new CalculoLiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.usuario = Global.usuario;
      this.calculoLiquidacaoSentencaPrevidenciario.nomeCalculo = "Novo cálculo liquidação de sentença";
      this.calculoLiquidacaoSentencaPrevidenciario.data = new Date();
      this.calculoLiquidacaoSentencaPrevidenciario.tipoCalculo = CalculoTipos.LIQUIDACAOSENTENCAPREVIDENCIARIO;

      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca = new LiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia = new ReferenciaLiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.naoAplicarPrescricao = false;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.tipoProcesso = TiposProcessoLiquidacaoSentencaPrevidenciario.CONCESSAO;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.dataCalculo = new Date();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.cpf = "00000000000";
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.referencia.numeroProcesso = "9999999-99.9999.9.99.9999";

      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio = new BeneficioLiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.calculoBeneficios = new CalculoBeneficios();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.dip = new Date();
      this.configService.getSalarioMinimo().subscribe(sm => this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.rmi = sm);
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.beneficio.tipoBeneficio = TipoBeneficioLiquidacaoSentencaPrevidenciario.APOSENTADORIAIDADE;


      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos = new AbatimentoLiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.abatimentos = new Array();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.evolucoes = new Array();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.abatimentos.percentualValorOriginalOpcao = 0;



      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.decimoTerceiroSemAntecipacao = false;

      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo = new ProcessoLiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloAplicacaoJuros = ProtocolosAplicacaoJurosPrevidenciario.MANUALCALCULOJUSTICAFEDERAL;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.protocoloCorrecaoMonetaria = ProtocolosCorrecaoMonetariaPrevidenciario.MANUALCALCULOJUSTICAFEDERAL;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasAplicacaoJuros = new Array();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.processo.regrasCorrecaoMonetaria = new Array();


      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios = new HonorariosLiquidacaoSentencaPrevidenciario();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.dataLimite = new Date();
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.incideHonorariosSucumbencia = false;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.incideHonorariosContratuais = false;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.valorPercentualContratual = 0;
      this.calculoLiquidacaoSentencaPrevidenciario.liquidacaoSentenca.honorarios.valorPercentualSucumbencia = 0;
      this.atualizarCalculoOpcoesModificadas = true;






      this.stepper.to(1);

    }


  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges()
  }


  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculoliquidacaosentencaprevidenciario.json", request => {
      this.calculoLiquidacaoSentencaPrevidenciario = request as CalculoLiquidacaoSentencaPrevidenciario;
      // console.log("liquidacao mock", request);

      this.stepper.to(6);

    });

  }



  pdf(event) {
    // console.log('baixar pdf liquidacao');
    this.relatorio.baixarPDF(0);
  }





  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }

  calcularResultado() {

    this.configService.getUsuario().subscribe(usuario=>this.calculoLiquidacaoSentencaPrevidenciario.usuario = usuario)
    this.configService.setAguarde(true);
    this.calculeiApiService.api("ListarRelatorioLiquidacaoSentencaPrevidenciario", this.calculoLiquidacaoSentencaPrevidenciario)
      .subscribe(resultado => {
        // console.log("resultado calculo liquidacao sentença", resultado);
        this.calculoLiquidacaoSentencaPrevidenciario = resultado as CalculoLiquidacaoSentencaPrevidenciario;
        this.configService.setAguarde(false);
      })

  }

}
