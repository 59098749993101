<div *ngIf="opcoesMatriz && opcoesMatriz.listaAliquotas">

    <div class="pb-4">
        <div class="row ">
            <div class="col">
                <h5 class="mb-0 linha-quadro">Valor da Contribuição
                    <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroValorContribuicao" aria-expanded="false" aria-controls="helpQuadroValorContribuicao"></i>
                </h5>

                <div class="collapse" id="helpQuadroValorContribuicao">

                    <div class="row py-4 d-flex ">

                        <div class="col-3 py-2 d-flex">
                            <div class="row bg-secondary rounded m-2">
                                <div class="col d-flex p-2">

                                    <p class=" p-2 lh-1">
                                        Escolha quais salários e alíquotas serão consideradas no cálculo
                                    </p>

                                </div>
                                <div class="mt-auto pb-2 text-right">
                                    <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-auto">
                <div class="dropdown">

                    <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                        <div class="row">
                            <div class="col-auto text-center">
                                <i class="comando bi bi-plus-square-dotted fs-6"></i>

                            </div>
                        </div>

                    </div>

                    <div #dropdownMenu id="dropAddOutroValorContribuicao" class="dropdown-menu">
                        <div class="container">
                            <div class>
                                <div class="row">
                                    <div class="col">
                                        <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.11, 'sm')">11% do Salário Mínimo</a></li>
                                        <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.11, 'teto')">11% do Teto (Sócio do Simples Nacional)</a></li>
                                        <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.2, 'sm')">20% do Salário Mínimo</a></li>
                                        <li><a class="dropdown-item" role="button" (click)="addPerfilContributivo(0.2, 'teto')">20% do Teto</a></li>
                                    </div>
                                </div>
                            </div>
                            <div class>
                                <div class="row text-center">
                                    <div class="col">
                                        <p>Outros valores</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 pb-2 d-flex flex-column justify-content-between  ">
                                        <h5 class=" card-title text-uppercase mb-0 text-default">
                                            Salário de contribuição
                                        </h5>
                                        <input [inputMask]="currencyInputMask" [(ngModel)]="novoSalario" placeholder="R$ 0,00" class=" form-control text-left" #inputTitulo type="text" />
                                    </div>
                                    <!-- <div class="col-12">
    
                                        
                                        <input [inputMask]="currencyInputMask" [(ngModel)]="novoSalario" placeholder="R$ 0,00" class=" form-control text-left" #inputTitulo type="text" />
                                        <p class="h6">
                                            
                                        </p>
                                    </div> -->
                                </div>
                                <div class="row">
                                    <div class="col-12 pb-2 d-flex flex-column justify-content-between ">
                                        <h5 class=" card-title text-uppercase mb-0 text-default">
                                            Alíquota
                                        </h5>
                                        <input [inputMask]="aliquotaInputMask" [(ngModel)]="novaAliquota" placeholder="0,00" class=" form-control text-left" #inputTitulo type="text" />
                                    </div>

                                    <!-- <div class="col-12 ">
                                        <input [inputMask]="jurosInputMask" [(ngModel)]="novaAliquota" placeholder="0,00" class=" form-control text-left" #inputTitulo type="text" />
                                        <p class="h6">
                                            Alíquota
                                        </p>
                                    </div> -->
                                </div>

                                <div class="col-12 text-right">
                                    <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddValorContribuicao()"></i>
                                    <i class="comando bi bi-arrow-right fs-4" role="button" (click)="confirmarAddValorContribuicao()"></i>
                                </div>
                            </div>
                        </div>

                        <div class="container">

                        </div>

                    </div>
                </div>

            </div>

            <div class="col">
                <div *ngIf="!(opcoesMatriz && opcoesMatriz.edicoesSalarioAliquota && opcoesMatriz.edicoesSalarioAliquota.length > 0)" class="row py-2">
                    <p>
                        Está vazio por aqui <i class="bi bi-moon-stars"></i>
                    </p>
                </div>

                <div class="row">
                    <ng-container *ngIf="opcoesMatriz && opcoesMatriz.edicoesSalarioAliquota">
                        <ng-container *ngFor="let perfil of opcoesMatriz.edicoesSalarioAliquota">
                            <div class="col-4 item">
                                <div class="row p-2">
                                    <div class="col-12 bg-secondary rounded py-4">
                                        <div class="row">
                                            <div class="col-auto">
                                                <p class="lh-1 fw-light">
                                                    {{perfil.aliquota * 100 | number : '1.0-2'}}%
                                                    <ng-container *ngIf="perfil.salario == salarioMinimo" class="h2">
                                                        sobre o Salário Mínimo {{perfil.salario | currency}}
                                                    </ng-container>
                                                    <ng-container *ngIf="perfil.salario == tetoInss" class="h2">
                                                        sobre o Teto do INSS {{perfil.salario | currency}}
                                                    </ng-container>

                                                    <ng-container *ngIf="perfil.salario != salarioMinimo && perfil.salario != tetoInss" class="h2">
                                                        sobre {{perfil.salario | currency}}
                                                    </ng-container>
                                                </p>

                                            </div>
                                        </div>
                                        <div class="row justify-content-end">
                                            <div class="col-auto excluir-item">
                                                <i class="bi bi-trash" role="button" (click)="excluirPerfil(perfil)"></i>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                </div>

            </div>
        </div>
    </div>

    <div class="pb-4">
        <app-frequencia-contributiva *ngIf="opcoesMatriz && opcoesMatriz.frequencias" [frequencias]="opcoesMatriz.frequencias"></app-frequencia-contributiva>
    </div>

    <!-- <div class="pb-4">
        <app-intervalo-contribuicoes-matriz *ngIf="opcoesMatriz && opcoesMatriz.intervalos" [intervalos]="opcoesMatriz.intervalos"></app-intervalo-contribuicoes-matriz>

    </div> -->

    <div class="pb-4">
        <app-add-atividade-matriz *ngIf="opcoesMatriz && opcoesMatriz.atividades" [atividades]="opcoesMatriz.atividades"></app-add-atividade-matriz>
    </div>

    <div class="pb-4">

        <div class="row ">
            <div class="col">
                <h5 class="mb-0 linha-quadro">Produto Financeiro Comparativo
                    <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroProdutoFinanceiro" aria-expanded="false" aria-controls="helpQuadroProdutoFinanceiro"></i>
                </h5>

                <div class="collapse" id="helpQuadroProdutoFinanceiro">

                    <div class="row py-4 d-flex ">

                        <div class="col">
                            <p>
                                O <strong>Produto Financeiro Comparativo</strong> permite simular o resultado que o valor das suas contribuições teria se fosse investido em um produto financeiro com a rentabilidade informada, em vez de ser destinado ao aumento do benefício previdenciário.
                            </p>

                            <h3><strong>Como funciona?</strong></h3>
                            <ul>
                                <li>
                                    <strong>Juros/Rentabilidade Real:</strong> Informe a taxa de rentabilidade anual real esperada (já descontada a inflação) para o produto financeiro que deseja comparar.
                                </li>
                                <li>
                                    O sistema utiliza esta taxa para calcular quanto suas contribuições acumuladas renderiam ao longo do tempo, considerando o período e os valores indicados no plano de aposentadoria selecionado.
                                </li>
                                <li>
                                    O resultado é exibido como uma projeção comparativa entre o valor acumulado no produto financeiro e o benefício que seria recebido no plano de aposentadoria.
                                </li>
                            </ul>

                            <h3><strong>Como usar este quadro?</strong></h3>
                            <ul>
                                <li>
                                    Escolha uma taxa de juros/rentabilidade real com base em expectativas realistas para o produto financeiro de sua escolha, como CDB, Tesouro Direto ou outro investimento.
                                </li>
                                <li>
                                    Compare os resultados financeiros projetados:
                                    <ul>
                                        <li>Se o rendimento do produto financeiro for superior ao benefício previdenciário, isso pode indicar que investir diretamente é mais vantajoso.</li>
                                        <li>Se o benefício for maior, o plano de aposentadoria pode ser a melhor opção.</li>
                                    </ul>
                                </li>
                                <li>
                                    Ajuste a taxa de rentabilidade real para simular diferentes cenários e avaliar o impacto de investimentos com maior ou menor retorno.
                                </li>
                            </ul>

                            <h3><strong>Importante:</strong></h3>
                            <p>
                                A projeção financeira não considera impostos, taxas ou outros custos associados ao investimento. Certifique-se de incluir esses fatores ao tomar sua decisão.
                            </p>

                            <p>
                                Para mais dúvidas ou suporte, entre em contato com nossa equipe.
                            </p>
                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-4  d-flex py-2">
                <div class="col-12 py-2 d-flex flex-column justify-content-between bg-secondary rounded ">
                    <h5 class=" card-title text-uppercase mb-0 text-default">
                        Juros / Rentabilidade real
                    </h5>
                    <input [inputMask]="jurosInputMask" [(ngModel)]="opcoesMatriz.rentabilidade" class=" form-control text-left mb-0" placeholder type="text" />
                </div>
            </div>
        </div>

        <div class="row">
            <!-- <div class="col-1">

                <div class="dropdown">

                    <div data-bs-toggle="dropdown" aria-expanded="false" role="button">
                        <div class="row">
                            <div class="col-auto text-center">
                                <i class="comando bi bi-plus-square-dotted fs-6"></i>
                                <p class="h6">
                                    Incluir <br>Produto<br> Financeiro
                                </p>
                            </div>
                        </div>
                    </div>

                    <div #dropdownMenu class="dropdown-menu">
                        <li>
                            <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.13)">
                                Rentabilidade 13% a.a.
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.14)">
                                Rentabilidade 14% a.a.
                            </button>
                        </li>
                        <li>
                            <button class="dropdown-item" role="button" (click)="selecionarRentabilidade(0.15)">
                                Rentabilidade 15% a.a.
                            </button>
                        </li>

                        <div class="container">
                            <div class="col-12 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    Outro valor
                                </h5>
                                <input [inputMask]="jurosInputMask" [(ngModel)]="novaRentabilidade" placeholder="00 %" class=" form-control text-left" #inputTitulo type="text" />
                                <p class="h6">
                                    Rentabilidade Anual
                                </p>
                            </div>
                            <div class="col-12 text-right py-2">
                                <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddRentabilidade()"></i>
                                <i class="comando bi bi-arrow-right fs-4" role="button" (click)="addRentabilidade()"></i>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col">

                <div class="row">
                    <ng-container *ngFor="let rentabilidade of opcoesMatriz.listaRentabilidades">
                        <div class="col-4 item">
                            <div class="row p-2">
                                <div class="col-12 bg-secondary rounded py-4">
                                    <div class="row">
                                        <div class="col-auto">
                                            <p class="lh-1 fw-light">
                                                Rentabilidade {{rentabilidade * 100 | number : '1.0-2'}}% a.a.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end">
                                        <div class="col-auto excluir-item">
                                            <i class="bi bi-trash" role="button" (click)="excluirRendimento(rentabilidade)"></i>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </div>

            </div> -->
        </div>
    </div>

    <div class>
        <app-editor-imposto-renda [edicaoImpostoRenda]="opcoesMatriz.edicaoImpostoRenda"></app-editor-imposto-renda>
    </div>

    <div class>
        <!-- <app-editor-otimizacao-kertzman></app-editor-otimizacao-kertzman> -->
    </div>

    <div class="pb-4">
        <div class="row ">
            <div class="col">
                <h5 class="mb-0 linha-quadro">Otimização da Aposentadoria por Idade
                    <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroOtimizacao" aria-expanded="false" aria-controls="helpQuadroOtimizacao"></i>
                </h5>

                <div class="collapse" id="helpQuadroOtimizacao">

                    <div class="row py-4 d-flex ">

                        <div class="col-3 py-2 d-flex">
                            <div class="row bg-secondary rounded m-2">
                                <div class="col d-flex p-2">

                                    <p class=" p-2 lh-1 ">
                                        Método Kertzman
                                    </p>

                                </div>
                                <div class="mt-auto pb-2 text-right">
                                    <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>

        <div class="py-4">
            <div class="row">
                <div class="col-12">

                    <div class="form-group mb-3 py-2">
                        <h5 class="text-uppercase mb-0 text-default">
                            Método Kertzman
                        </h5>
                        <div class="form-inline d-flex align-items-center">
                            <div class="btn-group w-100" btnRadioGroup (ngModelChange)="aplicarOtimizacao($event)" [(ngModel)]="opcoesMatriz.otimizacaoKertzman">
                                <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Não otimizar</label>
                                <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Aplicar otimização Método Kertzman</label>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>