import { Component, EventEmitter, Input, Output } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { AppComponent } from 'src/app/app.component';
import { EdicaoImpostoRenda } from 'src/app/modelos/previdenciario/edicaoimpostorenda';
import { OpcoesMatriz } from 'src/app/modelos/previdenciario/opcoesmatriz';
import { OpcoesPlanejamento } from 'src/app/modelos/previdenciario/opcoesplanejamento';

@Component({
  selector: 'app-editor-imposto-renda',
  templateUrl: './editor-imposto-renda.component.html',
  styleUrl: './editor-imposto-renda.component.scss'
})
export class EditorImpostoRendaComponent {

  @Input() edicaoImpostoRenda: EdicaoImpostoRenda;
  

  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  constructor(){
    this.currencyInputMask.autoUnmask = true;
    this.currencyInputMask.unmaskAsNumber = true;
  }

  atualizarDespesasMedicas(){
    
  }
}
