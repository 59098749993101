<div class="row ">
    <div class="col">
        <h5 class="mb-0 linha-quadro">Atividades Secundárias
            <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroAtividadeSecundaria" aria-expanded="false" aria-controls="helpQuadroAtividadeSecundaria"></i>
        </h5>

        <div class="collapse" id="helpQuadroAtividadeSecundaria">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Inclua uma nova atividade secundária nos casos em que houver mais de uma fonte de remuneração
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <!-- <i class="comando bi bi-clock-history fs-3"></i> -->
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="py-2">
    <div class="row">
        <div class="col-auto">
            <div class="dropdown">

                <div data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false" role="button">
                    <div class="row">
                        <div class="col-auto text-center">
                            <i class="comando bi bi-plus-square-dotted fs-6"></i>
                            
                        </div>
                    </div>

                </div>

                <div #dropdownMenu id="dropAddAtividade" class="dropdown-menu" style="width: max-content; max-width: max-content;">
                    <div class="container">
                        <div class="row py-4 d-flex">

                            <div class="col-3 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    Início
                                </h5>
                                <ejs-datepicker [strictMode]="true" [enableMask]="true" (change)="changeDataInicio($event)" locale="pt" [(ngModel)]="atividade.inicio"></ejs-datepicker>
                            </div>
                            <div class="col-3 py-2">
                                <h5 class=" align-middle card-title text-uppercase mb-0 text-default">
                                    Opções data Fim
                                </h5>

                                <div class="btn-group w-100 " btnRadioGroup (ngModelChange)="atualizarOpcaoDataFim($event)" [(ngModel)]="atividade.limitarDataFim">
                                    <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Até a aposentadoria</label>
                                    <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Limitar data</label>
                                </div>
                            </div>
                            <div class="col-3 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    FIM
                                </h5>
                                <ejs-datepicker [disabled]="!atividade.limitarDataFim" [enableMask]="true" [strictMode]="true" (change)="changeDataInicio($event)" locale="pt" [(ngModel)]="atividade.fim"></ejs-datepicker>
                            </div>

                            <div class="col-3 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    Salário de Contribuição
                                </h5>
                                <input (blur)="atualizarSalarioBlur($event)" [inputMask]="currencyInputMask" class=" form-control text-left" placeholder="R$ 0,00" type="text" [(ngModel)]="atividade.salario" />
                            </div>

                            <div class="col-3 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    Intervalo entre as Contribuições
                                </h5>
                                <div class="dropdown w-100" style="position: static;">
                                    <button class="btn w-100 btn-outline-default text-left  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{atividade.periodicidade | periodicidade}}
                                    </button>
                                    <ul class="dropdown-menu w-75">
                                        <li *ngFor="let periodicidade of periodicidadeOptions">
                                            <button class="dropdown-item" (click)="editarPeriodicidade(periodicidade)">{{periodicidade | periodicidade}}</button>
                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div class="col-3 py-2">
                                <h5 class=" card-title text-uppercase mb-0 text-default">
                                    Tipo de Vínculo
                                </h5>
                                <div class="dropdown w-100" style="position: static;">
                                    <button class="btn w-100 btn-outline-default  dropdown-toggle text-left" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {{atividade.tipoVinculo | tipoVinculo}}
                                    </button>
                                    <ul class="dropdown-menu w-75">
                                        <li *ngFor="let tipoVinculo of tiposVinculosOptions">
                                            <button class="dropdown-item" (click)="editarTipoVinculo(tipoVinculo)">{{tipoVinculo | tipoVinculo}}</button>
                                        </li>

                                    </ul>
                                </div>

                            </div>

                            <div class="col-3 py-2">
                                <h5 class=" align-middle card-title text-uppercase mb-0 text-default">
                                    Opções de Alíquota
                                </h5>

                                <div class="btn-group w-100" btnRadioGroup (ngModelChange)="atualizarOpcaoDataFim($event)" [(ngModel)]="atividade.aliquotaAutomatica">
                                    <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="true">Automático</label>
                                    <label class="btn btn-lg btn-outline-black mb-0" [btnRadio]="false">Manual</label>
                                </div>
                            </div>

                            <div class="col-3">
                                <h5 class=" align-middle card-title text-uppercase mb-0 text-default">
                                    Alíquota
                                </h5>
                                <input [disabled]="atividade.aliquotaAutomatica" [(ngModel)]="atividade.aliquota" [inputMask]="aliquotaInputMask" class=" form-control text-left"
                                    placeholder="0 %" type="text" />
                            </div>

                            <!-- <div class="col-1 py-2 mt-auto">
                                <i class="comando bi bi-plus-lg fs-3 " (click)="addAtividade()" role="button" container="body" ngbTooltip="Confirmar atividade" triggers="hover"></i>
                            </div> -->

                        </div>
                    </div>

                    <div class="container">
                        <!-- <div class="col-12 py-2">
                            <h5 class=" card-title text-uppercase mb-0 text-default">
                                Outro valor
                            </h5>
                            <input [inputMask]="currencyInputMask" [(ngModel)]="novoSalario" placeholder="R$ 0,00" class=" form-control text-left" #inputTitulo type="text" />
                            <p class="h6">
                                Salário de contribuição
                            </p>
                        </div> -->
                        <div class="col-12 text-right py-2">
                            <i class="comando bi bi-x fs-6" role="button" (click)="fecharAddAtividade()"></i>
                            <i class="comando bi bi-plus-lg fs-4" role="button" (click)="addAtividade()" container="body" ngbTooltip="Confirmar atividade" triggers="hover"></i>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="col">
            <div class="row py-2">
                <div *ngIf="opcoesPlanejamento.atividadesSecundarias && opcoesPlanejamento.atividadesSecundarias.length==0" class="row py-2">
                    <p>
                        Está vazio por aqui <i class="bi bi-moon-stars"></i>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <ng-container *ngIf="opcoesPlanejamento.atividadesSecundarias && opcoesPlanejamento.atividadesSecundarias.length>0">
                        <p>
                            Atividades Secundárias
                        </p>
                        <div class="table-responsive">
                            <table class="table table-bordered table-sm  table-striped  table-hover align-middle">
                                <thead>

                                    <tr>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Data de Início
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Data Fim
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Salário de Contribuição
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Periodicidade
                                            </span>
                                        </th>

                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Tipo de Vínculo
                                            </span>
                                        </th>
                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Alíquota
                                            </span>
                                        </th>

                                        <th class=" alignt-middle text-center" scope="col">
                                            <span>
                                                Opções
                                            </span>
                                        </th>

                                    </tr>

                                </thead>
                                <tbody>

                                    <ng-container class *ngFor="let atividade of opcoesPlanejamento.atividadesSecundarias; index as index;">

                                        <tr>
                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    {{atividade.inicio | date : 'dd/MM/yyyy'}}
                                                </span>

                                            </td>

                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    <ng-container *ngIf="!atividade.limitarDataFim">
                                                        <span>até a data da aposentadoria</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="atividade.limitarDataFim">
                                                        {{atividade.fim | date : 'dd/MM/yyyy'}}
                                                    </ng-container>
                                                </span>
                                            </td>
                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    {{atividade.salario | currency}}
                                                </span>

                                            </td>

                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    {{periodicidadeOptions[atividade.periodicidade]}}
                                                </span>
                                            </td>

                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    {{atividade.tipoVinculo | tipoVinculo}}
                                                </span>
                                            </td>

                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    <ng-container *ngIf="atividade.aliquotaAutomatica">
                                                        <span>{{atividade.aliquota * 100 | number : '1.0-0'}}% (presumido pelo tipo do vínculo)</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="!atividade.aliquotaAutomatica">
                                                        {{atividade.aliquota * 100 | number : '1.0-0'}}%
                                                    </ng-container>
                                                </span>
                                            </td>

                                            <td class=" align-middle text-center">
                                                <span class=" align-middle text-center ">
                                                    <i class="bi bi-trash" role="button" container="body" tooltip="Excluir atividade secundaria" triggers="hover" (click)="excluirAtividadeSecundaria(atividade)"></i>
                                                </span>
                                            </td>

                                        </tr>

                                    </ng-container>

                                </tbody>
                            </table>
                        </div>
                    </ng-container>

                </div>
            </div>

        </div>
    </div>

</div>
