
<h5 class="mb-0 linha-quadro">Logomarca</h5>

<div class="row">
  <div class="col-lg-12">
    <p> Use esse espaço para configurar o arquivo da sua logomarca e personalizar os relatórios.</p>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class=" form-group">
      
    </div>
  </div>
</div>

<div class="row flex d-flex align-content-middle">
  
  <div class="col-auto align-self-center">
    <i *ngIf="!logoUrl" class="bi bi-shop-window fs-1"></i>
    <img *ngIf="logoUrl"
      alt="Image placeholder"
      class="pb-4"
      src={{logoUrl}}
      width="100px" />

  </div>

  <div class="col-auto align-self-center">
    <input
        style="display: none"
        type="file"
        #fileInput
        multiple="multiple"
        (change)="selecionarArquivo($event)" />
      <button type="button" (click)="showFileDialog()" class="btn btn-outline-black">
        Selecionar sua logo do arquivo
      </button>
    
  </div>

  
  <!-- <div class="col-lg-12">
    <i *ngIf="!logoUrl" class="bi bi-shop-window fs-1"></i>
    <img *ngIf="logoUrl"
      alt="Image placeholder"
      class="pb-4"
      src={{logoUrl}}
      width="100px" />
  </div> -->
</div>
