<div class="row">
  <div class="col-12">
    <h5 class="mb-0 linha-quadro">Payback</h5>
  </div>

  <div *ngIf="visualizarPdf" class="col-12 d-flex flex-row-reverse">
    <i class="bi-filetype-pdf h1 mb-0 clicavel" (click)="gerarPDF()"></i>

    <div *ngIf="aguardePdf" class="spinner-border  mx-2" >
      <span class="visually-hidden">aguarde...</span>
    </div>

    
  </div>
  
</div>


<div class="row" >
  <!-- Chart wrapper -->
  <div class="col-lg-12" style="height: 300px;">
    <canvas id="variacaoPaybackMeses"></canvas>
  </div>
</div>