import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';

import { ExcluirLeituraCnisService } from './../../../../../servicos/calculo/previdenciario/excluir-leitura-cnis.service';
import { AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, viewChild, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import { RelacaoPrevidenciaria } from 'src/app/modelos/previdenciario/relacaoprevidenciaria';
import { ptBrLocale } from 'ngx-bootstrap/locale';

import { defineLocale } from 'ngx-bootstrap/chronos';
import { te } from 'date-fns/locale';
import * as moment from 'moment';
import 'moment-precise-range-plugin';
import { Sexo } from 'src/app/modelos/previdenciario/sexo';
import { RequestRelatorioTempoContribuicao } from 'src/app/modelos/previdenciario/requestrelatoriotempocontribuicao';
import { RelatorioTempoContribuicaoService } from 'src/app/servicos/calculo/previdenciario/relatorio-tempo-contribuicao.service';
import { RequestCalculoBeneficios } from 'src/app/modelos/previdenciario/requestcalculobeneficios';
import { Conjuge } from 'src/app/modelos/previdenciario/conjuge';
import { Tempo } from 'src/app/modelos/previdenciario/tempo';
import { Contribuicao } from 'src/app/modelos/previdenciario/contribuicao';
import { TipoAtividade } from 'src/app/modelos/previdenciario/tipoatividade';
import { stringify } from 'querystring';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createMask } from '@ngneat/input-mask';
import * as currency from 'currency.js';
import { Global } from 'src/app/modelos/global';
import { AppComponent } from 'src/app/app.component';
import { Navegacao } from 'src/app/modelos/ui/navegacao';
import { FasesPlanejamento } from 'src/app/modelos/ui/fasesplanejamento';
import { FasesBeneficios } from 'src/app/modelos/ui/fasesbeneficios';
import { Fluxos } from 'src/app/modelos/ui/fluxos';
import { FasesRevisaoVidaToda } from 'src/app/modelos/ui/fasesrevisaovidatoda';
import { FasesPlanejamentoKertzman } from 'src/app/modelos/ui/fasesplanejamentokertzman';
import { Usuario } from 'src/app/modelos/usuario/usuario';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { ExtratoRelacoesPrevidenciariasComponent } from '../extrato-relacoes-previdenciarias/extrato-relacoes-previdenciarias.component';
import { SalvarLeituraCnisEdicaoService } from 'src/app/servicos/calculo/previdenciario/salvar-leitura-cnis-edicao.service';

import { LinkMarcadorLeituraCnisComponent } from '../link-marcador-leitura-cnis/link-marcador-leitura-cnis.component';
import { Marcador } from 'src/app/modelos/common/marcador';
import { EditorCnisTabularComponent } from '../editor-cnis-tabular/editor-cnis-tabular.component';
import { AtualizarEdicaoCnisService } from 'src/app/servicos/calculo/previdenciario/atualizar-edicao-cnis.service';
import { QuadroSeguradoEditorCnisComponent } from '../quadro-segurado-editor-cnis/quadro-segurado-editor-cnis.component';
import { CalculeiApiService } from 'src/app/servicos/calculei/calculei-api.service';
import { BuracoTempoContribuicaoComponent } from '../buraco-tempo-contribuicao/buraco-tempo-contribuicao.component';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { EditorCnisGridComponent } from '../editor-cnis-grid/editor-cnis-grid.component';
defineLocale('pt-br', ptBrLocale);

declare module 'moment' {
  function preciseDiff(start: string | Date | moment.Moment, end: string | Date | moment.Moment, convertToObject?: boolean): any;
}

@Component({
  selector: 'app-editor-cnis',
  templateUrl: './editor-cnis.component.html',
  styleUrls: ['./editor-cnis.component.scss']
})
export class EditorCNISComponent implements OnInit, OnChanges, AfterViewChecked {

  @ViewChild('extratoRelacoesPrevidenciarias') extratoRelacoesPrevidenciarias: ExtratoRelacoesPrevidenciariasComponent;
  
  @ViewChild('linkMarcadorLeituraCnis') linkMarcadorLeituraCnis: LinkMarcadorLeituraCnisComponent;
  @ViewChild('editorCnisGrid') editorCnisGrid: EditorCnisGridComponent;
  @ViewChild('timeline') timeline: BuracoTempoContribuicaoComponent;


  @Input() calculoBeneficios: CalculoBeneficios;
  @Output() edicaoCNISFinalizadaEvent = new EventEmitter<CalculoBeneficios>();
  @Output() listaSeletorCnisAtualizadaEvent = new EventEmitter<boolean>();
  @Output() cnisAtualizadoEvent = new EventEmitter<CalculoBeneficios>();
  @Output() excluirCnisFinalizadoEvent = new EventEmitter<boolean>();
  @Output() cnisModificadoEvent = new EventEmitter<any>();


  cnisModificado: boolean;

  edicaoRelacoesPrevidenciarias = {};
  edicaoContribuicoes = {};
  cnisAtualizado: CNIS;
  radioModelSexo = 'Masculino';
  dataBaseCalculo: Date;
  bsValueDataCalculo: Date;
  dataCalculo: Date;
  requestRelatorioTempoContribuicao: RequestRelatorioTempoContribuicao;
  requestCalculoBeneficios: RequestCalculoBeneficios;

  entries: number = 10;



  pensaoMorte: boolean;
  bsValueDataObito: Date;
  dataObito: Date;
  filhosMenores21: number = 0;
  conjuges: number = 0;
  conjuge: Conjuge;
  filhosDeficientesInvalidos: number = 0;
  formDependentes: boolean = false;
  formPensaoMorte: boolean = false;


  selecaoTipoAtividade: string;
  tiposAtividade: string[] = ['Normal', 'Professor', 'Rural', 'Especial15', 'Especial20', 'Especial25'];


  indicadores: string[][] = [[]];



  dateInputMask = createMask<Date>(AppComponent.dateInputOptions);

  currencyInputMask = createMask(AppComponent.currencyInputOptions);

  checkModel: { left?: boolean; middle?: boolean; right?: boolean } = { left: false, middle: true, right: false };
  radioModel = 'Masculino';


  edicaoSexoVisivel: boolean = false;
  edicaoDataCalculoVisivel: boolean = false;

  usuario: Usuario;

  leituraCnisEdicao: LeituraCNIS;
  exibirGridTelaCheia: boolean;


  constructor(private relatorioTempoContribuicaoService: RelatorioTempoContribuicaoService, private changeDetector: ChangeDetectorRef,
    private calculeiApiService: CalculeiApiService,
    private atualizarEdicaoCnisService: AtualizarEdicaoCnisService,
    private salvarLeituraCnisEdicaoService: SalvarLeituraCnisEdicaoService,
    private excluirLeituraCnisService: ExcluirLeituraCnisService,
    private configService: ConfigService) {

    this.dataBaseCalculo = new Date();
    this.usuario = Global.usuario;

    if (!environment.production) {
      this.exibirGridTelaCheia = true;
    }
  }

  habilitarSalvarEdicao(habilitar: boolean) {
    this.cnisModificado = habilitar;
    this.cnisModificadoEvent.emit(true);
  }

  finalizarEdicaoCNIS() {}

  

  incluirMarcador() {
    // this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.sexo = this.editorCnisForm.get('sexo').value as Sexo;
    this.linkMarcadorLeituraCnis.openModal(this.calculoBeneficios.leituraCnis);
  }

  linkMarcadorConcluido(marcadores: Marcador[]) {
    // console.log('link marcadores concluido - editor cnis');
    // console.log(marcadores);

    this.calculoBeneficios.leituraCnis.marcadores = marcadores;
    this.leituraCnisEdicao.marcadores = marcadores;
  }


  excluirCnis() {
    // console.log('excluir cnis - editor cnis component');


    this.excluirLeituraCnisService.excluirLeituraCnis(this.calculoBeneficios.leituraCnis)
      .then((leituraCnis: LeituraCNIS) => {
        // console.log('exlcluir leitura cnis retorno api');
        this.excluirCnisFinalizadoEvent.emit(true);
      });


  }


  tipoAtividadeToString(tipo): string {
    let resultado: string = '';

    switch (tipo) {
      case TipoAtividade.Normal:
        resultado = 'Normal';
        break;
      case TipoAtividade.Professor:
        resultado = 'Professor';
        break;
      case TipoAtividade.Rural:
        resultado = 'Rural';
        break;
      case TipoAtividade.Especial15:
        resultado = 'Especial 15';
        break;
      case TipoAtividade.Especial20:
        resultado = 'Especial 20';
        break;
      case TipoAtividade.Especial25:
        resultado = 'Especial 25';
        break;
      default:
        resultado = '-';
        break;
    }
    return resultado;
  }







  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();

  }


  ngOnChanges(changes: SimpleChanges): void {
  }


  ngOnInit(): void {
    this.carregarDados();
    
    
  }

  carregarDados() {
    
  }


  leituraCnisAtualizada(calculoBeneficios: CalculoBeneficios) {
    // o cnis foi alterado no quadro segurado ou no editor tabular. marcar para salvar
    // console.log(leituraCnis.cnis.relacoesPrevidenciarias);
    this.cnisModificado = true;
  }

  atualizarCnisModificado(){
    this.configService.setAguarde(true);
    // this.calculoBeneficios = calculoBeneficios;
    this.calculeiApiService.api("AtualizarEdicaoCnis", this.calculoBeneficios)
      .subscribe(atualizacao => {
        this.calculoBeneficios = atualizacao as CalculoBeneficios;
        // this.timeline.carregarDados(this.calculoBeneficios);
        // console.log('cnis atualizado', atualizacao);
        
        this.cnisAtualizadoEvent.emit(this.calculoBeneficios);
        this.configService.setAguarde(false);
      });
  }

  salvarModificacoesCnis(){

    if(!this.cnisModificado)return;

    const inputValue = "Editado " + (this.usuario.configuracao.anonimo ? this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome.split(' ')[0] : this.calculoBeneficios.leituraCnis.cnis.identificacaoFiliado.nome).toString();
    Swal.fire({
      title: "Salvar alterações do CNIS",
      input: "text",
      inputValue : inputValue,
      showCancelButton: true,
      cancelButtonText: "cancelar",
      confirmButtonColor: "black",
      confirmButtonText: "ok",
      inputValidator: (value) => {
        if (!value) {
          return "insira uma referência para localizar o cnis mais tarde!";
        }
      }
    }).then(confirmacao=>{

      if (confirmacao.isConfirmed) {
        if (this.cnisModificado) {
          // console.log('prep salvar cnis');
          this.calculoBeneficios.leituraCnis.nome = confirmacao.value;
          this.configService.inserirHistoricoLeituraCnis(this.calculoBeneficios.leituraCnis);
          this.cnisModificado = false;
          this.calculeiApiService.api("SalvarLeituraCnisEdicao", this.calculoBeneficios.leituraCnis)
          .subscribe(resultado=>{
            let atualizacao:LeituraCNIS = resultado as LeituraCNIS;
            this.configService.setHistoricoLeituraCnis();
            this.calculoBeneficios.leituraCnis = atualizacao;
            this.cnisModificado = false;
          });
        }
        
      } else {
        this.cnisModificado = false;
      }

    });
  }


  excluirFinalizado() {

  }


  gridTelaCheia(telaCheia:boolean){
    this.exibirGridTelaCheia = telaCheia;
  }




}
