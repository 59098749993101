<div class="card">
  <div class="card-header border-dark">
    <h5 class="mb-0">Planos Base</h5>
  </div>
  <div class="card-body">
    <div class="row justify-content-center py-2" >
      <div class="col-4 py-4 d-flex" *ngFor="let plano of listaPlanos">
    
          <div class="card">
              <div class="card-body">
                  <div class="row align-items-center">
                      <div class="col-12 text-center py-4">
                          <h3 class=" h3 mb-0">{{plano.nome}}</h3>
                      </div>
    
                      <!-- <div class="col  text-center py-2">
                          <span> de </span><span class="text-decoration-line-through">{{plano.valorOriginal | currency}}</span><span> por</span>
                      </div> -->
    
                      <div class="row align-items-end  py-2">
                          <div class="col  text-center">
                              <!-- <span class=" display-1  ">{{plano.valor | currency}}</span><span >/{{plano.periodo | periodo}}</span> -->
                              <span class=" display-1  ">{{plano.valor | currency}}</span>
                          </div>
                      </div>
    
    
    
    
                      <div class="col  text-center py-4">
                          <span>{{plano.meioPagamento | meioPagamento}}</span>
                      </div>
                      
    
                      <div class="col-12 text-center py-4">
                          <button class="btn btn-default w-75" (click)="checkout(plano)">Assinar Plano {{plano.nome}}</button>
                      </div>
                  </div>
    
              </div>
          </div>
    
        </div>
    </div>  
  </div>
</div>