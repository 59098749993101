<div class="row pb-2">
    <div class="col">
        <h5 class="mb-0 linha-quadro"></h5>
        <div class="row">
            <div class="col-6">
                <h5 class="mb-0">Otimização
                    <i *ngIf="visualizarPdf" class=" comando bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpOtimizacao" aria-expanded="false" aria-controls="helpOtimizacao"></i>
                </h5>
            </div>
            <div *ngIf="visualizarPdf" class="col-6">
                <div class="text-right">
                    <i class="comando bi bi-filetype-pdf fs-6 " role="button" container="body" (click)="gerarPdf()" ngbTooltip="Salvar PDF" triggers="hover"></i>
                </div>
            </div>
        </div>

        <div class="collapse" id="helpOtimizacao">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row w-100 bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1">
                                Otimização da regra de aposentadoria por idade
                            </p>

                        </div>
                        <!-- <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div *ngIf="relatorio.otimizacao.totalSubstituicoesTeto > 0" class="row">
    <div class="col-4">
        <div>
            <div>
                <p class="text-center fw-lighter h6 text-uppercase">melhor investimento</p>
            </div>
            <p class="display-1 lh-1  mb-0">
                {{relatorio.otimizacao.totalSubstituicoesTeto}}
            </p>
            <p class="lh-1 fw-light">
                Contribuições de otimização
            </p>
        </div>
        <div>
            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.dataAposentadoria | date : 'dd/MM/yyyy'}}
                </p>

                <p class="lh-1 fw-lighter">
                    Data da aposentadoria
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.idadeDataAposentadoria.porExtenso}}
                </p>

                <p class="lh-1 fw-lighter">
                    Idade na data de Aposentadoria
                </p>
            </div>
        </div>

    </div>
    <div class="col-4">
        <div>
            <div>
                <p class="text-center fw-lighter h6 text-uppercase">ganho do investimento</p>
            </div>

            

            

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.valorBeneficioAbonoAnual | currency}}
                </p>

                <p class="lh-1 fw-lighter">
                    Benefício com investimento otimizado
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.valorBeneficioCenarioBase| currency}}
                </p>

                <p class="lh-1 fw-lighter">
                    Benefício com investimento mínimo
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 mb-0">
                    {{relatorio.otimizacao.ganhoBeneficio | currency}}

                </p>

                <p class="lh-1 fw-lighter">
                    Ganho na benefício com otimização do investimento
                </p>
            </div>

            


            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.lucroPlanejamentoContributivo | currency}}

                </p>

                <p class="lh-1 fw-lighter">
                    Estimativa de ganho em {{relatorio.otimizacao.expectativaVida | number : '1.1-1'}}
                    anos / {{relatorio.otimizacao.expectativaVida * 12 | number : '1.0-0'}} meses
                </p>
            </div>

        </div>

    </div>
    <div class="col-4">

        <div>
            <div>
                <p class="text-center fw-lighter h6 text-uppercase">Investimento</p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.valorContribuicaoComplementarMensal | currency}}
                </p>

                <p class="lh-1 fw-lighter">
                    Valor da contribuição de otimização
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.totalInvestimentoSubstituicoes | currency}}
                </p>

                <p class="lh-1 fw-lighter">
                    Total em contribuições de otimização
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.custoContributivoTotalImpostoRenda | currency}}
                </p>

                <p class="lh-1 fw-lighter">
                    Imposto de Renda
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.totalInvestimento | currency}}
                </p>

                <p class="lh-1 fw-lighter">
                    Total do Investimento
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 mb-0">
                    {{relatorio.otimizacao.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} % a. m.

                </p>

                <p class="lh-1 fw-lighter">
                    Taxa de Retorno
                </p>
            </div>

            <div>
                <p class="display-4 lh-1  mb-0">
                    {{relatorio.otimizacao.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses

                </p>

                <p class="lh-1 fw-lighter">
                    Payback
                </p>
            </div>

            
        </div>

    </div>
</div>



<div *ngIf="relatorio.otimizacao.totalSubstituicoesTeto == 0" class="row">
    <div class="col-12">
        <div>
            <div>
                <p class="text-center fw-lighter h6 text-uppercase">melhor investimento</p>
            </div>
            <p class="display-1 lh-1  mb-0">
                Otimização não indicada
            </p>
            <p class="lh-1 fw-light">
                Recomenda-se apenas a manutenção da qualidade de segurado
            </p>
        </div>
        <!-- <div>
            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.dataAposentadoria | date : 'dd/MM/yyyy'}}
                </p>

                <p class="lh-1 fw-lighter">
                    Data da aposentadoria
                </p>
            </div>

            <div>
                <p class="display-4 lh-1 fw-lighter mb-0">
                    {{relatorio.otimizacao.idadeDataAposentadoria.porExtenso}}
                </p>

                <p class="lh-1 fw-lighter">
                    Idade na data de Aposentadoria
                </p>
            </div>
        </div> -->

    </div>
    
</div>


























<!-- <div class="row py-4 text-default">
    <div class="col-12">

        <div class="row justify-content-end ">

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalSubstituicoesTeto}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Contribuições opcionais previstas para otimização do benefício
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorContribuicaoComplementarMensal | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor mensal da contribuição de otimização
                </span>

            </div>
            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimentoSubstituicoes | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total das contribuições opcionais para otimização do benefício
                </span>

            </div>

           
            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimento | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Total do Investimento
                </span>

            </div>

        </div>

        <div class="row justify-content-end">

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} % a. m.

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Taxa de Retorno do Investimento de Otimização
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste-ouro">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorBeneficio | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor do benefício otimizado (sem abono anual)
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste-ouro">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorBeneficioAbonoAnual | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor do benefício otimizado (com abono anual)
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorBeneficioCenarioBase| currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor do benefício sem otimização (pré-análise)
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.ganhoBeneficio | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Ganho no valor do benefício com as contribuições opcionais de otimização incluindo abono anual
                </span>

            </div>
            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.lucroPlanejamentoContributivo | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Ganho no lucro com as contribuições opcionais de otimização incluindo abono anual
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.dataAposentadoria | date : 'dd/MM/yyyy'}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Data prevista para a aposentadoria
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo destaque-contraste">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Payback do investimento nas contribuições opcionais de otimização
                </span>

            </div>

        </div>

        <div *ngIf="relatorio.otimizacao.totalContribuicoesPlanejadas > 0" class="row justify-content-end">

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalContribuicoesPlanejadas}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Contribuições previstas para cumprimento de carência
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorContribuicaoComplementarMensal | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor mensal da contribuição para cumprimento de carência
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimentoPlanejamento | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total das contribuições previstas para cumprimento de Carência
                </span>

            </div>

        </div>

        <div *ngIf="relatorio.otimizacao.totalContributivoQualidadeSeguradoMeses > 0" class="row justify-content-end">

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalContributivoQualidadeSeguradoMeses | number : '1.0-0'}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Contribuições previstas para manutenção da Qualidade de Segurado
                </span>

            </div>

            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.valorContribuicaoQualidadeSegurado | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Valor da contribuição para cumprimento de manutenção da qualidade de segurado
                </span>

            </div>
            <div class="col-12 col-xl-6 py-4 continuo">

                <h1 class=" display-2 card-title  mb-0 ">

                    {{relatorio.otimizacao.totalInvestimentoQualidadeSegurado | currency}}

                </h1>
                <span class=" display-4 font-weight-lighter  mb-0 ">
                    Custo total das contribuições para manutenção da qualidade de segurado
                </span>

            </div>

        </div>

    </div>

</div> -->
