




<div class="  ">

  <div class="pb-4">
    <div class="card">
      <div class="row">
        <div class="col">
          <div class="card-body">
            <span class=" display-3 font-weight-lighter  mb-0 text-default">
              Relatório Valor da Causa
            </span>


          </div>
        </div>

      </div>


      <div class="row">
        <div class="col">



          <div id="passo1" [className]="calculoValorCausa.relatorioValorCausa.parcelas? 'visible' : 'invisible'">

            <div class="row">
          
              <div class=" col-12">
                <div class=" card">
                  <div class=" card-header border-dark">
                    <h5 class="mb-0">Informações do Cálculo</h5>
                  </div>
          
                  <div class=" card-body">
          
                    <div class=" row mb-5">
                      <div class=" col-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Segurado
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.referencia.nome}}</span>
                      </div>
                      <div class="col-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          CPF
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.referencia.cpf}}</span>
                      </div>
          
                    </div>
          
          
                    <div class=" row mb-5">
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Tipo do Processo
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{tipoProcessoTexto}}</span>
                      </div>
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Tipo do Benefício
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{tipoBeneficioTexto}}</span>
                      </div>
                    </div>
          
          
                    <div class=" row mb-5">
          
          
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Data de Início do Benefício
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.beneficio.dib | date :
                          'dd/MM/yyyy'}}</span>
                      </div>
          
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Data de Início de Pagamento
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.beneficio.dip | date :
                          'dd/MM/yyyy'}}</span>
                      </div>
          
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Data do Cálculo
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.referencia.dataCalculo | date :
                          'dd/MM/yyyy'}}</span>
                      </div>
          
          
                    </div>
          
          
          
                    <div class=" row mb-5">
          
          
                      <div *ngIf="calculoValorCausa.valorCausa.beneficio.acrescimo25Dependente" class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          DIB 25%
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.beneficio.dib25 | date :
                          'dd/MM/yyyy'}}</span>
                      </div>
          
          
          
          
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Renda Mensal Inicial
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.valorCausa.beneficio.rmi | currency }}</span>
                      </div>
                    </div>
          
          
          
          
          
          
                    <!-- <div class=" row mb-5">
                      <div class=" col-xl-6 col-md-6">
                        <h5 class="text-uppercase text-muted mb-0">
                          Abatimentos
                        </h5>
                        <ul>
                          <li *ngFor="let abatimento of calculoValorCausa.valorCausa.abatimentos.abatimentos">
                            <span class=" h2 font-weight-bold mb-0 ">Competência: {{abatimento.competencia | date : 'MM/yyyy'}},
                              Valor: {{abatimento.valor | currency}}</span>
                          </li>
                        </ul>
                      </div>
          
          
                    </div> -->
          
          
          
                    <div class=" row mb-5">
          
          
                      <div class=" col-12 ">
                        <h5 class="text-uppercase text-muted mb-0">
                          Correção Monetária
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{protocoloCorrecaoMonetariaTexto}}:   </span>
                        <span *ngFor="let regra of regrasIndexadores" class=" h2 font-weight-bold mb-0 ">{{regra.indice}}, de {{regra.dataInicial | date : 'dd/MM/yyyy'}} a {{regra.dataFinal | date : 'dd/MM/yyyy'}}. </span>
                      </div>
          
                    </div>
          
                  </div>
                </div>
              </div>
            </div>
          
          
          
          
          
            <div class="row">
              <div class=" col-xl-12">
                <div class=" card">
                  <div class=" card-header border-dark">
                    <h5 class="mb-0">Totais</h5>
                  </div>
                  <div class=" card-body">
                    <div class=" row">
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Total Parcelas Vencidas
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.relatorioValorCausa.totalParcelasVencidas | currency}} </span>
                      </div>
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Total Parcelas Vincendas
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.relatorioValorCausa.totalParcelasVincendas | currency }} </span>
                      </div>
                      <div class=" col-xl-4 col-md-4">
                        <h5 class="text-uppercase text-muted mb-0">
                          Total Valor da Causa
                        </h5>
                        <span class=" h2 font-weight-bold mb-0 ">{{calculoValorCausa.relatorioValorCausa.totalValorCausa | currency }} </span>
                      </div>
                    </div>
          
                  </div>
                </div>
              </div>
            </div>
          
            
          
          
            <div class="row">
          
              <div class=" col-xl-12">
                <div class=" card">
                  <div class=" card-header border-dark">
                    <h5 class="mb-0">Extrato</h5>
                  </div>
          
                  <div class=" card-body">
          
          
                    <div class="row">
                      <div class=" col-xl-12 col-md-12">
          
                        <ngx-datatable class="material striped" [rows]="rows" [columnMode]="'force'" [footerHeight]="50" [scrollbarH]="true"
                          [rowHeight]="'auto'" headerHeight="auto" (page)="onPage($event)">
          
          
          
                          <ngx-datatable-column name="Competência" prop="competencia" [flexGrow]="25" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
          
                              <span>{{ value | date : "MM/yyyy" }}</span>
                              <span *ngIf="row.decimoTerceiro"> 13<sup>o</sup> </span>
                            </ng-template>
                          </ngx-datatable-column>
          
          
                          <ngx-datatable-column name="Valor Original" prop="valorOriginal" [flexGrow]="25" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value | currency }}</span>
                            </ng-template>
                          </ngx-datatable-column>
          
          
          
                          <ngx-datatable-column name="Abatimento" prop="abatimento" [flexGrow]="25" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value | currency }}</span>
                            </ng-template>
                          </ngx-datatable-column>

                          <ngx-datatable-column name="Desconto Abatimento" prop="valorDescontoAbatimento" [flexGrow]="25" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value | currency }}</span>
                            </ng-template>
                          </ngx-datatable-column>
          
          
                          <ngx-datatable-column name="Fator de correção" prop="fatorIndice" [flexGrow]="30" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value }}</span>
                            </ng-template>
                          </ngx-datatable-column>
          
          
                          <ngx-datatable-column name="Valor Corrigido" prop="valorCorrigido" [flexGrow]="30" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value | currency }}</span>
                            </ng-template>
                          </ngx-datatable-column>
          
          
                          <ngx-datatable-column name="Valor de Correção" prop="correcaoMonetaria" [flexGrow]="30"
                            [sortable]="false" [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value | currency }}</span>
                            </ng-template>
                          </ngx-datatable-column>
          
                          <ngx-datatable-column name="Valor Atualizado" prop="valorAtualizado" [flexGrow]="30" [sortable]="false"
                            [draggable]="false">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                              <span>{{ value | currency }}</span>
                            </ng-template>
                          </ngx-datatable-column>
          
                        </ngx-datatable>
                      </div>
                    </div>
          
          
          
          
                  </div>
                </div>
              </div>
          
          
          
          
          
          
          
            </div>
          
          
          
          
          
          
            
          
          </div>
          
          

        </div>
      </div>


    </div>



  </div>


</div>