<div class="  ">

    <div class="pb-4">
        <div class="card">
            <div class="row">
                <div class="col">
                    <div class="card-body">
                        <span class=" display-3 font-weight-lighter  mb-0 text-default">
                            Atualização dos Valores
                        </span>


                    </div>
                </div>

            </div>



            <div class="row ">
                <div class="col-12 ">
                    <div class="card">

                        <div class="card-body ">

                            <span class=" h2 font-weight-bold ">
                                Os critérios do Manual de Cálculo da Justiça Federal já estão pré-selecionados. Se não
                                houver necessidade de alterar juros ou indexador, clique em <i
                                    class="bi-arrow-right"></i> para continuar
                            </span>

                        </div>
                    </div>
                </div>
            </div>


            <form [formGroup]="processoForm">
                <div class="row ">
                    <div class="col-12 col-xxl-6">
                        <div class="row ">
                            <div class="col-12 d-flex ">
                                <div class=" card w-100">
                                    <div class=" card-header border-dark">
                                        <h5 class="mb-0">Indexadores</h5>
                                    </div>
                                    <div class=" card-body ">
                                        <div class="row">

                                            <div class="col-lg-12">
                                                

                                                <div class="row mt-auto">
                                                    <div class="col-12">
                                                        <label class="form-control-label"
                                                            for="selectCorrecaoMonetaria">Seleção do Critério de
                                                            Indexadores</label>

                                                        <ngx-select formControlName="protocoloCorrecaoMonetaria"
                                                            [items]="itemsSelectProtocoloCorrecao"
                                                            placeholder="Selecionar Indexadores"
                                                            (select)="selectItemCorrecao($event)"
                                                            (selectionChanges)="selectItemCorrecaoChanges($event)">
                                                        </ngx-select>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-12">
                                                <div
                                                    [className]="novaRegraCorrecaoVisivel ? 'visible py-4' : 'invisible'">
                                                    <app-nova-regra-atualizacao-monetaria [dataCalculo]="dataCalculo"
                                                        [dib]="dib"
                                                        (adicionarNovaRegraEvent)="adicionarNovaRegraCorrecao($event)"></app-nova-regra-atualizacao-monetaria>
                                                </div>
                                                <div class="dataTables_wrapper w-100 py-4">

                                                    <ngx-datatable class="material striped selection-cell"
                                                        [columnMode]="'force'" [headerHeight]="50"
                                                        [footerHeight]="'auto'" [rowHeight]="'auto'"
                                                        [rows]="linhasRegras"
                                                        [messages]="{emptyMessage: 'Use o botão para adicionar indexador por período', totalMessage: 'regras' }">
                                                        <ngx-datatable-column name="Indexador" prop="indice">

                                                        </ngx-datatable-column>
                                                        <ngx-datatable-column name="Data Inicial" prop="dataInicial">
                                                            <ng-template let-value="value" let-rowIndex="rowIndex"
                                                                ngx-datatable-cell-template>

                                                                <!-- <span *ngIf="dib <= (value | date : 'yyyy-MM-dd')">{{value | date : 'dd/MM/yyyy'}}</span> -->
                                                                <!-- <span *ngIf="dib  > (value | date : 'yyyy-MM-dd')">{{dib | date : 'dd/MM/yyyy'}} (DIB)</span> -->

                                                                <span
                                                                    *ngIf="(dib | date : 'yyyy-MM-dd')  <= (value | date : 'yyyy-MM-dd')">{{value
                                                                    | date : 'dd/MM/yyyy'}}</span>
                                                                <span
                                                                    *ngIf="(dib | date : 'yyyy-MM-dd') > (value | date : 'yyyy-MM-dd')">{{dib
                                                                    | date : 'dd/MM/yyyy'}} (DIB)</span>

                                                                <!-- <span *ngIf="dib  <= value">{{value | date : 'dd/MM/yyyy'}}</span>
                                              <span *ngIf="dib  > value">{{dib | date : 'dd/MM/yyyy'}} (DIB)</span> -->

                                                            </ng-template>
                                                        </ngx-datatable-column>

                                                        <ngx-datatable-column name="Data Final" prop="dataFinal">
                                                            <ng-template let-value="value" let-rowIndex="rowIndex"
                                                                ngx-datatable-cell-template>

                                                                <span
                                                                    *ngIf="(dataCalculo | date : 'yyyy-MM-dd') > (value | date : 'yyyy-MM-dd')">{{value
                                                                    | date : 'dd/MM/yyyy'}}</span>
                                                                <span
                                                                    *ngIf="(dataCalculo | date : 'yyyy-MM-dd')  <= (value | date : 'yyyy-MM-dd')">{{dataCalculo
                                                                    | date : 'dd/MM/yyyy'}} (Data Cálculo)</span>



                                                            </ng-template>
                                                        </ngx-datatable-column>




                                                    </ngx-datatable>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>

                    
                </div>




            </form>


        </div>



    </div>


</div>