<div class="row pb-2">
    <div class="col">
        <h5 class="mb-0 linha-quadro">Plano Indicado
            <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpMelhorCenario" aria-expanded="false" aria-controls="helpMelhorCenario"></i>
        </h5>


        <div class="collapse" id="helpMelhorCenario">

            <div class="row py-4 d-flex ">
        
                <div class="col">
                    <p>
                        O <strong>Calculei</strong> simula todas as possibilidades de aposentadoria com base nas suas
                        contribuições informadas e na frequência selecionada. Cada possibilidade é analisada como um
                        "caso" ou "plano de benefício". Para identificar o <strong>plano indicado</strong>, aplicamos os seguintes critérios:
                    </p>
                    <ol>
                        <li>
                            <strong>Comparação de benefícios:</strong> Apenas são considerados os planos cujo <mark>valor do benefício mensal estimado</mark> seja maior que o valor que poderia ser obtido com um saque equivalente em uma estratégia de capitalização.
                        </li>
                        <li>
                            <strong>Critério de otimização:</strong> Entre os planos restantes, escolhemos aquele que oferece o <mark>melhor equilíbrio</mark> entre:
                            <ul>
                                <li>O <mark>valor do benefício mensal estimado</mark>.</li>
                                <li>O <mark>retorno total sobre o investimento (ROI)</mark>.</li>
                                <li>
                                    O <mark>custo de renúncia</mark>: ou seja, o valor que você deixaria de receber ao optar por uma aposentadoria mais tardia, em vez de escolher uma possibilidade de aposentadoria que poderia ocorrer mais cedo.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        Este método garante que o plano indicado <mark>maximize os benefícios</mark> para você, considerando tanto os valores mensais recebidos quanto a eficiência do investimento ao longo do tempo.
                    </p>
                    <p>
                        <strong>Importante:</strong> o <mark>plano indicado</mark> é apenas uma <mark>sugestão</mark> baseada nos critérios descritos acima e não leva em conta <mark>fatores subjetivos</mark> ou preferências pessoais. Você pode <mark>alterar o plano indicado</mark> a qualquer momento, caso tenha outra preferência ou objetivo em mente.
                    </p>
                    <p>
                        Para alterar o plano indicado, clique no <mark>nome do plano</mark> desejado na tabela de planos e selecione a opção <strong><mark>"Escolher como plano indicado"</mark></strong>. O sistema irá atualizar automaticamente o plano escolhido para refletir sua decisão.
                    </p>
                    <p>
                        Se precisar de mais detalhes ou ajuda, entre em contato com nosso <mark>suporte</mark>.
                    </p>
                </div>
        
            </div>
        
        </div>

        <!-- <div class="collapse" id="helpMelhorCenario">

            <div class="row py-4 d-flex ">

                <div class="col">
                    <p>
                        O <strong>Calculei</strong> simula todas as possibilidades de aposentadoria com base nas suas
                        contribuições informadas e na frequência selecionada. Cada possibilidade é analisada como um
                        "caso" ou "plano de benefício". Para identificar o <strong>plano indicado</strong>, aplicamos
                        os seguintes critérios:
                    </p>
                    <ol>
                        <li>
                            <strong>Comparação de benefícios:</strong> Apenas são considerados os planos cujo valor do benefício mensal estimado seja maior que o valor que poderia ser obtido com um saque equivalente em uma estratégia de capitalização.
                        </li>
                        <li>
                            <strong>Critério de otimização:</strong> Entre os planos restantes, escolhemos aquele que oferece o melhor equilíbrio entre:
                            <ul>
                                <li>O valor do benefício mensal estimado.</li>
                                <li>O retorno total sobre o investimento (ROI).</li>
                                <li>
                                    O custo de renúncia: ou seja, o valor que você deixaria de receber ao optar por uma aposentadoria mais tardia, em vez de escolher uma possibilidade de aposentadoria que poderia ocorrer mais cedo.
                                </li>
                            </ul>
                        </li>
                    </ol>
                    <p>
                        Este método garante que o plano indicado maximize os benefícios para você, considerando tanto os valores mensais recebidos quanto a eficiência do investimento ao longo do tempo.
                    </p>
                    <p>
                        Se precisar de mais detalhes ou ajuda, entre em contato com nosso suporte.
                    </p>
                </div>

            </div>

        </div> -->
    </div>

</div>
<div class="row">
    <div class="col">

        <div class>

            <div class="row">
                <div class="col-4" role="button">
                    <div>
                        <div>
                            <p class="text-center fw-lighter h6 text-uppercase">Plano</p>
                        </div>
                        <p (click)="showCasoCanvas(caso)" role="button" class="display-1 lh-1 fw-lighter  mb-0">
                            {{caso.qualificacaoReferencia + ' +' + caso.totalContributivoComplementarMeses}}
                        </p>

                        <p class="display-4 lh-1 fw-lighter mb-0">
                            {{caso.tipoBeneficio | beneficio }}
                        </p>

                        <p class="lh-1 fw-lighter">
                            Regra
                        </p>

                        <a (click)="showCasoCanvas(caso)"> ver detalhes do plano</a>
                    </div>
                </div>
                <div class="col-4">
                    <div class="vstack">
                        <div class>
                            <div>
                                <p class="text-center fw-lighter h6 text-uppercase">Resultado do Plano</p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Data da Aposentadoria
                                </p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{caso.idadeDataAposentadoria.porExtenso}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Idade na Aposentadoria
                                </p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 mb-0">
                                    {{caso.valorBeneficio | currency}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Valor do Benefício
                                </p>
                            </div>
                        </div>
                        <hr>
                        <div *ngIf="caso.totalContributivoComplementarMeses > 0">
                            <div>
                                <p class="text-center fw-lighter h6 text-uppercase">Comparativo de Produto Financeiro</p>
                            </div>

                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{caso.totalContributivoCapitalizadoGanhoReal | currency}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Resultado Acumulado no Produto Financeiro
                                </p>
                            </div>

                            <div>
                                <div class="hstack">
                                    <!-- <div class="">
                                        <i class="bi bi-arrow-down"></i>

                                    </div> -->
                                    <div class>
                                        <p class="display-4 lh-1 fw-light mb-0">
                                            {{caso.valorSaqueCapitalizacao | currency}}
                                        </p>
                                        <p class="lh-1 fw-lighter">
                                            Benefício Mensal do Resultado Acumulado no Produto Financeiro
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="col-4">
                    <div>
                        <p class="text-center fw-lighter h6 text-uppercase">investimento no Plano</p>
                    </div>

                    <div *ngIf="caso.totalContributivoComplementarMeses > 0">
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% sobre {{caso.baseContributiva | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Alíquota e Salário Base
                        </p>
                    </div>

                    <div *ngIf="caso.totalContributivoComplementarMeses > 0">
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.valorContribuicaoComplementarMensal | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Valor da Contribuição
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.totalContributivoComplementarMeses}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Total de Contribuições
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.totalInvestimento | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Total do Investimento
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.retornoTotalInvestimentoRoi | currency}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Total de recebimento do benefício por {{caso.expectativaVida}} anos
                        </p>
                    </div>

                    <!-- <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.dataPayback | date : 'MM/yyyy'}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Data do Payback
                        </p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{caso.idadePayback.porExtenso}}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Idade no Payback
                        </p>
                    </div> -->

                </div>
            </div>

        </div>

    </div>

</div>