<h5 class="mb-0 linha-quadro">Minha assinatura</h5>


<ng-container *ngIf="assinatura; else elseTemplate">
    
    <div class="row g-3 py-4 flex d-flex">
        <div class="col-3">
            <p class="h2">
                Plano
            </p>
            <p class="text-default">
                {{assinatura.plano.nome}}
            </p>
        </div>

        <div class="col-3">
            <p class="h2">
                Recorrência
            </p>
            <p class="text-default">
                {{assinatura.plano.periodo}}
            </p>
        </div>

        <div class="col-3">
            <p class="h2">
                Valor por cobrança
            </p>
            <p class="text-default">
                {{assinatura.plano.valor | currency}}
            </p>
        </div>

        <div class="col-3">
            <p class="h2">
                Status
            </p>
            <p class=" badge text-default" [ngClass]="{'badge-success': assinatura.statusAssinatura === statusAssinaturaType.ATIVA,
             'badge-danger': assinatura.statusAssinatura === statusAssinaturaType.INADIMPLENTE}">
                {{assinatura.statusAssinatura | statusAssinatura}}
            </p>
        </div>

        <div class="col-3">
            <p class="h2">
                Vencimento
            </p>

            <p class="text-default">
                {{assinatura.vencimento | date : 'dd/MM/yyyy'}}
            </p>
            
        </div>


    </div>

</ng-container>
<ng-template #elseTemplate>
    
</ng-template>


