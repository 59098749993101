<div *ngIf="calculoMatrizPlanejamento &&  calculoMatrizPlanejamento.casos">
  <div class="row">
    <div class="col-12">

      <div class>
        <div class="row ">
          <div class="col">
            <h5 class="mb-0 linha-quadro">
              Tabela de Planos
              <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpCenarios" aria-expanded="false" aria-controls="helpCenarios"></i>
            </h5>

            <div class="collapse " id="helpCenarios">
              <div class="row py-4 d-flex ">
                <div class="col">
                  <p>
                    A <strong>Tabela de Planos</strong> apresenta uma visão detalhada de todos os cenários de aposentadoria simulados pelo
                    <strong>Calculei</strong>, listando informações completas de cada plano para facilitar a comparação. Diferentemente da
                    Matriz de Planos, a tabela organiza os dados em colunas e oferece <strong>rolagem lateral</strong> para exibir todos os planos.
                  </p>

                  <h3><strong>Estrutura da Tabela</strong></h3>
                  <ul>
                    <li>
                      <strong>Linhas:</strong> Cada linha representa uma métrica ou dado relevante, como data da aposentadoria, valor do benefício, custo do investimento, custo de oportunidade, entre outros.
                    </li>
                    <li>
                      <strong>Colunas:</strong> Cada coluna representa um plano de aposentadoria, identificado pelo nome que inclui a regra aplicada e o número de contribuições previstas.
                    </li>
                    <li>
                      <strong>Interatividade no nome do plano:</strong> Ao clicar no nome de qualquer plano, você pode:
                      <ul>
                        <li>Acessar o detalhamento completo do cálculo do plano.</li>
                        <li>Adicionar um marcador ou etiqueta colorida para destacar o plano na tabela.</li>
                      </ul>
                    </li>
                  </ul>

                  <h3><strong>Interatividade</strong></h3>
                  <ul>
                    <li>
                      <strong>Detalhamento do plano:</strong> Clique no nome de um plano para abrir um painel detalhado com as novas contribuições e a memória de cálculo.
                    </li>
                    <li>
                      <strong>Marcadores/etiquetas:</strong> Adicione uma etiqueta colorida para destacar planos de interesse.
                    </li>
                    <li>
                      <strong>Rolagem lateral:</strong> Use a rolagem lateral para visualizar todas as colunas disponíveis.
                    </li>
                  </ul>

                  <h3><strong>Como utilizar a Tabela de Planos?</strong></h3>
                  <ul>
                    <li>
                      <strong>Analise todas as informações:</strong> Compare os planos detalhadamente com base em critérios como retorno, custo, prazo e benefício.
                    </li>
                    <li>
                      <strong>Destaque os planos importantes:</strong> Use os marcadores para identificar rapidamente os planos que chamaram sua atenção.
                    </li>
                    <li>
                      <strong>Acesse os cálculos completos:</strong> Clique no nome de qualquer plano para visualizar as novas contribuições e o detalhamento completo.
                    </li>
                    <li>
                      <strong>Use a rolagem lateral:</strong> Deslize horizontalmente para acessar todos os planos disponíveis.
                    </li>
                  </ul>

                  <p>
                    Para mais detalhes ou dúvidas, entre em contato com nosso suporte.
                  </p>

                </div>

              </div>

              <div class="row d-flex ">

                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">

                      <p class=" p-2 lh-1 ">
                        Utilize os botões para modificar a visualização de planos. Clique nos valores, para fazer anotações.
                      </p>

                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <!-- <i class="comando bi bi-plus-square-dotted fs-6"></i> -->
                    </div>

                  </div>

                </div>

                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">

                      <p class=" p-2 lh-1 ">

                        Filtrar os planos por regra, alíquota ou salário de contribuição

                      </p>

                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-funnel fs-6"></i>
                    </div>

                  </div>

                </div>

                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">

                      <p class=" p-2 lh-1 ">

                        Mostrar ou esconder as tags

                      </p>

                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-bookmarks fs-6"></i>

                    </div>

                  </div>

                </div>

                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">

                      <p class=" p-2 lh-1 ">

                        Filtrar campos. Visualize apenas as informações mais relevantes de cada plano na tabela
                      </p>

                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-filter-square fs-6"></i>
                    </div>

                  </div>

                </div>

                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">

                      <p class=" p-2 lh-1 ">

                        Ordenar os planos por ordem alfabética

                      </p>

                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-sort-alpha-up fs-6"></i>

                    </div>

                  </div>

                </div>

                <div class="col-3 py-2 d-flex">
                  <div class="row bg-secondary rounded m-2 w-100">
                    <div class="col d-flex p-2">

                      <p class=" p-2 lh-1 ">

                        Ordenar os planos por valor do benefício

                      </p>

                    </div>
                    <div class="mt-auto pb-2 text-right">
                      <i class="comando bi bi-sort-numeric-up fs-6"></i>
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>

        </div>

        <div class="row py-2  flex d-flex justify-content-between">

          <div class="col-auto flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default" data-bs-toggle="collapse" data-bs-target="#areaFiltros" aria-expanded="false" aria-controls="areaFiltros" role="button" container="body" ngbTooltip="Mostrar filtros de planos">
                <i class="comando bi bi-funnel fs-6"></i>
                <i class="comando bi bi-funnel-fill fs-6 "></i>

              </a>
            </div>

            <div class="col-auto p-0 m-0">

              <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#marcadoresExibir" container="body" ngbTooltip="Mostrar marcadores">
                <a class id="marcadoresExibir"></a>
                <i class="comando bi bi-bookmarks fs-6 " (click)="marcadoresExibir(true)"></i>
                <i class="comando bi bi-bookmarks-fill fs-6 " (click)="marcadoresExibir(false)"></i>

              </a>
            </div>

            <div class="col-auto p-0 m-0">
              <a class="text-default " aria-expanded="false" data-bs-toggle="collapse" role="button" href="#areaCampos" container="body" ngbTooltip="Mostrar filtro de campos da tabela">
                <i class="comando bi bi-filter-square fs-6 " (click)="marcadoresExibir(true)"></i>
                <i class="comando bi bi-filter-square-fill fs-6 " (click)="marcadoresExibir(false)"></i>

              </a>

            </div>

            <div class="col-auto p-0 m-0">
              <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" role="button" href="#alphaFiltro" container="body" ngbTooltip="Ordem alfabética por nome dos planos">
                <a class id="alphaFiltro"></a>
                <i class="comando bi bi-sort-alpha-down fs-6 " (click)="ordenarAlpha(true)"></i>
                <i class="comando bi bi-sort-alpha-up fs-6 " (click)="ordenarAlpha(false)"></i>

              </a>

            </div>

            <div class="col-auto p-0 m-0">
              <a class="text-default" aria-expanded="false" data-bs-toggle="collapse" role="button" href="#valorBeneficioFiltro" container="body" ngbTooltip="Ordenar por valor de benefício">
                <a class id="valorBeneficioFiltro"></a>
                <i class="comando bi bi-sort-numeric-down fs-6 " (click)="ordernarValor(true)"></i>
                <i class="comando bi bi-sort-numeric-up fs-6 " (click)="ordernarValor(false)"></i>

              </a>
            </div>

          </div>

          <!-- <div class="col-auto flex d-flex">
          <div class="col-auto p-0 m-0">
            <a class="text-default " role="button" (click)="pdfMatriz()" container="body" ngbTooltip="Exportar tabela em PDF">
              <i class="comando bi bi-filetype-pdf fs-6 "></i>
            </a>
          </div>
    
          <div class="col-auto p-0 m-0">
            <a class="text-default " role="button" container="body" ngbTooltip="Exportar tabela em excel">
              <i class="comando bi bi-filetype-xls fs-6 "></i>
            </a>
          </div>
        </div> -->

        </div>
        <!-- campos -->
        <div class="collapse" id="areaCampos">
          <div class="row ">
            <div class="col-12">
              <p class="h3">
                Mostrar Campos
              </p>
            </div>
          </div>

          <div class="row m-0 p-0  flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="marcarTodosCampos()" container="body" ngbTooltip="Marcar todos">
                <i class="comando bi bi-ui-checks fs-6 "></i>
              </a>
            </div>

            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="limparTodosCampos()" container="body" ngbTooltip="Limpar todos">
                <i class="comando bi bi-grid fs-6 "></i>
              </a>
            </div>
          </div>
          <form [formGroup]="camposForm">
            <!-- campos -->
            <div class="row p-1">

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoAliquotaSalario">
                  <label class="form-check-label">
                    Alíquota / Salário
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoDataAposentadoria">
                  <label class="form-check-label">
                    Data da Aposentadoria
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoRegra">
                  <label class="form-check-label">
                    Regra
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoValorBeneficio">
                  <label class="form-check-label">
                    Valor do Benefício
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoTotalContribuicoes">
                  <label class="form-check-label">
                    Total de Contribuições
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoValorContribuicao">
                  <label class="form-check-label">
                    Valor da Contribuição
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoTotalInvestimento">
                  <label class="form-check-label">
                    Total do Investimento
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoRoiAcumulado">
                  <label class="form-check-label">
                    Expectativa de Retorno
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoTaxaRetorno">
                  <label class="form-check-label">
                    Taxa de Retorno
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoGanhoRoi">
                  <label class="form-check-label">
                    Ganho no ROI
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="campoPercentualTeto">
                  <label class="form-check-label">
                    Percentual do Teto do INSS
                  </label>
                </div>
              </div>

            </div>
          </form>
        </div>

        <!-- filtros -->
        <div class="collapse" id="areaFiltros">
          <div class="row ">
            <div class="col-12">
              <p class="h3">
                Filtros
              </p>
            </div>
          </div>

          <div class="row m-0 p-0  flex d-flex">
            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="marcarTodosFiltros()" container="body" ngbTooltip="Marcar todos">
                <i class="comando bi bi-ui-checks fs-6 "></i>
              </a>
            </div>

            <div class="col-auto p-0 m-0">
              <a class="text-default " role="button" (click)="limparTodosFiltros()" container="body" ngbTooltip="Limpar todos">
                <i class="comando bi bi-grid fs-6 "></i>
              </a>
            </div>
          </div>

          <form [formGroup]="filtrosForm">
            <!-- regras -->
            <div class="row">
              <div class="col-1">
                <p class="h3">
                  Regras:
                </p>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraIdadeTransicao">
                  <label class="form-check-label">
                    Idade
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraPedagio100">
                  <label class="form-check-label">
                    Pedágio 100%
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraPedagio50">
                  <label class="form-check-label">
                    Pedágio 50%
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraIdadeMinimaProgressiva">
                  <label class="form-check-label">
                    TC + Idade Mínima
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroRegraPontos">
                  <label class="form-check-label">
                    Pontos
                  </label>
                </div>
              </div>
            </div>

            <!-- aliquota -->
            <div class="row ">
              <div class="col-1">
                <p class="h3">
                  Alíquota:
                </p>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroAliquota11">
                  <label class="form-check-label">
                    11%
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroAliquota20">
                  <label class="form-check-label">
                    20%
                  </label>
                </div>
              </div>

            </div>
            <!-- salario -->
            <div class="row">
              <div class="col-1">
                <p class="h3">
                  Salário:
                </p>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroSalarioMinimo">
                  <label class="form-check-label">
                    Salário Mïnimo
                  </label>
                </div>
              </div>

              <div class="col-auto">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" formControlName="filtroSalarioTeto">
                  <label class="form-check-label">
                    Teto
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

    </div>
  </div>

  <!-- tabela -->
  <div class="row">
    <div class="col-12">
      <div class="table-wrapper">
        <div class="table-responsive">

          <table class="table table-bordered table-sm   table-striped  table-hover align-middle overflow-auto">
            <thead>

              <tr>
                <td class="cabecalho text-center">
                  <!-- <span>
                    Plano
                  </span> -->
                </td>

                <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                  <ng-container *ngIf="!caso.filtrarMatriz">
                    <td class="plano">

                      <div class="vstack">
                        <div class="h3">
                          <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                            {{caso.referenciaMatriz}}
                          </span>

                          <div class="dropdown">
                            <div class="dropdown-menu" [id]="'opcoesCenario'+i">
                              <div class="container">
                                <div class="row pb-4">
                                  <div class="col">
                                    <span class="h1 fw-lighter text-default" role="button ">
                                      {{caso.referenciaMatriz}}
                                    </span>
                                  </div>
                                  <div class="col-auto">
                                    <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCenario'+i)"></i>
                                  </div>
                                </div>

                                <div class="py-2">
                                  <div class="row">
                                    <div class="col">
                                      <p class="h3">Abrir o cálculo do plano</p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col">
                                      <i class="comando bi bi-plus-slash-minus" role="button" (click)="abrirPlano(caso)"></i>

                                    </div>
                                  </div>
                                </div>

                                <div class="py-2">
                                  <div class="row">
                                    <div class="col">
                                      <p class="h3">Escolher como plano indicado</p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col">
                                      <i class="comando bi bi-check2" role="button" (click)="escolherPlanoIndicado(caso, 'opcoesCenario'+i)"></i>

                                    </div>
                                  </div>
                                </div>

                                

                                <div class="py-2">
                                  <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'CASO')">
                                  </app-add-marcador>
                                </div>

                                <div class="py-2">
                                  <div class="row">
                                    <div class="col">
                                      <p class="h3">Esconder plano</p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col">
                                      <i class="comando bi bi-eye-slash" role="button" (click)="esconderCenario(caso, 'opcoesCenario'+i)"></i>
                                      <p class="h6">
                                        Remover o plano da matriz
                                      </p>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>

                        </div>
                      </div>

                    </td>

                  </ng-container>
                </ng-container>

              </tr>

            </thead>
            <tbody>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.aliquotaSalario">

                  <th class="cabecalho">
                    Alíquota / Salário
                  </th>

                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoAliquota'+ i" [style.backgroundColor]=" caso.aliquotaContributivaMarcadores | tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          <span [ngSwitch]="caso.baseContributiva" class="p-0 m-0">
                            <span *ngSwitchCase="calculoMatrizPlanejamento.tetoInss">
                              <span> {{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% / Teto</span>
                            </span>
                            <span *ngSwitchCase="calculoMatrizPlanejamento.salarioMinimo">
                              <span>{{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% / Sal. Mín</span>
                            </span>
                            <span *ngSwitchDefault>
                              <span>{{caso.aliquotaContributiva * 100 | number : '1.0-2'}}% / {{caso.baseContributiva | currency}} </span>
                            </span>
                          </span>

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoAliquota'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo alíquota / salário
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAliquota'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ALIQUOTA')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ALIQUOTA', 'campoAliquota'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.aliquotaContributivaMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ALIQUOTA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria">
                  <th class="cabecalho">
                    Data da Aposentadoria
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoDataAposentadoria'+ i" [style.backgroundColor]=" caso.dataAposentadoriaMarcadores| tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.dataAposentadoria | date : 'dd/MM/yyyy'}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoDataAposentadoria'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Data da Aposentadoria
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoDataAposentadoria'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'DATAAPOSENTADORIA')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'DATAAPOSENTADORIA', 'campoDataAposentadoria'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.dataAposentadoriaMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1 text-white" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'DATAAPOSENTADORIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.dataAposentadoria">
                  <th class="cabecalho">
                    Expectativa de vida após a data da aposentadoria
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoDataAposentadoria'+ i" [style.backgroundColor]=" caso.dataAposentadoriaMarcadores| tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.expectativaVida}} anos

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoDataAposentadoria'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Data da Aposentadoria
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoDataAposentadoria'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'DATAAPOSENTADORIA')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'DATAAPOSENTADORIA', 'campoDataAposentadoria'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <!-- <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.dataAposentadoriaMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1 text-white" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'DATAAPOSENTADORIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span> -->

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.regra">
                  <th class="cabecalho">
                    Regra
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoRegra'+ i" [style.backgroundColor]=" caso.qualificacaoReferenciaMarcadores| tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.qualificacaoReferencia}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoRegra'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Regra
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoRegra'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'QUALIFICACAOREFERENCIA')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'QUALIFICACAOREFERENCIA', 'campoRegra'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.qualificacaoReferenciaMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'QUALIFICACAOREFERENCIA')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>

                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorBeneficio">
                  <th class="cabecalho">
                    Valor do Benefício
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoValorBeneficio'+ i" [style.backgroundColor]=" caso.valorBeneficioMarcadores | tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.valorBeneficio | currency}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoValorBeneficio'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Valor do Benefício
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoValorBeneficio'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'VALORBENEFICIO')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'VALORBENEFICIO', 'campoValorBeneficio'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.valorBeneficioMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'VALORBENEFICIO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalContribuicoes">
                  <th class="cabecalho">
                    Total de Novas Contribuições
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoTotalContribuicoes'+ i" [style.backgroundColor]=" caso.totalContributivoComplementarMesesMarcadores | tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.totalContributivoComplementarMeses}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoTotalContribuicoes'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Total de Contribuições
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTotalContribuicoes'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES', 'campoTotalContribuicoes'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                      <!-- <td>
  
                      <span>
                        
                      </span>
                      <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                        <span *ngFor="let marcador of caso.totalContributivoComplementarMesesMarcadores">
                          <span class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALCONTRIBUTIVOCOMPLEMENTARMESES')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                        </span>
                      </span>
  
                   
                    </td> -->

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.valorContribuicao">
                  <th class="cabecalho">
                    Valor da Contribuição
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoValorContribuicao'+ i" [style.backgroundColor]=" caso.valorContribuicaoComplementarMensalMarcadores |  tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.valorContribuicaoComplementarMensal | currency}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoValorContribuicao'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Valor da Contribuição
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoValorContribuicao'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL', 'campoValorContribuicao'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.valorContribuicaoComplementarMensalMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'VALORCONTRIBUICAOCOMPLEMENTARMENSAL')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento">
                  <th class="cabecalho">
                    Total do Investimento
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoTotalInvestimento'+ i" [style.backgroundColor]=" caso.totalInvestimentoMarcadores |  tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.totalInvestimento | currency}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoTotalInvestimento'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Total do Investimento
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTotalInvestimento'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALINVESTIMENTO')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALINVESTIMENTO', 'campoTotalInvestimento'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.totalInvestimentoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALINVESTIMENTO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.totalInvestimento">
                  <th class="cabecalho">
                    Custo de oportunidade do plano mais rápido
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoCustoRenunciaCenarioDataAposentadoriaMaisCedo'+ i">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.custoRenunciaCenarioDataAposentadoriaMaisCedo | currency}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoCustoRenunciaCenarioDataAposentadoriaMaisCedo'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.custoRenunciaCenarioDataAposentadoriaMaisCedo}} opções campo Custo de oportunidade do plano mais rápido
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoCustoRenunciaCenarioDataAposentadoriaMaisCedo'+i)"></i>
                                </div>
                              </div>

                              <!-- <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TOTALINVESTIMENTO')">
                                </app-add-marcador>
                              </div> -->

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TOTALINVESTIMENTO', 'campoTotalInvestimento'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <!-- <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.totalInvestimentoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TOTALINVESTIMENTO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span> -->

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.roiAcumulado">
                  <th class="cabecalho">
                    Expectativa de Retorno
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoRoiAcumulado'+ i" [style.backgroundColor]=" caso.retornoTotalInvestimentoRoiMarcadores |  tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.retornoTotalInvestimentoRoi | currency}}

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoRoiAcumulado'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo ROI Acumulado
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoRoiAcumulado'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'RETORNOTOTALINVESTIMENTOROI')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'RETORNOTOTALINVESTIMENTOROI', 'campoRoiAcumulado'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.retornoTotalInvestimentoRoiMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'RETORNOTOTALINVESTIMENTOROI')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <!-- <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.taxaRetorno">
                  <th class="">Taxa de Retorno</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
  
                      <td [id]="'campoTaxaRetorno'+ i" [style.backgroundColor]=" caso.taxaRetornoPlanejamentoContributivoMarcadores |  tintaFundo ">
  
                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.taxaRetornoPlanejamentoContributivo * 100 | number : '1.2-2'}} %
  
                        </span>
  
                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoTaxaRetorno'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Taxa de Retorno
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoTaxaRetorno'+i)"></i>
                                </div>
                              </div>
  
                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO')">
                                </app-add-marcador>
                              </div>
  
                              <div class="py-1">
                                <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO', 'campoTaxaRetorno'+ i )">
  
                                </app-add-tinta>
                              </div>
  
                            </div>
                          </div>
  
                        </div>
  
                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.taxaRetornoPlanejamentoContributivoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TAXARETORNOPLANEJAMENTOCONTRIBUTIVO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>
  
                      </td>
  
                    </ng-container>
                  </ng-container>
  
                </ng-container>
  
              </tr> -->

              <!-- <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.payback">
                  <th class="">Payback</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
  
                      <td [id]="'campoPayback'+ i" [style.backgroundColor]=" caso.tempoRetornoInvestimentoMesesAbonoMarcadores |  tintaFundo ">
  
                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.tempoRetornoInvestimentoMesesAbono | number : '1.0-0'}} meses
  
                        </span>
  
                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoPayback'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Payback
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoPayback'+i)"></i>
                                </div>
                              </div>
  
                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'TEMPORETORNOINVESTIMENTOMESESABONO')">
                                </app-add-marcador>
                              </div>
  
                              <div class="py-1">
                                <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'TEMPORETORNOINVESTIMENTOMESESABONO', 'campoPayback'+ i )">
  
                                </app-add-tinta>
                              </div>
  
                            </div>
                          </div>
  
                        </div>
  
                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.tempoRetornoInvestimentoMesesAbonoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'TEMPORETORNOINVESTIMENTOMESESABONO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>
  
                      </td>
  
                    </ng-container>
                  </ng-container>
  
                </ng-container>
  
              </tr> -->

              <!-- <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.ganhoRoi">
                  <th class="">Ganho no ROI</th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">
  
                      <td [id]="'campoGanhoRoi'+ i" [style.backgroundColor]=" caso.lucroPlanejamentoContributivoMarcadores |  tintaFundo ">
  
                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{caso.lucroPlanejamentoContributivo | currency}}
  
                        </span>
  
                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesGanhoRoi'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo Ganho ROI
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesGanhoRoi'+i)"></i>
                                </div>
                              </div>
  
                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'LUCROPLANEJAMENTOCONTRIBUTIVO')">
                                </app-add-marcador>
                              </div>
  
                              <div class="py-1">
                                <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'LUCROPLANEJAMENTOCONTRIBUTIVO', 'campoGanhoRoi'+ i )">
  
                                </app-add-tinta>
                              </div>
  
                            </div>
                          </div>
  
                        </div>
  
                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.lucroPlanejamentoContributivoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'LUCROPLANEJAMENTOCONTRIBUTIVO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>
  
                      </td>
  
                    </ng-container>
                  </ng-container>
  
                </ng-container>
  
              </tr> -->

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.percentualTeto">
                  <th class="cabecalho">
                    % Teto do INSS
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoPercentualTeto'+ i" [style.backgroundColor]=" caso.percentualTetoInssMarcadores |  tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">
                          {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}%

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoPercentualTeto'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">
                                    {{caso.referenciaMatriz}} opções campo % Teto do INSS
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoPercentualTeto'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'PERCENTUALTETOINSS')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'PERCENTUALTETOINSS', 'campoPercentualTeto'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.percentualTetoInssMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'PERCENTUALTETOINSS')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                  <th class="cabecalho">
                    Resultado Produto Financeiro
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoAcumuladoCapitalizacao'+ i" [style.backgroundColor]=" caso.acumuladoCapitalizacaoMarcadores |  tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">

                          {{caso.taxaRetornoPrivadoAno * 100 | number :'1.2-2'}}% / {{caso.totalContributivoCapitalizadoGanhoReal | currency }}
                          <!-- {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}% -->

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoAcumuladoCapitalizacao'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">

                                    {{caso.referenciaMatriz}} opções campo Resultado Produto Financeiro
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAcumuladoCapitalizacao'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ACUMULADOCAPITALIZACAO')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ACUMULADOCAPITALIZACAO', 'campoAcumuladoCapitalizacao'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ACUMULADOCAPITALIZACAO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

              <tr>
                <ng-container *ngIf="calculoMatrizPlanejamento.opcoesMatriz.campos.acumuladoCapitalizacao">
                  <th class="cabecalho">
                    Benefício Mensal no Produto Financeiro
                  </th>
                  <ng-container class *ngFor="let caso of calculoMatrizPlanejamento.casos; index as i;">
                    <ng-container *ngIf="!caso.filtrarMatriz">

                      <td [id]="'campoAcumuladoCapitalizacao'+ i" [style.backgroundColor]=" caso.acumuladoCapitalizacaoMarcadores |  tintaFundo ">

                        <span role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="false">

                          {{caso.valorSaqueCapitalizacao | currency }}
                          <!-- {{(caso.valorBeneficio * 100) / calculoMatrizPlanejamento.tetoInss | number : '1.0-0' }}% -->

                        </span>

                        <div class="dropdown">
                          <div class="dropdown-menu" [id]="'opcoesCampoAcumuladoCapitalizacao'+i">
                            <div class="container">
                              <div class="row py-1">
                                <div class="col">
                                  <span role="button">

                                    {{caso.referenciaMatriz}} opções campo Resultado Acumulado no Produto Financeiro
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <i class="bi bi-x-lg" role="button" (click)="fecharDrop('opcoesCampoAcumuladoCapitalizacao'+i)"></i>
                                </div>
                              </div>

                              <div class="py-1">
                                <app-add-marcador (addMarcadorEvent)="addMarcador(caso, $event, 'ACUMULADOCAPITALIZACAO')">
                                </app-add-marcador>
                              </div>

                              <div class="py-1">
                                <!-- <app-add-tinta (addTintaEvent)="addTinta(caso, $event, 'ACUMULADOCAPITALIZACAO', 'campoAcumuladoCapitalizacao'+ i )">
  
                                </app-add-tinta> -->
                              </div>

                            </div>
                          </div>

                        </div>

                        <span *ngIf="calculoMatrizPlanejamento.opcoesMatriz.exibirMarcadores">
                          <span *ngFor="let marcador of caso.acumuladoCapitalizacaoMarcadores">
                            <span *ngIf="marcador.tipoMarcador != tiposMarcadorType.TINTAFUNDO" class="badge rounded-pill mx-1" [style.backgroundColor]="marcador.cor">{{marcador.nome}} <i (click)="excluirMarcador(caso, marcador, 'ACUMULADOCAPITALIZACAO')" role="button" class=" mx-1 bi bi-x-lg"></i> </span>
                          </span>
                        </span>

                      </td>

                    </ng-container>
                  </ng-container>

                </ng-container>

              </tr>

            </tbody>
          </table>

        </div>

      </div>

    </div>
  </div>
</div>