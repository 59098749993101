<div class="row pb-2">
    <div class="col">
        <h5 class="mb-0 linha-quadro"></h5>
        <div class="row">
            <div class="col-6">
                <h5 class="mb-0">Histórico de CNIS
                    <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpQuadroHistoricoCnis" aria-expanded="false" aria-controls="helpQuadroHistoricoCnis"></i>
                </h5>
            </div>
            <div class="col-6">
                <div class="text-right">
                    <div class="row justify-content-end">
                        <div class="col-3">
                            <app-aguarde-local [aguarde]="aguarde"></app-aguarde-local>
                        </div>
                    </div>
                    <div *ngIf="listaLeituraCnis && listaPronta && !(listaLeituraCnis.length == 0)">
                        <i class="comando bi bi-trash" role="button" (click)="excluirTodosCNIS()" container="body" ngbTooltip="Excluir todos CNIS" triggers="hover"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="collapse" id="helpQuadroHistoricoCnis">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Sempre que você importar um CNIS ele vai ficar guardado nesta área. Da próxima vez, você pode abrir o CNIS diretamente aqui do histórico, mesmo sem o arquivo original.
                            </p>

                        </div>
                        <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-clock-history fs-3"></i>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>

<div class="py-4">
    <div [className]="!listaPronta || aguarde ? 'visible py-4' : 'invisible py-4'">
        <div class="d-flex justify-content-center">

            <app-aguarde-config [aguarde]="!listaPronta"></app-aguarde-config>

        </div>
    </div>

    <div *ngIf="listaLeituraCnis && listaPronta">
        <div *ngIf="listaLeituraCnis.length == 0" class="row">
            <div class="col-12 justify-items-center text-center">
                <span>Está vazio por aqui <i class="bi bi-moon-stars"></i></span>

            </div>
        </div>

        <div [className]="!aguarde ? 'visible' : 'invisible'">
            <div class="row d-flex ">

                <ng-container *ngFor="let leitura of listaLeituraCnis">

                    <div class="col-4 py-2 d-flex">
                        <div class="row w-100 bg-secondary rounded d-flex flex-column m-2  p-2" role="button" (click)="cardClick(leitura)">

                            <div class="col pt-2">

                                <div class="vstack">
                                    <div class>
                                        <ng-container *ngIf="usuario && usuario.configuracao.anonimo">
                                            <span class="fs-6 fw-light">{{leitura?.cnis?.identificacaoFiliado?.nome | anonimo}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="usuario && !usuario.configuracao.anonimo">
                                            <span class="fs-6 fw-light">{{leitura?.cnis?.identificacaoFiliado?.nome}}</span>
                                        </ng-container>
                                    </div>
                                    <div>
                                        <span class="fs-7 ">{{leitura.nome}}</span>
                                    </div>
                                </div>

                                <p class="fs-8 fw-lighter">{{leitura.dataUltimaModificacao | date }} </p>

                            </div>
                            <div class="col align-content-end text-right">
                                <i class="bi bi-trash h6 fw-lighter " role="button" (click)="excluirCNIS(leitura)"></i>
                            </div>

                        </div>

                    </div>

                </ng-container>

            </div>

        </div>

        

    </div>
</div>
