<div *ngIf="beneficio">
    <h5 class="mb-0 linha-quadro pb-4">Plano de Aposentadoria</h5>
    <div class="row m-0 p-0">
        

        <div class="col-12">
          <p class="h1">
            {{beneficio.qualificacaoReferencia}}
          </p>
        </div>
        <div class="col-12 flex d-flex align-items-top">
          
          <div class="col-auto px-0 mx-0 align-items-center ">
            
            <p class="h6 lh-1 mb-0">
                {{beneficio.qualificacaoDetalhada}}
              </p>
              <p class="h6 lh-1 mb-0">
                {{beneficio.qualificacao}}
              </p>
            

          </div>
          <!-- <div class="col-auto">
            <div [ngSwitch]="statusBeneficioType[beneficio.status]">
              <span *ngSwitchCase="statusBeneficioType.Apto" class="badge bg-success rounded-pill text-default px-4 mr-4">{{beneficio.status | statusBeneficio }} {{beneficio.dataCalculo | date : 'dd/MM/yyyy'}}</span>
              <span *ngSwitchCase="statusBeneficioType.Inapto" class="badge bg-danger rounded-pill text-default px-4 mr-4"> {{beneficio.status | statusBeneficio }} {{beneficio.dataCalculo | date : 'dd/MM/yyyy'}}</span>

            </div>

          </div> -->
        </div>
        
        <div class="col-12">
           

            <div class="row justify-content-end">
                <div class="col-auto py-3 ">

                    <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                        {{beneficio.relatorioRendaMensalInicial.rendaMensalInicial | currency}}

                    </h1>
                    <span class=" display-4 font-weight-lighter  mb-0 text-default">
                        Renda Mensal Inicial
                    </span>

                </div>

                <div class="col-auto  py-3 ">

                    <h1 class=" display-2 card-title text-capitalize mb-0 text-default">

                        {{beneficio.dataCalculo | date : 'dd/MM/yyyy'}}

                    </h1>
                    <span class=" display-4 font-weight-lighter  mb-0 text-default">
                        Data Simulação
                    </span>

                </div>

            </div>

            

            <!-- <div class="row">

                

                <div class="col-4  py-4" >
                    

                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="(beneficio.relacaoIdadeCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">
                            
                        </div>
                    </div>

                    <div class="row justify-content-between">


                        <div class="col-auto ">

                            <span class="display-4 font-weight-bold mb-0 text-default">
                                Idade
                            </span>
                            <p>
                                Faltam: {{beneficio.idadeComplementar.porExtenso}}
                            </p>

                        </div>
                        <div class="col-auto text-right">
                            <p class="display-2">
                                {{beneficio.relacaoIdadeCarencia > 100 ? 100 : beneficio.relacaoIdadeCarencia | number : '1.0-0'}}%
                            </p>

                        </div>
                    </div>

                </div>

                <div class="col-4 py-4">
                    

                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="(beneficio.relacaoTempoContribuicaoCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">
                            
                        </div>
                    </div>
                    <div class="row justify-content-between">

                        <div class="col-auto">

                            <span class="display-4 font-weight-bold mb-0 text-default">
                                Tempo de Contribuição
                            </span>
                            <p>
                                Faltam {{beneficio.tempoContribuicaoComplementar.porExtenso}}
                            </p>

                        </div>
                        <div class="col-auto text-right">
                            <p class="display-2">
                                {{beneficio.relacaoTempoContribuicaoCarencia > 100 ? 100 : beneficio.relacaoTempoContribuicaoCarencia | number : '1.0-0'}}%
                            </p>

                        </div>
                        
                    </div>
                </div>

                <div class="col-4  py-4 " *ngIf="beneficio.pontos <= 0">

                    
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="(beneficio.relacaoCarenciaMeses > 100 ? 100 : beneficio.relacaoCarenciaMeses | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">
                            
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-auto">

                            <span class="display-4 font-weight-bold mb-0 text-default">
                                Carência
                            </span>
                            <p>
                                Faltam: {{beneficio.carenciaComplementar.porExtenso}}
                            </p>

                        </div>
                        <div class="col-auto text-right">
                            <p class="display-2">
                                {{beneficio.relacaoCarenciaMeses > 100 ? 100 : beneficio.relacaoCarenciaMeses | number : '1.0-0'}}%
                            </p>

                        </div>
                    </div>

                </div>

                <div class="col-4 py-4" *ngIf="beneficio.pontos > 0">
                    
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" [style.width]="(beneficio.relacaoPontosCarencia | number : '1.0-0') + '%'" aria-valuemin="0" aria-valuemax="100">
                            
                        </div>
                    </div>
                    <div class="row justify-content-between">

                        <div class="col-auto">

                            <span class="display-4 font-weight-bold mb-0 text-default">
                                Pontos
                            </span>
                            <p>
                                Faltam: {{beneficio.pontosComplementares}} pontos
                            </p>

                        </div>
                        <div class="col-auto text-right">
                            <p class="display-2">
                                {{beneficio.relacaoIdadeCarencia > 100 ? 100 : beneficio.relacaoPontosCarencia | number : '1.0-0'}}%
                            </p>

                        </div>


                        
                    </div>

                </div>

                

            </div> -->

        </div>

    </div>
</div>