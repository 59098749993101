<div class="row pb-2">
    <div class="col">
        <h5 class="mb-0 linha-quadro">Resultado Financeiro do Benefício
            <i class="bi bi-person-raised-hand" type="button" data-bs-toggle="collapse" data-bs-target="#helpMelhorCenario" aria-expanded="false" aria-controls="helpMelhorCenario"></i>
        </h5>

        <div class="collapse" id="helpMelhorCenario">

            <div class="row py-4 d-flex ">

                <div class="col-3 py-2 d-flex">
                    <div class="row w-100 bg-secondary rounded m-2">
                        <div class="col d-flex p-2">

                            <p class=" p-2 lh-1 text-justify">
                                Retorno financeiro do investimento no benefício de aposentadoria do INSS
                            </p>

                        </div>
                        <!-- <div class="mt-auto pb-2 text-right">
                            <i class="comando bi bi-cash-coin fs-3"></i>
                        </div> -->

                    </div>

                </div>

            </div>

        </div>
    </div>

</div>
<div class="row" >
    <div class="col">

        <div class="">
            
            <div class="row">
                <div class="col-4">
                    <div>
                        <div>
                            <p class="text-center fw-lighter h6 text-uppercase">Benefício</p>
                        </div>
                        <p class="display-1 lh-1  mb-0">
                            {{relatorio.otimizacao.valorBeneficio | currency }}
                        </p>
                        
                        <p class="lh-1 fw-lighter">
                            Valor mensal do benefício
                        </p>
                    </div>
                </div>
                <div class="col-4">
                    <div class="vstack">
                        <div class="">
                            <div>
                                <p class="text-center fw-lighter h6 text-uppercase">Estimativa de Lucro</p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{relatorio.otimizacao.valorBeneficioAbonoAnual | currency}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Valor mensal do benefício com abono anual
                                </p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{relatorio.otimizacao.expectativaVida | number : '1.1-1'}} anos / {{relatorio.otimizacao.expectativaVida * 12 | number : '1.0-0'}} meses
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Expectativa de sobrevida (IBGE)
                                </p>
                            </div>
                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{relatorio.otimizacao.valorBeneficioAbonoAnual * relatorio.otimizacao.expectativaVida * 12 | currency }}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Estimativa de retorno a longo prazo
                                </p>
                            </div>

                            <div>
                                <p class="display-4 lh-1 fw-light mb-0">
                                    {{relatorio.otimizacao.retornoTotalInvestimentoRoi | currency}}
                                </p>
                                <p class="lh-1 fw-lighter">
                                    Estimativa de retorno Líquido
                                </p>
                            </div>
                        </div>
                       
                    </div>

                </div>
                <div class="col-4">
                    <div>
                        <p class="text-center fw-lighter h6 text-uppercase">investimento benefício INSS</p>
                    </div>

                    <div>
                        <p class="display-4 lh-1 fw-light mb-0">
                            {{relatorio.otimizacao.totalInvestimento | currency }}
                        </p>
                        <p class="lh-1 fw-lighter">
                            Investimento em novas contribuições de otimização
                        </p>
                    </div>

                    
                    
                    

                </div>
            </div>

        </div>

    </div>

</div>