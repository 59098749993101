<div class="row">
  <div class="col-12">
    <h5 class="mb-0 linha-quadro">Mapa da Qualidade de Segurado</h5>
  </div>

  <div *ngIf="visualizarPdf" class="col-12 d-flex flex-row-reverse">
    <i class="bi-filetype-pdf h1 mb-0 clicavel" (click)="gerarPDF()"></i>
    <div *ngIf="aguardePdf" class="spinner-border  mx-2">
    </div>
  </div>
</div>

<div class="row">
  <!-- Chart wrapper -->
  <div id="wrapper" style="position: relative; height: 50vh">
    <canvas id="mapaQualidadeSegurado"></canvas>
  </div>
</div>