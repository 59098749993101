import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { CnisListagemComponent } from 'src/app/aplicacao/dashboard/compartilhado-dashboard/cnis-listagem/cnis-listagem.component';
import { ImportadorCNISComponent } from '../compartilhado/importador-cnis/importador-cnis.component';
import { EditorCNISComponent } from '../compartilhado/editor-cnis/editor-cnis.component';
import { GruposSumarioCalculoBeneficiosComponent } from '../compartilhado/grupos-sumario-calculo-beneficios/grupos-sumario-calculo-beneficios.component';
import { CalculoBeneficios } from 'src/app/modelos/previdenciario/calculobeneficios';
import { Beneficio } from 'src/app/modelos/previdenciario/beneficio';
import { CNIS } from 'src/app/modelos/previdenciario/cnis';
import Stepper from 'bs-stepper';
import { DatePipe, Location } from '@angular/common';
import { ListarCalculoResultadoService } from 'src/app/servicos/calculo/common/listar-calculo-resultado.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/servicos/ui/config.service';
import { ListarGruposSumarioCalculoBeneficiosService } from 'src/app/servicos/calculo/previdenciario/listar-grupos-sumario-calculo-beneficios.service';
import { NotificacoesService } from 'src/app/servicos/ui/notificacoes.service';
import { ListarRelatorioRendaMensalInicialPdfService } from 'src/app/servicos/relatorio/listar-relatorio-renda-mensal-inicial-pdf.service';
import { ListarDadosArquivoDataService } from 'src/app/servicos/calculo/common/listar-dados-arquivo-data.service';
import { ListarPDFService } from 'src/app/servicos/relatorio/listar-pdf.service';
import { CalculoTipos } from 'src/app/modelos/common/calculotipos';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { LeituraCNIS } from 'src/app/modelos/previdenciario/leituracnis';

@Component({
  selector: 'app-invalidez',
  templateUrl: './invalidez.component.html',
  styleUrl: './invalidez.component.scss'
})
export class InvalidezComponent {

  @ViewChild('seletorCnis', { static: false }) seletorCnis?: CnisListagemComponent;
  @ViewChild('tabsCalculoBeneficios', { static: false }) tabs?: TabsetComponent;
  @ViewChild('importadorCNIS') importadorCNIS: ImportadorCNISComponent;
  @ViewChild('editorCNIS') editorCNIS: EditorCNISComponent;
  @ViewChild('gruposSumarioBeneficios') gruposSumarioBeneficios: GruposSumarioCalculoBeneficiosComponent;


  calculoBeneficios: CalculoBeneficios;

  beneficioSelecionado: Beneficio;

  
  
  

  //usado para atualizar ou nao o grupo sumario depois da navegacao voltar
  //identifica se houve alteracao no cnis para calcular novamente
  cnisEditado: CNIS;
  dataCalculoEditada: Date;
  calculoArquivo: boolean;

  titulo: string;

  private stepper: Stepper;
  aguardeConfig: boolean;
  aguardeResultado: boolean;
  atualizarCalculoCnisModificado: boolean;

  constructor(private location: Location, private listarCalculoResultadoService: ListarCalculoResultadoService,
    private router: Router,
    private configService: ConfigService,
    private listarGruposSumarioCalculoBeneficiosService: ListarGruposSumarioCalculoBeneficiosService,
    private datePipe: DatePipe, private changeDetector: ChangeDetectorRef,
    private notificacoesService: NotificacoesService,
    private listarRelatorioRendaMensalInicialPdfService: ListarRelatorioRendaMensalInicialPdfService,
    private listarDadosArquivoDataService: ListarDadosArquivoDataService,
    private listarPDFService: ListarPDFService) {



  }


  ngAfterViewChecked(): void {
    //ng0100: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //erro quando coloca retorno do evento edicaocnisfinalizada no objeto calculo
    this.changeDetector.detectChanges();
  }


  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.



    if (this.location.getState().hasOwnProperty('calculo')) {
      this.configService.setAguarde(true);
      this.calculoBeneficios = this.location.getState()['calculo'] as CalculoBeneficios;
      // this.carregarDados(this.calculoBeneficios);
      this.configService.setAguarde(false);
      this.stepper.to(3);


    }
    else {
      this.calculoBeneficios = new CalculoBeneficios();
      this.calculoBeneficios.data = new Date();
      this.calculoBeneficios.dataCalculo =  new Date();
      this.calculoBeneficios.nomeCalculo = "Novo cálculo aposentadoria por invalidez";
      this.calculoBeneficios.tipoCalculo = CalculoTipos.INVALIDEZ;
      this.configService.getUsuario().subscribe(usuario => this.calculoBeneficios.usuario = usuario);
    }
  }





  ngOnInit(): void {

    var stepperElement = document.querySelector('#stepper1');
    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    })

    stepperElement.addEventListener('show.bs-stepper', (event: CustomEvent) => {
      // You can call preventDefault to stop the rendering of your step
      // console.warn('stepper show', event.detail);

      switch (event.detail.to) {
        case 0:
          // console.log('entrar no passo seleção CNIS');
          break;
        case 1:
          if (this.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
          } else {
            event.preventDefault();
            Swal.fire({
             // title: 'CNIS',
             text: 'Selecione CNIS antes de continuar',
             iconHtml: '<i class="bi bi-filetype-pdf"></i>',
             iconColor: '#000',
             showCloseButton: false,
             showConfirmButton: false,
             // confirmButtonText: 'Ok',
             // confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }
          break;
        case 2:
          if (this.calculoBeneficios.leituraCnis) {
            // console.log('entrando no passo análise do CNIS');
            this.calcularResultado();
          } else {
            event.preventDefault();
            Swal.fire({
              // title: 'CNIS',
              text: 'Selecione CNIS antes de continuar',
              iconHtml: '<i class="bi bi-filetype-pdf"></i>',
              iconColor: '#000',
              showCloseButton: false,
              showConfirmButton: false,
              // confirmButtonText: 'Ok',
              // confirmButtonColor: '#000'

            }).then(result => {
              this.stepper.to(0);
            });

          }

          break;

        default:
          break;
      }

      //controle salvar cnis

      switch (event.detail.from) {
        case 1:
          this.editorCNIS.salvarModificacoesCnis();
          break;

        default:
          break;
      }
    });

    stepperElement.addEventListener('shown.bs-stepper', (event: CustomEvent) => {
      // console.warn('step shown mostrando', event.detail);

      // console.log('passo ', event.detail.indexStep);
      switch (event.detail.indexStep) {
        case 0:
          break;
        case 1:
          break;
        case 2:
          break;
        default:
          break;
      }

    });

    this.configService.getAguarde().subscribe(status => this.aguardeConfig = status);

    if (!environment.production) {
      // this.mock();
    };
  }

  mock() {
    this.listarDadosArquivoDataService.ListarArquivo("calculoinvalidez.json", request => {
      this.carregarDados(request as CalculoBeneficios);
      this.stepper.to(2);
    });
  }

  carregarDados(calculoBeneficios: CalculoBeneficios) {
    this.calculoBeneficios = calculoBeneficios;

  }

  leituraCNISEvent(leituraCNIS: LeituraCNIS) {
    this.calculoBeneficios.leituraCnis = leituraCNIS;
    
    this.proximo(true);
    this.cnisAtualizado(this.calculoBeneficios);

  }


  edicaoCNISFinalizada(calculoBeneficios: CalculoBeneficios) {

    this.calculoBeneficios = calculoBeneficios;
  }

  incluirMarcadorCnis(incluir: boolean) {

    this.editorCNIS.incluirMarcador();
  }

  excluirCnisFinalizado(exclui: boolean) {
    // console.log('cnis excluido - beneficios component');
    this.editorCNIS.excluirFinalizado();
    // this.seletorCnis.carregarDados();
    this.configService.setAguarde(false);
  }



  gerarPDF() {

  }

  atualizarListaCnis(atualizar: boolean) {
    // this.seletorCnis.carregarDados();
  }


  proximoStepper() {
    // console.log('proximo stepper');
    this.stepper.next();
  }
  anteriorStepper() {
    // console.log('anterior stepper');
    this.stepper.previous();
  }

  proximo(event) {
    this.proximoStepper()
  }
  anterior(event) {
    this.anteriorStepper()
  }
  cancelar(event) {
    // console.log('cancelar');
    this.router.navigateByUrl('calculei/home');

  }
  pdf(event) {
    this.gruposSumarioBeneficios.gerarPDFSumario();
  }

  salvarEdicaoCnis(event) {
    this.editorCNIS.salvarModificacoesCnis();
  }


  editarTitulo(titulo: string) {
    this.calculoBeneficios.nomeCalculo = titulo;

  }
  calcularResultado() {

    if (!this.atualizarCalculoCnisModificado) return;


    // console.log('calculo pre ');
    // console.log(this.calculoBeneficios);

    this.configService.setAguarde(true);
    this.aguardeResultado = true;
    this.changeDetector.detectChanges();
    let prepCalculoApi: CalculoBeneficios = JSON.parse(JSON.stringify(this.calculoBeneficios));
    prepCalculoApi.beneficios = new Array();
    this.listarGruposSumarioCalculoBeneficiosService.listarGruposSumarioCalculoBeneficios(prepCalculoApi)
      .then(calculo => {

        // console.log('calculo pronto beneficios');
        // console.log(calculo);

        this.calculoBeneficios = calculo as CalculoBeneficios;
        this.configService.setAguarde(false);
        this.aguardeResultado = false;
        this.atualizarCalculoCnisModificado = false;


      });

  }

  cnisAtualizado(calculoBeneficios: CalculoBeneficios) {
    // console.log('atualizando cnis beneficios');
    this.cnisModificado(true);
    if (this.editorCNIS) {
      this.editorCNIS.carregarDados();
    }
  }
  cnisModificado(event) {
    // console.log('cnis Modificado set true atualizarCalculo');
    this.atualizarCalculoCnisModificado = true;

  }

}
