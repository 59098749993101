<div class="card">
    <div class="card-header border-dark">
        <h5 class="mb-0">Planos Criados</h5>
    </div>
    <div class="card-body">














        <div class="dataTables_wrapper py-4">
            <div class="row">


                <div class="col-12">
                    <div class="dataTables_length" id="datatable_length">
                        <div class="row">
                            <div class="col-12">
                                <label>
                                    Buscar Cliente por nome, data ou CPF
                                    <div class="col-lg-4">
                                        <input type="search" class="form-control" placeholder="Digite o termo da busca"
                                            aria-controls="datatable" (keyup)="filterTable($event)" />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <ngx-datatable class="material striped selection-cell"  [headerHeight]="50"
                        [footerHeight]="50" [rowHeight]="'auto'" [limit]="entries != -1 ? entries : undefined"
                        [rows]="temp" (activate)="onActivate($event)" [columnMode]="ColumnMode.force"
                        [messages]="{emptyMessage: 'Nenhum cliente encontrado'}">


                        
                        <ngx-datatable-column name="IdPlan" prop="idPlan"></ngx-datatable-column>
                        <ngx-datatable-column  name="Nome" prop="name"></ngx-datatable-column>
                        <ngx-datatable-column  name="Total de Assinaturas" prop="quantitySubscriptions"></ngx-datatable-column>
                        <ngx-datatable-column  name="Valor" prop="amount">
                            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                                <span>{{ value | currency }}</span>
                            </ng-template>
                        </ngx-datatable-column>





                        <ngx-datatable-column name="Excluir" >



                            <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
                                <div class="row">


                                    <i class="text-default ni ni-2x ni-fat-remove " (click)="desativarPlano(rowIndex)">
                                    </i>


                                </div>
                            </ng-template>
                        </ngx-datatable-column>


                    </ngx-datatable>
                </div>

            </div>


        </div>











    </div>
</div>